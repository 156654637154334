import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Translate, HomeRounded, LocalGroceryStoreRounded, ReceiptLong, HelpRounded, ContactPageRounded, AccountCircleRounded, QrCodeScanner } from '@mui/icons-material';
import { availableLanguages, getCountryList, setLanguage, traductionMap } from '../componants/Traduction';
import { auth } from '../firestore'

const NavConsumer = () => {

    const [showNav, setShowNav] = useState(false);
    const [hamburgerColorChange, setHamburgerColorchange] = useState(false);
    const [navbarColorChange, setNavbarColorChange] = useState(false);
    const [visibility, setVisibility] = useState('hidden')

    //Show side bar nav on hamburger click
    const handleShowNav = () => {
        setShowNav(!showNav);
    };

    //Force the close on the side nav
    const closeNav = () => {
        setShowNav(false);
    }

    //Change the hamburger background on scroll
    const handleHamburgerColorChange = () => {
        if (window.scrollY >= 80) {
            setHamburgerColorchange(true);
        }
        else {
            setHamburgerColorchange(false);
        }
    };
    window.addEventListener('scroll', handleHamburgerColorChange);

    //Change the navbar color on scroll
    const handleNavbarColorChange = () => {
        if (window.scrollY >= 80) {
            setNavbarColorChange(true);
        }
        else {
            setNavbarColorChange(false);
        }
    }
    window.addEventListener('scroll', handleNavbarColorChange);
    
    const changeLanguage = (l) => {
        var languageKey = Object.keys(availableLanguages).find(key => availableLanguages[key] === l);
        localStorage.setItem('language', languageKey)
        setLanguage(languageKey)
        setVisibility('hidden')
        window.location.reload()
    }

    return (
        <div>
        <div className='selectorContainer'style={{visibility: visibility}}>
            <div style={{ overflow: 'auto', maxHeight: '65%' }}>
                {getCountryList().map(name => 
                    <div className='selector' onClick={()=>changeLanguage(name)}>
                        {name}
                    </div>)}
            </div>
        </div>
        <nav className={navbarColorChange ? `consumerNavbar navbarColorChange ${showNav ? "showSideBar" : "hideSideBar"}` : `consumerNavbar ${showNav ? "showSideBar" : "hideSideBar"}`}>
            
            <div className="navContainer">
                <button className={hamburgerColorChange ? 'navbarBurger colorChange' : 'navbarBurger'} onClick={handleShowNav}>
                    <span className="burgerBar"></span>
                </button>
                
                <ul>
                    <div className="navLeft">
                        <li>
                            <NavLink exact to="/" activeClassName='navActive' onClick={closeNav}>
                                <i><HomeRounded /></i>
                                <span>{traductionMap.home}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/Events" activeClassName='navActive' onClick={closeNav}>
                                <i><LocalGroceryStoreRounded /></i>
                                <span>{traductionMap.order}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/ScanPaymentQR" activeClassName='navActive' onClick={closeNav}>
                                <i><QrCodeScanner /></i>
                                <span>{traductionMap.pay}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/MyOrders" activeClassName='navActive' onClick={closeNav}>
                                <i><ReceiptLong /></i>
                                <span>{traductionMap.myOrders}</span>
                            </NavLink>
                        </li>
                        <li className='navSeparator'>
                            <div></div>
                        </li>
                        <li>
                            <div activeClassName='navActive' onClick={()=> setVisibility('visible')}>
                                <i><Translate /></i>
                                <span>{availableLanguages[localStorage.getItem('language')?localStorage.getItem('language'):navigator.language.substring(0, 2)]}</span>
                            </div>
                        </li>
                        <li>
                            <NavLink exact to="/UserGuide" activeClassName='navActive' onClick={closeNav}>
                                <i><HelpRounded /></i>
                                <span>{traductionMap.help}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/About" activeClassName='navActive' onClick={closeNav}>
                                <i><ContactPageRounded /></i>
                                <span>{traductionMap.about}</span>
                            </NavLink>
                        </li>
                    </div>
                    <div className='navRight'>
                        <li>
                            {!localStorage.getItem("role") || localStorage.getItem("role") === 'undefined'?
                            <NavLink exact to="/Login" activeClassName='navActive' onClick={closeNav}>
                                <i><AccountCircleRounded /></i>
                                <span>{traductionMap.signIn}</span>
                            </NavLink>
                            :
                            <NavLink exact to="/" onClick={() => {
                                auth.signOut().then(() => {
                                    localStorage.setItem("role", "undefined")
                                    localStorage.setItem("userPath", "undefined")
                                    window.location.reload();
    
                                });
                            }}>
                                <i><AccountCircleRounded /></i>
                                <span>{traductionMap.logOut}</span>
                            </NavLink>
                            }
                        </li>
                    </div>
                    <li className='navMail'>
                        info@sqip.ch
                    </li>
                </ul>

            </div>

        </nav>
        </div>
    );
};

export default NavConsumer;
