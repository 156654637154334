 
import React, {  useEffect, useState } from 'react';
import firebase from '../../firestore';
import { useHistory } from "react-router-dom";
import trashIcon from '../../ressources/trash.png'
import { DeleteFirebaseBooth } from '../../repostirories/firebaseFunctions';
import { collection, getDocs } from "firebase/firestore";
import { useForceUpdate } from '../../tools/utils';
import ListItem from '../../componants/ListItem';
import { traductionMap } from '../../componants/Traduction';
import { translate } from '../../tools/Tools';

var db = firebase.firestore();

function BoothList() {
  const forceUpdate = useForceUpdate()
  const history = useHistory()
  const [items, setItems] = useState(null)

  console.log(localStorage.getItem("eventUserPath"))

  //Import the selected menu from firebase
  useEffect(() => {
    async function InitMenu() {
      var itemList = [];
      await getDocs(collection(db, localStorage.getItem("eventUserPath"))).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.data())
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists) {
            // Convert to item object
            var item;
            item = doc.data();
            item.id = doc.id;

            itemList.push(item);

          } else {
            console.log("No such document!")
          }
        })
        if (itemList.length !== 0) {
          console.log(itemList)
          setItems(itemList);
        }
      })
    }

    InitMenu();
    // eslint-disable-next-line
  },[]);

  const deleteBooth = (booth, path, items) => {
    DeleteFirebaseBooth(booth, path, items)
    
    forceUpdate()
  }
  const backButton = () => {
    history.push('/EventAdminConsole')
  }
  return (
    <div className="event">
      <div className="eventContent">
        <div className="footerButton">
          <p className="backBasket" onClick={backButton}>{traductionMap.return}</p>
        </div>
        {items === null ? null : items.map((item) =>
          <ListItem key={item.id}>
            <div style={{width: '80%'}} onClick={() => {
              localStorage.setItem("boothPath", localStorage.getItem("eventUserPath") + item.id + "/")
              localStorage.setItem("pseudoRole", 'BoothAdmin');
              localStorage.setItem("userPath", localStorage.getItem("eventUserPath") + item.id + "/");
              history.push("/BoothAdminConsole")
            }
            }>{translate(item.menuName)}
            </div>
            <div >
              <img onClick={() => deleteBooth(item, localStorage.getItem("boothPath"), items)} style={{ height: '44px', width: 'auto' }} src={trashIcon} alt="Supprimer un événement"></img>
            </div>

          </ListItem>)}
        </div>
    </div>
  )
}

export default BoothList;
