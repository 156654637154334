import React from "react";
import "../styles/componants/ConfirmScreen.css";

function ConfirmScreen(props) {

  return (
    <>
      <div className="ConfirmBox">
        <div>
          <div style={{ padding: '10px' }}>{props.message}</div>
          <p />
          <button className='confirmButton' onClick={props.functionYes}>{props.labelYes}</button>
          <button className='cancelButton' onClick={props.functionNo}>{props.labelNo}</button>
          {props.labelOthers !== null ?
            <button className='ConfirmBoxOther' onClick={props.functionOthers}>{props.labelOthers}</button> : null
          }
        </div>
      </div>
    </>
  );
}
export default ConfirmScreen
