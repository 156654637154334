import React, { useContext, useState, useEffect } from 'react';
import { currentPathContext } from '../../context/CurrentPathContext';
import {  getGateway  } from "../../tools/payrexx"
import firebase from '../../firestore'
import { useHistory } from "react-router-dom";

import { QRCode } from "react-qr-svg";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

import LoadingScreen from '../../componants/LoadingScreen';
import { addZero, formatDate, getDate, translate } from '../../tools/Tools';
import { getAnyDocById } from '../../repostirories/firebaseFunctions';
import { collection, doc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import Button from '../../componants/Button';
import { useForceUpdate } from '../../tools/utils';
import { traductionMap } from '../../componants/Traduction';
import WhiteSquare from "../../ressources/whitesquare.png";
import { changeStatus } from '../../repostirories/serverFunctions';

var db = firebase.firestore();

function CommandedView() {
    const [isLoading, setIsLoading] = useState(true)
    const [cmdNotConfirmed, setCmdNotConfirmed] = useState(false)
    const [commandNumber, setCommandNumber] = useState(null)
    const history = useHistory();
    const { setCommandNumberContext } = useContext(currentPathContext);
    const { itemPath, setItemPath } = useContext(currentPathContext);
    console.log(itemPath)
    if(itemPath.includes('https://'))
        setItemPath(localStorage.getItem('itemPath'))
    const boothId = itemPath.substring(itemPath.indexOf('/items') - 20, itemPath.indexOf('/items'))
    const collectionPath = itemPath.substring(0, itemPath.indexOf('/items') - 20) + boothId + '/commands/'
    const [currentOrder, setCurrentBasket] = useState(localStorage.getItem('currentOrder'))
    const forceUpdate = useForceUpdate()
    const [date, setDate] = useState("")

   

    var tvaMap = new Map()
    var tvaList = []


    const backButton = () => {
        let push = localStorage.getItem("commandPath")
        history.push("/Items/" + push);
    }
    // //split into array the stored data
    // const nameList = localStorage.getItem('listName').split('@EQ@')
    // const priceList = localStorage.getItem('listPrice').split('@EQ@')
    // const quantityList = localStorage.getItem('listQuantity').split('@EQ@')
    // const descriptionList = localStorage.getItem('listDescription').split('@EQ@')
    // const tvaList = localStorage.getItem('listTva').split('@EQ@')
    const priceTemp = +(localStorage.getItem('totalPrice'))
    const orderedItems = JSON.parse(localStorage.getItem('order'))



    useEffect(async () => {

        async function findGateway() {
            var today = new Date();
            var dateCommand = today.getFullYear() + "/" + addZero((today.getMonth() + 1),2) + "/" + addZero(today.getDate(),2) + " " + addZero(today.getHours(),2) + ":" + addZero(today.getMinutes(),2);
            var postData = {orderPath: collectionPath, orderId: localStorage.getItem('commandId'), dateHour: dateCommand}
                    
            await changeStatus(postData).then(async (data)=>{
                console.log(data)
                console.log(data)
                setCurrentBasket(data)
                setDate(data.dateHour)
                forceUpdate()
                
                if(!data.dateHour){
                    window.location.reload()
                }
                localStorage.setItem('bringToCompany', '')

                

                //add the order in order list
                var orders = JSON.parse(localStorage.getItem('myOrders'))
                
                
                if(!orders)
                    orders = []
                
                orders.push(data)
                localStorage.setItem('myOrders', JSON.stringify(orders))


                localStorage.setItem("basket", "");
                localStorage.setItem('subItems', "")
                //reset subItems
                data.orderedItems.map((item) =>

                    localStorage.setItem('subItems' + item.key, '')

                );
                
                
                var commandMessage = ''
                if (translate(data.SMSMessage))
                    commandMessage = translate(data.SMSMessage)
                var message = traductionMap.orderReady + " \n\n"
                + traductionMap.orderNumber + ' ' + data.number + '\n\n '+
                commandMessage
                // Préparer les notifications 
                setDoc(doc(db, "Notifications", data.id), {
                    customerId: localStorage.getItem('customerId'),
                    commandStatus: data.commandStatus,
                    number: data.number,
                    message: message
                });
                // Send notification directly
                const NotifRef = doc(db, "Notifications", data.id);
                if(localStorage.getItem('noPreparation') === 'true'){
                    localStorage.setItem('noPreparation', "false")
                    updateDoc(NotifRef, {
                        commandStatus: 1,
                    }).then(()=>{
                        updateDoc(NotifRef, {
                            commandStatus: 2,
                        })
                    });
                }

                forceUpdate()

            
                //go sur la page de numéro de commande
                setCommandNumberContext(commandNumber);
                setIsLoading(false)
                
            })
            orderedItems.forEach(async (item) =>{
                var product = await getAnyDocById(itemPath, item.key)
                var availableQty = product.availableInStock - item.quantity
                const docRef = doc(db, itemPath, item.key);
                setDoc(docRef, { availableInStock: availableQty }, { merge: true });
            })
                
        }
        
        await findGateway();
        // eslint-disable-next-line 
    },[]);
    
    //set tva
    if(orderedItems){
        orderedItems.forEach((item)  => {
            //set tva to zero on first passage
            if(tvaMap[item.tva] === undefined){
                tvaMap[item.tva] = 0
                tvaList.push(item.tva)
            }
            // add tva to the corresponding cumulated tva rate
            tvaMap[item.tva] = tvaMap[item.tva] + (item.price * item.quantity * item.tva/ 100)
        })
    }
    
    //download receipt
    const downloadHandler = (event) => {
        event.preventDefault();
        domtoimage.toBlob(document.getElementById('command'))
            .then(function (blob) {
                saveAs(blob, 'sqipCommand' + commandNumber + '.png');
            });
    }
    
    console.log(currentOrder)
    console.log(date)
    return (
        <>
            {isLoading ?
                <LoadingScreen></LoadingScreen>
                :
                    cmdNotConfirmed ?
                        <div>{traductionMap.orderNumber}</div> :
                        <div className='commanded'>
                            <div id="receipts" className='commandedContent'>
                                <h1>{traductionMap.myOrder}</h1>
                                <div id="command" className='command'>
                                    <div className="commandNumber" >

                                        <div > {traductionMap.orderNumber}  {' ' + currentOrder.number} </div>
                                        <p style={{ textAlign: 'center', fontSize: '14px' }}>
                                            {localStorage.getItem("officialName")}<br />
                                            {localStorage.getItem("adress")}<br />
                                            {localStorage.getItem("locality")}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>{traductionMap.date} {formatDate(getDate(date))}</p>
                                    </div>
                                    {orderedItems.map((item) =>
                                        <div key={item.key} className="flexContainer-receipt">
                                            <div style={{ width: "12%" }}> {item.quantity}X</div>
                                            <div style={{ width: "50%" }}> {translate(item.itemName).substring(0, 25)} </div>
                                            <div style={{ textAlign: "right", width: "25%", marginRight: "1px" }}> {(item.price * item.quantity).toFixed(2)} CHF</div>
                                        </div>
                                    )}
                                    <div className="flexContainer-receipt" style={{ fontWeight: "bold", fontSize: "14px" }}>
                                        <div style={{ width: "45%" }}>{traductionMap.totalDeposits} </div>
                                        <div style={{ textAlign: "right", width: "45%" }}>{Number(localStorage.getItem('totalDeposit')).toFixed(2)} CHF</div>
                                    </div>
                                    {tvaList.map((tva) =>
                                        <div className="flexContainer-receipt" style={{ fontWeight: "bold", fontSize: "14px" }}>
                                            <div style={{ width: "45%" }}>{traductionMap.vat} {tva}% </div>
                                            <div style={{ textAlign: "right", width: "45%" }}>{tvaMap[tva].toFixed(2)} CHF</div>
                                        </div>
                                    )}
                                    
                                    <div className="flexContainer-receipt" style={{ fontWeight: "bold", fontSize: "14px" }}>
                                        <div style={{ width: "45%" }}>{localStorage.getItem('vatNumber')} </div>
                                    </div>

                                    <div className="flexContainer-receipt" style={{ fontWeight: "bolder", fontSize: "16px" }}>
                                        <div style={{ width: "45%" }}>{traductionMap.total} </div>
                                        <div style={{ textAlign: "right", width: "45%" }}>{(priceTemp+Number(localStorage.getItem('totalDeposit'))).toFixed(2)} CHF</div>
                                    </div>
                                    {localStorage.getItem('commandId') !== null ?
                                        <div style={{ textAlign: "center", marginTop: "30px", position: 'relative'}} >
                                        <img src={WhiteSquare} alt=""  />
                                            <QRCode
                                                bgColor="transparent"
                                                fgColor="#000000"
                                                level="Q"
                                                style={{ zIndex: '2000', position: 'absolute', 
                                                top: '0px', left: '0px', right:'0px', width: '160px', marginLeft:'auto', marginRight:'auto',
                                                marginTop: 'auto', marginBottom: 'auto', bottom:'0' }}
                                                value={localStorage.getItem('commandId')}
                                            >
                                            </QRCode>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className='buttonSaveCommand' style={{ textAlign: "center", marginTop: "30px" }}>
                                    <Button onClick={downloadHandler}>{traductionMap.saveTicket}</Button>
                                </div>
                                <div className="buttonBackToBooth" style={{ textAlign: "center", marginTop: "30px" }}>
                                    <Button onClick={backButton}> {traductionMap.returnBooth} </Button>
                                </div>
                            </div>
                        </div>}
        </>
    )
}
export default CommandedView;
