import {useState} from 'react'

const authDomain = "sqipprod.firebaseapp.com"
const databaseURL= "https://sqip-563a3.firebaseio.com"

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
// generrate random 20 characters key
function generateKey(){
    //generateId
    var referenceId = '';
    var length = 20;
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        referenceId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return referenceId
}
// calendar format in several languages
const customLocale = {
    fr:{
        // months list by order
        months: [
        'Janvier',
        'février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
        ],
    
        // week days by order
        weekDays: [
        {
            name: 'Dimanche', // used for accessibility 
            short: 'D', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: 'Lundi',
            short: 'L',
        },
        {
            name: 'Mardi',
            short: 'M',
        },
        {
            name: 'Mercredi',
            short: 'M',
        },
        {
            name: 'Jeudi',
            short: 'J',
        },
        {
            name: 'Vendredi',
            short: 'V',
        },
        {
            name: 'Samedi',
            short: 'S',
            isWeekend: true,
        },
        ],
        // just play around with this number between 0 and 6
        weekStartingIndex: 0,

        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject) {
            return gregorainTodayObject;
        },

        // return a native JavaScript date here
        toNativeDate(date) {
            return new Date(date.year, date.month - 1, date.day);
        },

        // return a number for date's month length
        getMonthLength(date) {
            return new Date(date.year, date.month, 0).getDate();
        },

        // return a transformed digit to your locale
        transformDigit(digit) {
            return digit;
        },

        // texts in the date picker
        nextMonth: 'Next Month',
        previousMonth: 'Previous Month',
        openMonthSelector: 'Open Month Selector',
        openYearSelector: 'Open Year Selector',
        closeMonthSelector: 'Close Month Selector',
        closeYearSelector: 'Close Year Selector',
        defaultPlaceholder: 'Select...',

        // for input range value
        from: 'from',
        to: 'to',


        // used for input value when multi dates are selected
        digitSeparator: ',',

        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,

        // is your language rtl or ltr?
        isRtl: false,
    }
}

export {useForceUpdate, customLocale, generateKey, authDomain, databaseURL}