import { React } from 'react';
import { useHistory } from "react-router-dom";
import { AddFirebaseEvent } from "../../repostirories/firebaseFunctions"
import '../../styles/views/superAdminViews/AddUpdateEvent.css'
import { traductionMap } from '../../componants/Traduction';

function AddEvent() {
    const history = useHistory();

    const addEvent = () => {
        var event = {};
        event.eventName = {}
        event.eventName['default'] = document.getElementById("eventName").value;
        AddFirebaseEvent(event, 'events/');
        history.push("/AdminConsole")
    }

    const backButton = () => {
        history.push('/AdminConsole')
    }
    return (
        <div className={"App"}>
            <div className="basketFooter">
                <p className="backBasket" onClick={backButton}>{traductionMap.return}</p>
            </div>
            <br />
            <div className={'LoginView'}>
                <div>
                    <p>{traductionMap.eventName} </p>
                    <input id={"eventName"}></input>
                </div>
            </div>
                
            <p />
            <div className={'LoginView'}><button onClick={addEvent}>{traductionMap.addEvent}</button></div>
        </div>

    );

}
export default AddEvent;
