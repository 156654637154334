import React, { useState, useEffect } from 'react';
import '../../styles/views/boothAdminViews/Statistics.css'
import firebase from "../../firestore";
import { Calendar } from "react-modern-calendar-datepicker";
import { dateToString, isNull, translate} from '../../tools/Tools';
import {DateRange, Download} from '@mui/icons-material'
import { customLocale } from '../../tools/utils';
import { traductionMap } from '../../componants/Traduction';
import Chart from '../../componants/Chart';
import { collection, getDocs } from 'firebase/firestore';
import * as XLSX from 'xlsx';
import Button from '../../componants/Button';

var db = firebase.firestore();

function Statistics() {
    // (C1) DUMMY DATA
    const [allData, setAllData] =  useState([[]])
    
    const downloadAllData = ()=>{
        var data = allData.map(Object.values)
        // (C2) CREATE NEW EXCEL "FILE"
        var workbook = XLSX.utils.book_new(),
        worksheet = XLSX.utils.aoa_to_sheet(data);
        workbook.SheetNames.push("First");
        workbook.Sheets["First"] = worksheet;
        XLSX.writeFile(workbook, "stats.xlsx");

    }

    const [command, setCommand] = useState(0);
    const [turnover, setTurnover] = useState(0);
    const [turnoverNotPaid, setTurnoverNotPaid] = useState(0);
    const [sqipTotal, setSqipTotal] = useState(0);
    const [pspTotal, setPspTotal] = useState(0);
    const [tvaTotal, setTvaTotal] = useState(0);
    const [depositTotal, setDepositTotal] = useState(0)
    const [givenBackMoney, setGivenBackMoney] = useState(0)
    const chartType = 'bar'
    const userPath = localStorage.getItem("userPath") + 'commands/';
    const [orders, setOrders] = useState([])
    const today = new Date()
    // period picker management
    const defaultFrom = isNull(JSON.parse(localStorage.getItem('fromDateStat')))?
                        {
                            year: today.getFullYear() ,
                            month: today.getMonth() + 1, 
                            day: today.getDate()
                        }:
                        JSON.parse(localStorage.getItem('fromDateStat'))
    const defaultTo = isNull(JSON.parse(localStorage.getItem('toDateStat')))?null:
                        JSON.parse(localStorage.getItem('toDateStat'))
    const defaultValue = {
            from: defaultFrom,
            to: defaultTo,
        };
    const [selectedDayRange, setSelectedDayRange] = useState(defaultValue)
    // set calendar visible
    const [calendarVisible, setCalendarVisible] = useState(false)

    const changeDayRange = (dayRange)=>{
        setSelectedDayRange(dayRange)
        if(dayRange.to){
            dayRange.to.hour = 23
            dayRange.to.minute = 59
        }
        localStorage.setItem('fromDateStat', JSON.stringify(dayRange.from))
        localStorage.setItem('toDateStat', JSON.stringify(dayRange.to))

    }
    useEffect(() => {
        var data =[[]]
        async function InitCommands() {
            var j = 0
            let tempCommand = 0;
            let tempTurnover = 0;
            let tempTurnoverNotPaid = 0
            var tempSqip = 0;
            var tempPSP = 0
            var tempTva = 0
            var tempDeposit = 0
            var tempGivenBackMoney = 0
            var tempItemMap={}
            var tempOrders = []
            
            //init stats header
            data[j] = {0: traductionMap.identifier}
            data[j][1] = traductionMap.orderNumber
            data[j][2] = traductionMap.productName
            data[j][3] = traductionMap.quantity
            data[j][4] = traductionMap.price
            data[j][5] = traductionMap.vat
            data[j][6] = traductionMap.date
            data[j][7] = traductionMap.hour
            j++

            
            //init deposit 
            tempItemMap['deposit'] = {}
            tempItemMap['deposit']['name'] = traductionMap.deposits
            tempItemMap['deposit']['revenue'] = 0
            const querySnapshot = await getDocs(collection(db, userPath))
            querySnapshot.forEach((doc) => {
                
                var totalCommandTva = 0
                // doc.data() is never undefined for query doc snapshots
                if (doc.exists && doc.data().paymentStatus) {
                    var beginDate
                    var endDate
                    if(selectedDayRange.from !== null)
                        beginDate = dateToString(selectedDayRange.from)
                    if(selectedDayRange.to !== null)
                        endDate = dateToString(selectedDayRange.to)
                    //must not take the "command" document wich is in the same collection
                    
                    if (doc.id.length > 10) {
                        if(doc.data().dateHour >= beginDate && (doc.data().dateHour <= endDate || endDate === undefined))
                        {
                            
                            var order = doc.data()
                            order.id = doc.id
                            // take into account payrexx fees even on refund
                            if(doc.data().payrexxFee)
                                tempPSP += (doc.data().payrexxFee/100)
                            if(!doc.data().isRefund){
                                tempCommand++
                                if(doc.data().paymentSqip){
                                    tempTurnover += doc.data()["totalPrice"];
                                }
                                else{
                                    tempTurnoverNotPaid += doc.data()["totalPrice"]
                                }
                                
                                tempDeposit += doc.data().totalDeposit

                                tempSqip += doc.data()["totalPrice"] * doc.data().sqipRate
                                //add to orderList
                                tempOrders.push(doc.data())

                                if(doc.data().givenBackMoney){
                                    // adjust sqip part and vat in case of given back money
                                    tempTva = tempTva - (doc.data().givenBackMoney * 0.077)
                                    tempSqip = tempSqip - doc.data().givenBackMoney * doc.data().sqipRate
                                    tempGivenBackMoney = tempGivenBackMoney + doc.data().givenBackMoney
                                }
                                
                                    

                                doc.data().orderedItems.forEach(item => {
                                    data[j] = {0: doc.id}
                                    data[j][1] = doc.data().number
                                    data[j][2] = translate(item.itemName)
                                    data[j][3] = item.quantity
                                    data[j][4] = item.price
                                    data[j][5] = item.tva
                                    data[j][6] = doc.data().dateHour.substring(0, 10)
                                    data[j][7] = doc.data().dateHour.substring(12)
                                    data[j][8] = doc.data().paymentMean
                                    data[j][9] = doc.data().dateHour

                                    j++
                                    
                                    var orderDate = new Date(doc.data().dateHour)
                                    orderDate.setHours(0)
                                    orderDate.setMinutes(0)
                                    //get total of tva for one command
                                    totalCommandTva =
                                        item.quantity * item.price * (item.tva / 100)  +  
                                        item.quantity * item.deposit * 0.077 + 
                                        totalCommandTva
                                    
                                    
                                })
                                // add total tva of command to global tva total
                                tempTva = tempTva + totalCommandTva
                            }

                        }
                    }
                }
                else {
                    console.log("No such document!");
                }
            })

            //put deposit into downloaded statistics
            data[j] = {0: 0}
            data[j][2] = traductionMap.deposits
            data[j][3] = 1
            data[j][4] = tempDeposit
            data[j][5] = 7.7

            
            //put item cummulation into array
            setCommand(tempCommand);
            setDepositTotal(tempDeposit)
            setTurnover(tempTurnover)
            setTurnoverNotPaid(tempTurnoverNotPaid)
            setSqipTotal(tempSqip)
            setPspTotal(tempPSP)
            setTvaTotal(tempTva)
            setGivenBackMoney(tempGivenBackMoney)
            setOrders(tempOrders)
            setAllData(data)

        }
        if (userPath !== null) {
            InitCommands();
        }

    // eslint-disable-next-line
    },[selectedDayRange])
    return (
        
        <>
        
        <div className="Stats">
        <div>
            
            <Button onClick={()=>downloadAllData()}> <Download/></Button>
            </div>
            <i onClick={()=>{setCalendarVisible(!calendarVisible)}}><DateRange/> {dateToString(defaultFrom).substring(0,10)} - {dateToString(defaultTo).substring(0,10)}</i>
            <div></div>
            {calendarVisible?
                <Calendar
                    value={selectedDayRange}
                    onChange={changeDayRange}
                    colorPrimary="#ec7c34" 
                    colorPrimaryLight="rgba(236, 124, 52, 0.2)" 
                    shouldHighlightWeekends
                    locale={customLocale[localStorage.getItem('language')]}
                />: <div/>
            }
            <p><span className="bold">{command.toFixed(0)}</span> {traductionMap.totalOrders} <span className="bold">{(turnoverNotPaid + turnover).toFixed(2)}</span> CHF</p>
            <p><span className="bold"></span> {traductionMap.notPaidBysQip} <span className="bold">{turnoverNotPaid.toFixed(2)}</span> CHF</p>
            <p><span className="bold"></span> {traductionMap.paidBysQip} <span className="bold">{turnover.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.depositTotal} <span className="bold">{depositTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.givenBackDeposit} <span className="bold">{givenBackMoney.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.squipShare} <span className="bold">{sqipTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.pspShare} <span className="bold">{pspTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.GrossEarnings}
                {' '}<span className="bold">{(turnover - sqipTotal - pspTotal).toFixed(2)}</span> CHF
            </p>
            <p>{traductionMap.vatShare} <span className="bold">{tvaTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.netEarnings}
                {' '}<span className="bold">{(turnover + turnoverNotPaid - sqipTotal - pspTotal - tvaTotal).toFixed(2)}</span> CHF
            </p>
            <Chart chartType={chartType} orders={orders} ></Chart>
        </div>
        </>
    );
}
export default Statistics;
