import React, { useState } from 'react';
import { translate } from '../tools/Tools';
import { useForceUpdate } from '../tools/utils';
import trashIcon from '../ressources/trash.png'
import { traductionMap } from './Traduction';

const SubItems = (props) => {
    const [listVisible, setListVisible] = useState(false)
    const [addSubItemVisible, setAddSubItemVisible] = useState(false)
    const [addedSubItem, setAddedSubItem] = useState({})
    const forceUpdate = useForceUpdate()

    const changeAddedSubItem = (e) =>{
        var subItem = {}
        subItem.default = e.currentTarget.value
        subItem[localStorage.getItem('language')] = e.currentTarget.value
        setAddedSubItem(subItem)
    }
    const validateAddedSubitem = () =>{
        props.items.push(addedSubItem)
        setAddSubItemVisible(false)
    }
    const deleteSubItem = (d) =>{
        const item = props.items.find(i => i.default === d)
        const index = props.items.indexOf(item)
        props.items.splice(index, 1)
        forceUpdate()
    }
    return (
        <>
            <button onClick={()=>setListVisible(!listVisible)}>{traductionMap.subItem}</button>
            {listVisible?
            <>
                {props.items?
                props.items.map((item) =>
                <div id={'subItems'+props.id+item.default}  key={props.id+item.default} style={{display: 'flex'}}>
                    <div style={{marginBottom:'12px'}}>
                    <input defaultValue={translate(item)} onChange={(e)=>item[localStorage.getItem('language')] = e.currentTarget.value}>
                    </input>
                    </div>
                    <div >
                        <img onClick={() => deleteSubItem(item.default)} style={{ height: '22px', width: 'auto' }} src={trashIcon} alt="Supprimer un événement"></img>
                    </div>
                    
                </div>
                ):null}
                
                {addSubItemVisible?
                    <div>
                        <input onChange={(e)=> changeAddedSubItem(e)}></input>
                        <button onClick={()=>validateAddedSubitem()}>{traductionMap.confirm}</button>
                    </div>
                    :
                    <button onClick={()=>setAddSubItemVisible(!addSubItemVisible)}>{traductionMap.addSubItem}</button>
                }
            </>:null
            }
            
        </>
    );
}
export default SubItems