import React, { useState, useEffect } from 'react';
import '../../styles/views/boothAdminViews/Statistics.css'
import firebase from "../../firestore";
import {  getListOfPeriods,  translate} from '../../tools/Tools';
import {  useForceUpdate } from '../../tools/utils';
import { traductionMap } from '../../componants/Traduction';
import Chart from '../../componants/Chart';
import { collection,  getDocs } from 'firebase/firestore';
import Select from 'react-select';
import { getAllEvents, GetOrdersByEvent } from '../../repostirories/firebaseFunctions';
import YearSelect from '../../componants/YearSelect';

var db = firebase.firestore();

function DueAmount() {
    // (C1) DUMMY DATA
    
    const forceUpdate = useForceUpdate()

    const [command, setCommand] = useState(0);
    const [turnover, setTurnover] = useState(0);
    const [turnoverNotPaid, setTurnoverNotPaid] = useState(0);
    const [sqipTotal, setSqipTotal] = useState(0);
    const [pspTotal, setPspTotal] = useState(0);
    const [tvaTotal, setTvaTotal] = useState(0);
    const [depositTotal, setDepositTotal] = useState(0)
    const [givenBackMoney, setGivenBackMoney] = useState(0)
    const chartType = 'bar'
    const [eventPath, setEventPath] = useState(null)
    const [eventSelected, setEventSelected] = useState(false)
    const [eventNames, setEventNames] = useState([])
    const [orders, setOrders] = useState([])
    const today = new Date()
    const [periods, setPeriods] = useState(getListOfPeriods('monthly', 2, today.getFullYear(), null))
    // period picker management
    const defaultFrom = periods[0].value.start
    const defaultTo = periods[0].value.end
    const defaultValue = {
            start: defaultFrom,
            end: defaultTo,
        };
    const [selectedDayRange, setSelectedDayRange] = useState(defaultValue)
    // set calendar visible

    const changeDayRange = (dayRange)=>{
        var days = dayRange.value
        setSelectedDayRange(days)

    }
    

    // get the list of payout periods in the given year
    // get events
    useEffect(() => {
        //Import the events from firebase
        async function InitEvents() {
            var eventNames = []
            var itemList = await getAllEvents();
            if (itemList.length !== 0) {
                itemList.forEach(item => {
                    eventNames.push({ value: item.id, label: translate(item.eventName) })
                })
            }
            
            setEventNames(eventNames)
            if(localStorage.getItem('role') === 'EventAdmin')
            {
                setEventSelected(true)
                setEventPath(localStorage.getItem('userPath'))
            }
        }
        InitEvents()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        var data =[[]]
        async function InitCommands() {
            var j = 0
            let tempCommand = 0;
            let tempTurnover = 0;
            let tempTurnoverNotPaid = 0
            var tempSqip = 0;
            var tempPSP = 0
            var tempTva = 0
            var tempDeposit = 0
            var tempGivenBackMoney = 0
            var tempItemMap={}
            var tempOrders = []
            
            
            //init deposit 
            tempItemMap['deposit'] = {}
            tempItemMap['deposit']['name'] = traductionMap.deposits
            tempItemMap['deposit']['revenue'] = 0
            const orders = await GetOrdersByEvent(eventPath)
            orders.forEach((doc)=>{
                console.log(doc)
                var totalCommandTva = 0
                // doc is never undefined for query doc snapshots
                if (doc && doc.paymentStatus) {
                    var beginDate = new Date(selectedDayRange.start)
                    var endDate = new Date(selectedDayRange.end)
                    
                    beginDate = new Date(selectedDayRange.start).toISOString()

                    
                    endDate =  new Date(selectedDayRange.end).toISOString()
                    //must not take the "command" document wich is in the same collection
                    if (doc.id.length > 10) {
                        if(new Date(doc.dateHour).toISOString() >= beginDate && (new Date(doc.dateHour).toISOString() <= endDate || endDate === undefined))
                        {
                            var order = doc
                            order.id = doc.id
                            if(doc.payrexxFee)
                                tempPSP += (doc.payrexxFee/100)
                            if(!doc.isRefund){
                                tempCommand++
                                if(doc.paymentSqip){
                                    tempTurnover += doc["totalPrice"];
                                }
                                else{
                                    tempTurnoverNotPaid += doc["totalPrice"]
                                }
                                
                                tempDeposit += doc.totalDeposit

                                tempSqip += doc["totalPrice"] * doc.sqipRate
                                //add to orderList
                                tempOrders.push(doc)

                                if(doc.payrexxFee)
                                    tempPSP += (doc.payrexxFee/100)
                                if(doc.givenBackMoney){
                                    // adjust sqip part and vat in case of given back money
                                    tempTva = tempTva - (doc.givenBackMoney * 0.077)
                                    tempSqip = tempSqip - doc.givenBackMoney * doc.sqipRate
                                    tempGivenBackMoney = tempGivenBackMoney + doc.givenBackMoney
                                }
                                
                                    

                                doc.orderedItems.forEach(item => {
                                    data[j] = {0: doc.id}
                                    data[j][1] = translate(item.itemName)
                                    data[j][2] = item.quantity
                                    data[j][3] = item.price
                                    data[j][4] = item.tva
                                    data[j][5] = doc.dateHour.substring(0, 10)
                                    data[j][6] = doc.dateHour.substring(12)
                                    data[j][7] = doc.number
                                    j++
                                    
                                    var orderDate = new Date(doc.dateHour)
                                    orderDate.setHours(0)
                                    orderDate.setMinutes(0)
                                    //get total of tva for one command
                                    totalCommandTva =
                                        item.quantity * item.price * (item.tva / 100)  +  
                                        item.quantity * item.deposit * 0.077 + 
                                        totalCommandTva
                                    
                                    
                                })
                                // add total tva of command to global tva total
                                tempTva = tempTva + totalCommandTva
                            }
                        }
                    }
                }
                else {
                    console.log("No such document!");
                }
            
            })

            

            //put deposit into downloaded statistics
            data[j] = {0: 0}
            data[j][1] = traductionMap.deposits
            data[j][2] = 1
            data[j][3] = tempDeposit
            data[j][4] = 7.7

            
            //put item cummulation into array
            setCommand(tempCommand);
            setDepositTotal(tempDeposit)
            setTurnover(tempTurnover)
            setTurnoverNotPaid(tempTurnoverNotPaid)
            setSqipTotal(tempSqip)
            setPspTotal(tempPSP)
            setTvaTotal(tempTva)
            setGivenBackMoney(tempGivenBackMoney)
            setOrders(tempOrders)
            
            

        }
        
        if (eventPath) {
            InitCommands()
        }

    // eslint-disable-next-line
    },[selectedDayRange, eventPath])

    const selectEvent = async (o) =>{
        var path = ('events/' + o.value + '/menus/')

        setEventSelected(true)
        setEventPath(path)
        forceUpdate()
          

    }
    
    const onChangeSelectedYear = (year)=>{
        var p = getListOfPeriods('monthly', 2, year.value, null)
        setPeriods(p)
        
    }
    console.log(localStorage.getItem('role'))
    return (
        
        <>
        
        <div className="Stats">
        {
            localStorage.getItem('role') === 'SuperAdmin'?
                <div> 
                <Select
                    id={'events'}
                    placeholder={traductionMap.eventList}
                    options={eventNames}
                    onChange={o => { selectEvent(o) }}
                ></Select>
            </div>:null
        }
        
        {eventSelected?
        <div>
            <YearSelect onChange={onChangeSelectedYear}></YearSelect>
            <div> 
            <Select
                id={'periods'}
                placeholder={periods[0].label}
                defaultValue={periods[0].value}
                options={periods}
                onChange={o => { changeDayRange(o) }}
            ></Select>
            </div>
            {/* <i onClick={()=>{setCalendarVisible(!calendarVisible)}}><DateRange/> {dateToString(defaultFrom).substring(0,10)} - {dateToString(defaultTo).substring(0,10)}</i>
            <div></div>
            {calendarVisible?
                <Calendar
                    value={selectedDayRange}
                    onChange={changeDayRange}
                    colorPrimary="#ec7c34" 
                    colorPrimaryLight="rgba(236, 124, 52, 0.2)" 
                    shouldHighlightWeekends
                    locale={customLocale[localStorage.getItem('language')]}
                />: <div/>
            } */}
            <p><span className="bold">{command.toFixed(0)}</span> {traductionMap.totalOrders} <span className="bold">{(turnoverNotPaid + turnover).toFixed(2)}</span> CHF</p>
            <p><span className="bold"></span> {traductionMap.notPaidBysQip} <span className="bold">{turnoverNotPaid.toFixed(2)}</span> CHF</p>
            <p><span className="bold"></span> {traductionMap.paidBysQip} <span className="bold">{turnover.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.depositTotal} <span className="bold">{depositTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.givenBackDeposit} <span className="bold">{givenBackMoney.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.squipShare} <span className="bold">{sqipTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.pspShare} <span className="bold">{pspTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.GrossEarnings}
                {' '}<span className="bold">{(turnover - givenBackMoney - sqipTotal - pspTotal).toFixed(2)}</span> CHF
            </p>
            <p>{traductionMap.vatShare} <span className="bold">{tvaTotal.toFixed(2)}</span> CHF</p>
            <p>{traductionMap.netEarnings}
                {' '}<span className="bold">{(turnover + turnoverNotPaid - sqipTotal - pspTotal - tvaTotal - (givenBackMoney*0.077)).toFixed(2)}</span> CHF
            </p>
            <Chart chartType={chartType} orders={orders} ></Chart>
            </div>:null
        }
        
        </div>
        </>
    );
}
export default DueAmount;
