import Button from '../../componants/Button';
import firebase from '../../firestore';
import { traductionMap } from '../../componants/Traduction';
import { addDoc, collection } from 'firebase/firestore';
import {React, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import { getFirebaseTypebyBooth } from '../../repostirories/firebaseFunctions'
import { translate } from '../../tools/Tools';
import SubItems from '../../componants/SubItems';

var db = firebase.firestore()
function AddSingleProduct() {
    const history = useHistory()
    const path = localStorage.getItem('userPath') + 'items/'
    const typesPath = localStorage.getItem('userPath') + 'types/'
    const item = {subItems: []}
    const [types, setTypes] = useState([])
    const [selectedType, setSelectedType] = useState({})
    var options = []
    useEffect(() => {
        function getTypes(){
            getFirebaseTypebyBooth(typesPath).then((typeList)=>{
                typeList.forEach(element => {
                    var option = {
                    value: element.type.default,
                    label: translate(element.type)
                    }
                    options.push(option)
                });
                setTypes(options)
                setSelectedType({default: options[0].value})
            })
        }
        getTypes();
        // eslint-disable-next-line 
    },[])    
        
      
    
    const addProduct = async () => {
        var itemName = {}
        itemName['default'] = document.getElementById("itemName").value
        itemName[localStorage.getItem('language')] = document.getElementById("itemName").value
        var description = {}
        description['default']= document.getElementById("description").value
        description[localStorage.getItem('language')] = document.getElementById("description").value
        var variableDepositName = {}
        variableDepositName['default'] = document.getElementById("variableDepositName").value
        variableDepositName[localStorage.getItem('language')] = document.getElementById("variableDepositName").value

        await addDoc(collection(db, path), {
            itemName: itemName,
            description: description,
            price: Number(document.getElementById("price" ).value),
            attributs: {
            glutenFree: (document.getElementById("glutenFree" ).checked),
            vegetarian: (document.getElementById("vegetarian" ).checked),
            lactoseFree: (document.getElementById("lactoseFree" ).checked),
            eighteenMin: (document.getElementById("eighteenMin" ).checked),
            sixteenMin: (document.getElementById("sixteenMin" ).checked)
            },
            soldOut: document.getElementById("soldOut" ).checked,
            availableInStock: Number(document.getElementById("availableInStock" ).value),
            stockThreshold: Number(document.getElementById("stockThreshold" ).value),
            tva: Number(document.getElementById("vat" ).value),
            deposit: Number(document.getElementById("deposit" ).value),
            type: selectedType,
            variableDepositName: variableDepositName,
            variableDepositPrice: Number(document.getElementById("variableDepositPrice" ).value),
            subItems: item.subItems
        }).then(()=>{
        })
        .catch((e)=>{
            alert(e)
        })
        history.push('/ItemListView')

    }

    const backButton = () =>{
        history.push('/ItemListView')
    } 

    const changeType = (o) => {
        var tempType = {}
        tempType[localStorage.getItem('language')] = o.label
        tempType.default = o.value
        setSelectedType(tempType)
    }
    return (
        <div >
        <div className="boothList-flex-container-1">
          <input id={"itemName"} placeholder={traductionMap.productName} onChange={e => item.itemName = e.currentTarget.value} style={{ fontWeight: "bold" }}></input>
          
          
        </div>
        <div className="boothList-flex-container-2">
          <textarea id={"description"} placeholder={traductionMap.productDescription} onChange={e => item.description = e.currentTarget.value}></textarea>
        </div>
        <div className="boothList-flex-container-2">
            <div>{traductionMap.category} : </div>
            <Dropdown
                className='dropdownList'
                id={item.id}
                placeholder={translate(item.type)}
                options={types}
                onChange={o => { changeType(o) }}
              ></Dropdown>
          </div>
        <div className="boothList-flex-container-2">
            <p> {traductionMap.price} : </p>
            <input id={"price"}  defaultValue={0} style={{ textAlign: "right", fontWeight: "bold", width: "42px" }}></input>
        </div>
        <SubItems items={item.subItems} id={item.id}/>
        
        <div className="boothList-flex-container-2">
          {traductionMap.deposits} :  
          <input id={"deposit"}  defaultValue={0} onChange={e => item.deposit = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
        </div>
        <div className="boothList-flex-container-2">
          {traductionMap.variableDeposit} :  
          <input id={"variableDepositName"} placeholder={traductionMap.name} onChange={e => item.deposit = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
          <input type='number' id={"variableDepositPrice"} placeholder={traductionMap.price} defaultValue={0} onChange={e => item.deposit = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
        </div>
        <div className="boothList-flex-container-2">
          {traductionMap.vat} : 
          <input id={"vat"} type='number' defaultValue={0}  onChange={e => item.tva = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
        </div>
        <div className="boothList-flex-container-2">
          {traductionMap.qtyAvailable}
          <input id={"availableInStock"} type='number' defaultValue={-1} onChange={e => item.availableInStock = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
          {traductionMap.threshold}
          <input id={"stockThreshold"} type='number' defaultValue={-1} onChange={e => item.stockThreshold = Number(e.currentTarget.value)}  style={{ textAlign: "right", fontWeight: "bold", width: "42px" }}></input>

        </div>
        <div className="boothList-flex-container-3">
          <div>
            <input type="checkbox" id={"glutenFree"}></input>

            {traductionMap.glutenFree}
          </div>
          <div>
            <input type="checkbox" id={"vegetarian"}></input>
            {traductionMap.vegeterian}
          </div>
          <div>
            <input type="checkbox" id={"lactoseFree"}></input>
            {traductionMap.lactoseFree}
          </div>
          <div>
            <input type="checkbox" id={"sixteenMin"}></input>
            {traductionMap.noUnder16}
          </div>
          <div>
            <input type="checkbox" id={"eighteenMin"}></input>
            {traductionMap.noUnder18}
          </div>
          <div>
            <input type="checkbox" id={"soldOut"}></input>
            
            {traductionMap.outOfStock}
          </div>
        </div>
        <div style={{textAlign: 'center'}}>
            <Button onClick={()=>addProduct()}>{traductionMap.add}</Button>
            <Button onClick={()=>backButton()}>{traductionMap.cancel}</Button>
        </div>
      </div>
    

);

}
export default AddSingleProduct;