import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {Translate, ArrowBackIosNewRounded, ArrowForwardIosRounded, HomeRounded, AdminPanelSettingsRounded, 
    StoreRounded, FactCheckRounded, InventoryRounded, QrCodeScannerRounded, QueryStats, Settings, 
    HelpRounded, ContactPageRounded, AccountCircleRounded, CurrencyExchange, Receipt, Storefront, PointOfSale } from '@mui/icons-material';
import { auth } from '../firestore'
import { useState } from 'react';
import { availableLanguages, getCountryList, setLanguage, traductionMap } from '../componants/Traduction';
import { GetBoothNameByFullPath } from '../repostirories/firebaseFunctions';
import { translate } from '../tools/Tools';

const NavAdmin = () => {
    let userRole = localStorage.getItem("role");
    const [isLoading, setIsLoading] = useState(true);
    const [isNavShrink, setIsNavShrink] = useState(window.screen.width<850?true:false);
    const [visibility, setVisibility] = useState('hidden')
    const [boothsVisibility, setBoothsVisibility] = useState('hidden')
    var paths = {}
    if(localStorage.getItem('userPaths') !== "undefined"){
        paths = JSON.parse(localStorage.getItem('userPaths'))
    }
    const [boothNames, setBoothNames] = useState({})
    const [currentBooth, setCurrentBooth] = useState({})
    useEffect(()=>{
        async function initBoothNames (){
            var booth = await GetBoothNameByFullPath(localStorage.getItem('userPath'))
            var boothNameList = {}
            Object.keys(paths).forEach(async key => 
                boothNameList[key] = await GetBoothNameByFullPath(paths[key])
            )
            setCurrentBooth(booth)
            setBoothNames(boothNameList)
            
        }
        initBoothNames()
        // eslint-disable-next-line
    },[])
    const handleNavShrink = () => {
        setIsNavShrink(!isNavShrink);
    }

    auth.onAuthStateChanged(user => {
        if (user) {
            setIsLoading(false);
        }
    })
    const changeLanguage = (l) => {
        var languageKey = Object.keys(availableLanguages).find(key => availableLanguages[key] === l);
        localStorage.setItem('language', languageKey)
        setLanguage(languageKey)
        setVisibility('hidden')
        window.location.reload()
    }
    const changeUserPath = (path) => {
        localStorage.setItem('userPath', path)
        window.location.reload()
    }
    return (
        <div>
        <div className='selectorContainer'style={{visibility: visibility}}>
            <div style={{ overflow: 'auto', maxHeight: '65%' }}>
                {getCountryList().map(name => 
                    <div className='selector' onClick={()=>changeLanguage(name)}>
                        {name}
                    </div>)}
            </div>
        </div>
        {paths && (userRole === 'Staff' || userRole === 'BoothAdmin')?
        <div className='selectorContainer'style={{visibility: boothsVisibility}}>
            <div style={{ overflow: 'auto', maxHeight: '65%' }}>
                {Object.keys(paths).map( key => 
                    <div className='selector' onClick={()=>changeUserPath(paths[key])}>
                        {translate(boothNames[key])}
                    </div>
                    )
                    }
            </div>
        </div>:null}
        <nav className={`navAdmin ${isNavShrink ? "navAdminShrink" : "navAdminNoShrink"}`}>
            <div className="navAdminContainer">
                <div className="shrinkNav" onClick={handleNavShrink}>
                    <i>
                        {isNavShrink ? <ArrowForwardIosRounded /> : <ArrowBackIosNewRounded />}
                    </i>
                </div>

                <ul>
                    {isLoading ? <li></li> : <li className="userEmail">{auth.currentUser.email}</li>}
                    <li className='navSeparator'>
                        <div></div>
                    </li>
                    {paths && (userRole === 'Staff' || userRole === 'BoothAdmin')?
                        <li>
                            <div activeClassName='navActive' onClick={()=> setBoothsVisibility('visible')}>
                                <i><Storefront /></i>
                                <span>{translate(currentBooth)}</span>
                            </div>
                        </li>
                    :null}
                    {(userRole === 'Staff' || userRole === 'BoothAdmin')?
                        <li>
                            <NavLink exact to="/fullOrderView" activeClassName='navActive'>
                                <i><PointOfSale /></i>
                                <span>{traductionMap.pay}</span>
                            </NavLink>
                        </li>
                    :null}
                    <li>
                        <NavLink exact to="/" activeClassName='navActive'>
                            <i><HomeRounded /></i>
                            <span>{traductionMap.home}</span>
                        </NavLink>
                    </li>
                    {userRole === "SuperAdmin" || (userRole === "EventAdmin" && paths) ?
                        <li>
                            <NavLink exact to="/AdminConsole" activeClassName='navActive'>
                                <i><AdminPanelSettingsRounded /></i>
                                <span>{traductionMap.adminConsole}</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {userRole === "EventAdmin" && !paths?
                        <li>
                            <NavLink exact to="/EventAdminConsole" activeClassName='navActive'>
                                <i><AdminPanelSettingsRounded /></i>
                                <span>{traductionMap.adminConsole}</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {userRole === "BoothAdmin" ?
                        <li>
                            <NavLink exact to="/BoothAdminConsole" activeClassName='navActive'>
                                <i><AdminPanelSettingsRounded /></i>
                                <span>{traductionMap.adminConsole}</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {userRole === "SuperAdmin" || userRole === "BoothAdmin" || userRole === "EventAdmin"?
                        <li>
                            <NavLink exact to="/ItemListView" activeClassName='navActive'>
                                <i><InventoryRounded /></i>
                                <span>{traductionMap.productList}</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {userRole === "Staff"?
                        <li>
                            <NavLink exact to="/ItemListViewStaff" activeClassName='navActive'>
                                <i><StoreRounded /></i>
                                <span>{traductionMap.myProducts}</span>
                            </NavLink>
                        </li>
                        :null
                    }
                    {userRole === "SuperAdmin" || userRole === "EventAdmin" || userRole === "BoothAdmin" || userRole === "Staff" ?
                        <>
                            <li>
                                <NavLink exact to="/Commands" activeClassName='navActive'>
                                    <i><FactCheckRounded /></i>
                                    <span>{traductionMap.orderList}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/CommandScanner" activeClassName='navActive'>
                                    <i><QrCodeScannerRounded /></i>
                                    <span>{traductionMap.scanReceipt}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/ConsignHandler" activeClassName='navActive'>
                                    <i><CurrencyExchange /></i>
                                    <span>{traductionMap.returnDeposit}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/Settings" activeClassName='navActive'>
                                    <i><Settings /></i>
                                    <span>{traductionMap.settings}</span>
                                </NavLink>
                            </li>
                        </>
                        : null
                    }
                    {userRole === "SuperAdmin" || userRole === "EventAdmin" || userRole === "BoothAdmin" ?
                        <>
                            <li>
                                <NavLink exact to="/Statistics" activeClassName='navActive'>
                                    <i><QueryStats /></i>
                                    <span>{traductionMap.statistics}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/Receipts" activeClassName='navActive'>
                                    <i><Receipt/></i>
                                    <span>{traductionMap.receipts}</span>
                                </NavLink>
                            </li>
                        </>
                        : null
                    }
                    <li className='navSeparator'>
                        <div></div>
                    </li>
                    <li>
                        <div activeClassName='navActive' onClick={()=> setVisibility('visible')}>
                            <i><Translate /></i>
                            <span>{availableLanguages[localStorage.getItem('language')?localStorage.getItem('language'):navigator.language.substring(0, 2)]}</span>
                        </div>
                    </li>
                    <li>
                        <NavLink exact to="/UserGuide" activeClassName='navActive'>
                            <i><HelpRounded /></i>
                            <span>{traductionMap.help}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/About" activeClassName='navActive'>
                            <i><ContactPageRounded /></i>
                            <span>{traductionMap.about}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/" onClick={() => {
                            auth.signOut().then(() => {
                                localStorage.setItem("role", "undefined")
                                localStorage.setItem("userPath", "undefined")
                                window.location.reload();

                            });
                        }}>
                            <i><AccountCircleRounded /></i>
                            <span>{traductionMap.logOut}</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>
        </div>
    );
};

export default NavAdmin;
