import {React, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {getAnyDocById, UpdateFirebaseEvent, UpdateFirebaseEventOpen} from "../../repostirories/firebaseFunctions"

import LoadingScreen from '../../componants/LoadingScreen';
import { traductionMap } from '../../componants/Traduction';

function UpdateEvent() {
    const history = useHistory();
    const eventId = localStorage.getItem('selectedEventId')
    const [event, setEvent] = useState({})

    useEffect(() => {
        var item;
        async function getEvent(){
            item = await getAnyDocById('events/', eventId)
            setEvent(item)
        }
        getEvent()
    // eslint-disable-next-line
    },[])
    const updateEvent = () => {
        console.log(document.getElementById("eventInvisible").checked)
        var event = {};
        event.eventName = {}
    
        event.invisible = document.getElementById("eventInvisible").checked
        if(event.invisible === 'undefined'){
            event.invisible = document.getElementById("eventInvisible").defaultChecked
        }

        // add booth name in all languages
        event.eventName['default'] = document.getElementById("eventName").value;
        event.id = eventId
        
        UpdateFirebaseEvent(event, 'events')
        
        history.push("/EventAdminConsole")
    } 
   

    const openCloseEvent = () => {
        UpdateFirebaseEventOpen(event, 'events/')
        setEvent(s =>{
            return{
                ...s,
                open: !event.open
                
            }
        })
    } 



    
    const backButton = () =>{
        history.push('/EventAdminConsole')
    }
    return (
        event.id?
        <div className={"App"}>
            <div className="footerButton">
                <p className="backBasket" onClick={backButton}>{traductionMap.return}</p>
            </div>
            <br/>
            <div>
                {event.open ?
                    <span className="open" onClick={()=>openCloseEvent(event.open)}>Événement ouvert</span>
                :
                    <span className="close" onClick={()=>openCloseEvent(event.open)}>Événement fermé</span>}
            </div>
               
            <div className={'LoginView'}>
                <div>{traductionMap.boothName}</div>
                <div>
                    <input id={"eventName"} defaultValue={event.eventName['default']}
                    onChange = {e => event.eventName['default'] = e.currentTarget.value}></input>
                </div>
            </div>
            <div className={'LoginView'}>
                <div>{traductionMap.invisible}</div>
                <div>
                    <input type="checkbox" id={"eventInvisible"} defaultChecked={event.invisible}
                    onChange = {e => event.invisible = e.currentTarget.checked}></input>
                </div>
            </div>
            <p/>
            <div className={'LoginView'}><button  onClick={updateEvent}>{traductionMap.modifyEvent}</button></div>
        </div>:
        <LoadingScreen/>
        

    );

}
export default UpdateEvent;