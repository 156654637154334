import React, {Fragment, useEffect, useState} from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image} from '@react-pdf/renderer'
import { collection, getDocs, query, where } from "firebase/firestore"
import firebase from '../../firestore';
import Select from 'react-select';
import { getListOfPeriods, translate } from '../../tools/Tools';
import { GetOrdersByEvent } from '../../repostirories/firebaseFunctions';
import YearSelect from '../../componants/YearSelect';
import { traductionMap } from '../../componants/Traduction';
import { sQip } from '../../tools/configVariables';
import sqipLogo from '../../ressources/Logo.png';
import { fontSize, fontWeight } from '@mui/system';
var db = firebase.firestore()

function SelectBill ()
{
    // parameters
    const primaryColor = '#fbc306'
    const [eventSelected, setEventSelected] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [periodSelected, setPeriodSelected] = useState(false)
    const [eventNames, setEventNames] = useState([])
    const [invoice, setInvoice] = useState({})
    const [contract, setContract] = useState(null)
    const [periods, setPeriods] = useState(null)
    const today = new Date()
    // get events
    useEffect(() => {
        //Import the selected menu from firebase
        async function InitMenu() {
            var eventNames = []
            var itemList = [];
            await getDocs(collection(db, 'events/')).then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                if (doc.exists) {
                  // Convert to item object
                  var item;
                  item = doc.data();
                  item.id = doc.id;
      
                  itemList.push(item);
      
                } else {
                  console.log("No such document!")
                }
              })
              if (itemList.length !== 0) {
                itemList.forEach(item => {
                    eventNames.push({ value: item.id, label: translate(item.eventName) })
                })
                setEventNames(eventNames)
              }
            });
        }
        InitMenu()
        // eslint-disable-next-line
    }, [])
    const selectPeriod = async (o) =>{
        var periodStart = new Date(o.value.start)
        var periodEnd = new Date(o.value.end)
        
        var itemList = [];
        var invoice = {}
        invoice.items = []
        var balance = 0
        var i = 0
        var path = 'events/' + selectedEvent + '/menus/'
        var tempOrders = await GetOrdersByEvent(path)
        console.log(path)
        await getDocs(collection(db, path)).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                i++
            // doc.data() is never undefined for query doc snapshots
            if (doc.exists) {
                // Convert to item object
                var item;
                item = doc.data();
                item.id = doc.id;
    
                itemList.push(item);
                //
                balance += item.fees
                // set invoice parameters
                const date = new Date()
                invoice.id = item.id+date.getFullYear()+date.getMonth()+date.getDate()
                invoice.invoice_no = translate(item.menuName) + ' ' + periodEnd.getFullYear()+ '/' +(periodEnd.getMonth()+1)+'/' +periodEnd.getDate()
                invoice.balance = balance 
                invoice.company = translate(item.officialName)
                invoice.address = translate(item.adress)
                invoice.locality = translate(item.locality)
                invoice.trans_date = date.getDate()+ ' ' + date.toLocaleString(localStorage.getItem('language'), { month: 'long' })+ ' ' + date.getFullYear()
                if(!item.fees)
                    item.fees = 0
                
                

    
            } else {
                console.log("No such document!")
            }   
            })
            
        })

        let tempCommand = 0;
        let tempTurnover = 0;
        let tempTurnoverNotPaid = 0
        var tempSqip = 0;
        var tempPSP = 0
        var tempTva = 0
        var tempDeposit = 0
        var tempGivenBackMoney = 0
        //get sqip total of current period
        tempOrders.forEach((doc)=>{
            var totalCommandTva = 0
            // doc is never undefined for query doc snapshots
            if (doc && doc.paymentStatus) {
                var beginDate = new Date(o.value.start)
                var endDate = new Date(o.value.end)
                
                beginDate = new Date(o.value.start).toISOString()
                endDate =  new Date(o.value.end).toISOString()
                //must not take the "command" document wich is in the same collection
                if (doc.id.length > 10) {
                    if(new Date(doc.dateHour).toISOString() >= beginDate && (new Date(doc.dateHour).toISOString() <= endDate || endDate === undefined))
                    {
                        var order = doc
                        order.id = doc.id
                        if(doc.payrexxFee)
                            tempPSP += (doc.payrexxFee/100)
                        if(!doc.isRefund){
                            tempCommand++
                            if(doc.paymentSqip){
                                tempTurnover += doc["totalPrice"];
                            }
                            else{
                                tempTurnoverNotPaid += doc["totalPrice"]
                            }
                            
                            tempDeposit += doc.totalDeposit

                            tempSqip += doc["totalPrice"] * doc.sqipRate
                            //add to orderList
                            tempOrders.push(doc)

                            if(doc.givenBackMoney){
                                // adjust sqip part and vat in case of given back money
                                tempTva = tempTva - (doc.givenBackMoney * 0.077)
                                tempSqip = tempSqip - doc.givenBackMoney * doc.sqipRate
                                tempGivenBackMoney = tempGivenBackMoney + doc.givenBackMoney
                            }
                            doc.orderedItems.forEach(item => {
                                
                                var orderDate = new Date(doc.dateHour)
                                orderDate.setHours(0)
                                orderDate.setMinutes(0)
                                //get total of tva for one command
                                totalCommandTva =
                                    item.quantity * item.price * (item.tva / 100)  +  
                                    item.quantity * item.deposit * 0.077 + 
                                    totalCommandTva
                                
                                
                            })
                            // add total tva of command to global tva total
                            tempTva = tempTva + totalCommandTva
                        }
                    }
                }
            }
            else {
                console.log("No such document!");
            }
        })
        i = 0
        invoice.total = (tempTurnover - tempGivenBackMoney).toFixed(2)
        invoice.grossTotal = (tempTurnover- tempGivenBackMoney - tempSqip - tempPSP).toFixed(2)
        // set the contract fees for current period
        contract.invoices.forEach(element => {
            i++
            
            // set the amount depending on the type of payment
            switch (element.paymentType) {
                case 'sqipFees':
                    element.amount = tempSqip.toFixed(2)
                    break;
                case 'pspFees':
                    element.amount = tempPSP.toFixed(2)
                    break;
            
                default:
                    break;
            }
            var item ={
                sno: i,
                desc: translate(element.description),
                qty: element.quantity,
                rate: element.amount
            }
            //set if the invoice must be pushed in function of scheduled type
            switch (element.scheduleType) {
                case 'once':
                    if(element.date >= new Date(o.value.start).toISOString() && element.date <= new Date(o.value.end).toISOString()){
                        invoice.items.push(item)
                        invoice.grossTotal = invoice.grossTotal - element.amount
                    }
                    break;
                case 'monthly':
                    if(new Date(periodEnd.getFullYear(), periodEnd.getMonth() + 1, 0).getDate() === periodEnd.getDate()){
                        invoice.items.push(item)
                        invoice.grossTotal = invoice.grossTotal - element.amount
                    }
                    break;
                default:
                    invoice.items.push(item)
                    break;
            }     
        });
        
        if (contract.invoices.length !== 0) {
            setInvoice(invoice)
        }
        setPeriodSelected(true)

    }
    const selectEvent = async (o) =>{
        
        setSelectedEvent(o.value)
        setEventSelected(true) 
        const contractRef = collection(db, "contracts");
        
        console.log(contractRef)
        const selectedContract = query(contractRef, where("path", '==', 'events/' + o.value))
        const querySnapshot = await getDocs(selectedContract);
        querySnapshot.forEach((doc)=>{
            console.log(doc.data())
            var tempContract = doc.data()
            tempContract.id = tempContract.id
            setContract(tempContract)
            
            var p = getListOfPeriods(tempContract.paymentType, tempContract.numbPaymentByPeriod, today.getFullYear(), null)
            setPeriods(p)
        })
        

    }
    
  
    // Create styles
    const stylesGlobal = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 11,
            paddingTop: 30,
            paddingLeft:60,
            paddingRight:60,
            lineHeight: 1.5,
            flexDirection: 'column',
        }, 
        logo: {
            width: 74,
            height: 66,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    })
    //title
    const stylesTitle = StyleSheet.create({
   
        titleContainer:{
            flexDirection: 'row',
            marginTop: 24,
        },
        reportTitle:{
            color: primaryColor,
            letterSpacing: 4,
            fontSize: 25,
            textAlign: 'center',
            textTransform: 'uppercase',
        }
    });
    
    
    const InvoiceTitle = () => (
    <View style={stylesTitle.titleContainer}>
        <Text style={stylesTitle.reportTitle}>{traductionMap.invoice}</Text>
    </View>
    )

    //invoice Number
    const stylesNumber = StyleSheet.create({
        invoiceNoContainer: {
            flexDirection: 'row',
            marginTop: 36,
            justifyContent: 'flex-start'
        },
        invoiceDateContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-start'
        },
        invoiceDate: {
                fontSize: 12,
                fontStyle: 'bold',
                justifyContent: 'flex-start'
        },
        label: {
            width: 60
        }
        
    });
    
    // client part
    const stylesBillTo = StyleSheet.create({
        headerContainer: {
            marginTop: 36,
            justifyContent: 'flex-end',
            textAlign: 'right',
            position: 'absolute',
            right: 70,
            top: 92
        },
        billTo: {
            marginTop: 20,
            paddingBottom: 3,
            fontFamily: 'Helvetica-Oblique',
            justifyContent: 'flex-end'
        },
    });
    
    
    const BillTo = ({invoice}) => (
        <View style={stylesBillTo.headerContainer}>
            <Text>{invoice.company}</Text>
            <Text>{invoice.address}</Text>
            <Text>{invoice.locality}</Text>
            <Text>{invoice.phone}</Text>
            <Text>{invoice.email}</Text>
        </View>
    )
    // sqip part
    const stylesBillFrom = StyleSheet.create({
        headerContainer: {
            marginTop: 36
        },
        billTo: {
            marginTop: 20,
            paddingBottom: 3,
            fontFamily: 'Helvetica-Oblique'
        },
    });

    const InvoiceFrom = () => (
        <View style={stylesBillFrom.headerContainer}>
            <Text>{sQip.name}</Text>
            <Text>{sQip.address}</Text>
            <Text>{sQip.locality}</Text>
            <Text>{sQip.phone}</Text>
            <Text>{sQip.email}</Text>
        </View>
    )


    const InvoiceNo = ({invoice}) => (
   
        <Fragment>
            <View style={stylesNumber.invoiceNoContainer}>
                <Text style={stylesNumber.label}>{traductionMap.invoice} :</Text>
                <Text style={stylesNumber.invoiceDate}>{invoice.invoice_no}</Text>
            </View >
            <View style={stylesNumber.invoiceDateContainer}>
                <Text style={stylesNumber.label}>{traductionMap.date} : </Text>
                <Text >{invoice.trans_date}</Text>
            </View >
            <View style={stylesNumber.invoiceDateContainer}>
                <Text style={stylesNumber.label}>CAN : </Text>
                <Text >{invoice.total}</Text>
            </View >
            <View style={stylesNumber.invoiceDateContainer}>
                <Text style={stylesNumber.label}>Gains Brut : </Text>
                <Text >{invoice.grossTotal}</Text>
            </View >
        </Fragment>
    )
    // table header
    const stylesInvoiceHeader = StyleSheet.create({
        container: {
            flexDirection: 'row',
            borderBottomColor: primaryColor,
            backgroundColor: primaryColor,
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 24,
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        },
        description: {
            width: '60%',
            borderRightColor: primaryColor,
            borderRightWidth: 1,
        },
        qty: {
            width: '10%',
            borderRightColor: primaryColor,
            borderRightWidth: 1,
        },
        rate: {
            width: '15%',
            borderRightColor: primaryColor,
            borderRightWidth: 1,
        },
        amount: {
            width: '15%'
        },
    });

    const InvoiceTableHeader = () => (
        <View style={stylesInvoiceHeader.container}>
            <Text style={stylesInvoiceHeader.description}>{traductionMap.productDescription}</Text>
            <Text style={stylesInvoiceHeader.qty}>{traductionMap.quantity}</Text>
            <Text style={stylesInvoiceHeader.rate}>{traductionMap.price}</Text>
            <Text style={stylesInvoiceHeader.amount}>{traductionMap.total}</Text>
        </View>
    )
    //Invoice table Rows
    const stylesTableRows = StyleSheet.create({
        row: {
            flexDirection: 'row',
            borderBottomColor: primaryColor,
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 24,
            fontStyle: 'bold',
        },
        description: {
            width: '60%',
            textAlign: 'left',
            borderRightColor: primaryColor,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        qty: {
            width: '10%',
            borderRightColor: primaryColor,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        rate: {
            width: '15%',
            borderRightColor: primaryColor,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        amount: {
            width: '15%',
            textAlign: 'right',
            paddingRight: 8,
        },
    });
    
    
    const InvoiceTableRow = ({items}) => {
        const rows = items.map( item => 
            <View style={stylesTableRows.row} key={item.sno.toString()}>
                <Text style={stylesTableRows.description}>{item.desc}</Text>
                <Text style={stylesTableRows.qty}>{item.qty}</Text>
                <Text style={stylesTableRows.rate}>{item.rate}</Text>
                <Text style={stylesTableRows.amount}>{(item.qty * item.rate).toFixed(2)}</Text>
            </View>
        )
        return (<Fragment>{rows}</Fragment> )
    }

    // table footer
    const stylesTableFooter = StyleSheet.create({
        row: {
            flexDirection: 'row',
            borderBottomColor: primaryColor,
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 24,
            fontSize: 12,
            fontStyle: 'bold',
        },
        description: {
            width: '85%',
            textAlign: 'right',
            borderRightColor: primaryColor,
            borderRightWidth: 1,
            paddingRight: 8,
        },
        total: {
            width: '15%',
            textAlign: 'right',
            paddingRight: 8,
        },
      });
    
    
    const InvoiceTableFooter = ({items}) => {
        const total = items.map(item => item.qty * item.rate)
            .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
        return(    
            <View style={stylesTableFooter.row}>
                <Text style={stylesTableFooter.description}>{traductionMap.total}</Text>
                <Text style={stylesTableFooter.total}>{ Number.parseFloat(total).toFixed(2)}</Text>
            </View>
        )
    }
    // table
    const stylesTable = StyleSheet.create({
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 24,
            borderWidth: 1,
            borderColor: '#bff0fd',
        },
    });
    
    const InvoiceItemsTable = ({invoice}) => (
    <View style={stylesTable.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.items} />
        <InvoiceTableFooter items={invoice.items} />
    </View>
    )
    
    // sqip bank account
    const stylesBankAccount = StyleSheet.create({
        wholeContainer:{
            marginTop:20
        },
        invoiceNoContainer: {
            flexDirection: 'row',
            marginLeft: 0
        },
        invoiceDateContainer: {
            flexDirection: 'row',
        },
        invoiceDate: {
                fontSize: 12,
                fontStyle: 'bold',
        },
        label: {
            width: 100,
            fontWeight: 'bolder',
            fontFamily: 'Helvetica-Bold'
        },
        bankDetails: {
            color: primaryColor,
            fontWeight: 'bolder',
            fontSize:16,
            fontFamily: 'Helvetica-Bold'
        },
        info: {
            fontStyle: 'italic',
            textDecoration: 'underline',
            fontFamily: 'Helvetica-Oblique'
        }
    });
    
    
    const Bankaccount = () => (
        <Fragment style={stylesBankAccount.wholeContainer}>
            <View style={stylesBankAccount.wholeContainer}>
                <Text style={stylesBankAccount.info}>{traductionMap.forInfoPay}</Text>
            </View >
            <View style={stylesBankAccount.invoiceNoContainer}>
                <Text style={stylesBankAccount.bankDetails}>{traductionMap.bankDetails}</Text>
            </View >
            <View style={stylesBankAccount.invoiceNoContainer}>
                <Text style={stylesBankAccount.label}>{traductionMap.bank}</Text>
                <Text style={stylesBankAccount.invoiceDate}>{sQip.bankName}</Text>
            </View >
            <View style={stylesBankAccount.invoiceNoContainer}>
                <Text style={stylesBankAccount.label}>{traductionMap.bankCode} </Text>
                <Text >{sQip.bankCode}</Text>
            </View >
            <View style={stylesBankAccount.invoiceNoContainer}>
                <Text style={stylesBankAccount.label}>{traductionMap.iban}</Text>
                <Text style={stylesBankAccount.invoiceDate}>{sQip.iban}</Text>
            </View >
            <View style={stylesBankAccount.invoiceNoContainer}>
                <Text style={stylesBankAccount.label}>{traductionMap.swift}  </Text>
                <Text >{sQip.swift}</Text>
            </View >
        </Fragment>
    )

    // Create Document Component
    const Invoice = ({invoice}) => (
        <Document >
            <Page size="A4" style={stylesGlobal.page}>
                {/* <Image style={stylesGlobal.logo} src={logo}  /> */}
                <InvoiceTitle />
                <InvoiceFrom />
                <BillTo invoice={invoice}/>
                <InvoiceNo invoice={invoice}/>
                <InvoiceItemsTable invoice={invoice} />
                <Bankaccount  />
            </Page>
        </Document>
    )

    const onChangeSelectedYear = (year)=>{
        var p = getListOfPeriods(contract.paymentType, contract.numbPaymentByPeriod, year.value, {start: contract.startDate, end: contract.endDate})
        setPeriods(p)
        
    }
    
    console.log(periodSelected)
    return(
        <>
            {periodSelected?
            <PDFViewer width={'100%'}>
                <Invoice invoice={invoice}/>
            </PDFViewer>:
            
            <div className="Stats"> 
                <Select
                    id={'events'}
                    placeholder={traductionMap.eventName}
                    options={eventNames}
                    onChange={o => { selectEvent(o)}}
                ></Select>
                
            <YearSelect onChange={onChangeSelectedYear}></YearSelect>
            <Select
                id={'periods'}
                options={periods}
                onChange={o => { selectPeriod(o) }}
            ></Select>
            </div>
            }
        </>
    )
}

export default SelectBill