import React, { useEffect, useState } from 'react';
import '../../styles/views/consumerViews/EmailVerification.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Button from '../../componants/Button';
import { traductionMap } from '../../componants/Traduction';
import { GetCompanyList } from '../../repostirories/firebaseFunctions';
import Select from 'react-select';
import { useForceUpdate } from '../../tools/utils';

function ChangeCompany(props) {
    const [company, setCompany] = useState('')
    const [name, setName] = useState('')
    const [companyList, setCompanyList] = useState([])
    var phoneNumber = '+41'
    const changePhoneNumber = (phone) => {
        phoneNumber = ('+' + phone)
        localStorage.setItem("phoneNumber", '+' + phone)
    }
    var forceUpdate = useForceUpdate()
    
    var userInfos = {}
    if(localStorage.getItem('userInfos')){
        userInfos = JSON.parse(localStorage.getItem('userInfos'))
    }

    useEffect(() => {

        var companies = []

        async function setCompanies() {
            companies = await GetCompanyList();
            setCompanyList(companies)

        }
        setCompanies()
        // eslint-disable-next-line
    }, [])
    const selectCompany = () =>{
        if(company === ''){
            userInfos.company = userInfos.company
        }
        else
        userInfos.company = company

        if(name === ''){
            userInfos.name = userInfos.name
        }
        else
            userInfos.name = name
        if(phoneNumber === '+41'){
            userInfos.phoneNumber = localStorage.getItem("phoneNumber")
        }
        else
            userInfos.phoneNumber = phoneNumber

        localStorage.setItem('userInfos', JSON.stringify(userInfos))
        props.setVisible(false)
        forceUpdate()
        localStorage.setItem('companyVisible', 'false')
    }

    return (

        <div style={{ marginBottom: '100px', marginTop: '90px' }}>
            <br></br>
            {/* company name */}
            <Select
                id={'company'}
                defaultValue={userInfos.company}
                placeholder={traductionMap.company}
                options={companyList}
                onChange={o => { setCompany(o) }}
            ></Select>
            <p></p>
            {/* person name */}
            <input id='name' placeholder={'Nom'}defaultValue={userInfos.name} onChange={(e)=>setName(e.currentTarget.value)}></input>
            <p></p>
            <PhoneInput
                value={localStorage.getItem("phoneNumber")}
                countryCodeEditable={false}
                style={{ marginLeft: '12%', width: '12px!important' }}
                country={'ch'}
                onChange={phone => changePhoneNumber(phone)}
            />
            {/* <input autofocus style={{marginLeft: '12%'}} type={'tel'} onChange={phone => changePhoneNumber(phone)}></input> */}
            <div className='EmailVerification'>
                <Button onClick={()=>selectCompany()}>{traductionMap.order}</Button>
            </div>
        </div>
    )
}

export default ChangeCompany;