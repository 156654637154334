 
import React, { useEffect, useState } from 'react';
import firebase from '../../firestore';
import { ref, getDownloadURL, uploadBytesResumable, getStorage } from "firebase/storage";
import '../../styles/views/boothAdminViews/itemListView.css';
import { collection, getDocs } from 'firebase/firestore';
import { useForceUpdate } from '../../tools/utils';
import { traductionMap } from '../../componants/Traduction';
import { translate } from '../../tools/Tools';
import { AddFirebaseType, DeleteAnyDocById, UpdateFirebaseType } from '../../repostirories/firebaseFunctions';
import Trash from '../../ressources/trash.png'
import { deleteFile } from '../../repostirories/storageRepository';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

var db = firebase.firestore();

function TypeManagement() {
  const [types, setTypes] = useState([]);
  const boothPath = localStorage.getItem('userPath')
  const typePath = boothPath + 'types/'
  const [crops] = useState([]);
  const [srcs] = useState([]);
  const [outputs] = useState([]);
  const [allImgUrl, setAllImgUrl] = useState(new Map());
  const storage = getStorage();
  const [addTypeVisible, setAddTypeVisible] = useState('hidden')
  const forceUpdate = useForceUpdate()


  var i = 0
  //Import the selected menu from firebase
  useEffect(() => {
    
    async function InitTypes() {
      var typeList = []
      var type = {}
      const querySnapshot = await getDocs(collection(db, typePath));
      querySnapshot.forEach((doc) => {
        type = doc.data()
        type.id = doc.id
        type.typeName = doc.data().type['fr']
        typeList.push(type)
        

      });
      if (typeList.length !== 0) {
        //sort the list by type number
        typeList.sort((a, b) => {
          return a.number - b.number
        })
        setTypes(typeList)
        
      }
      
    }

    InitTypes()
    // eslint-disable-next-line
  }, [])
  //Import the selected image from cloud storage
  useEffect(() => {
    
    async function InitTypesImage() {
      types.forEach((type)=>{
        // get image
        const imageRef = ref(storage, `${boothPath}/typeImages/${type.number}.png`)
          
        getDownloadURL(imageRef).then(async (downloadURL) => {
          let blob = await fetch(downloadURL).then(r => r.blob())
          srcs[type.number] = URL.createObjectURL(blob)
          forceUpdate()
  
        });

      })
    }
   

    InitTypesImage()
    // eslint-disable-next-line
  }, [types])

  const handleSubmit = (e, item) => {
    console.log('handlesubmit')
    
    console.log(e.target[0].value)
    console.log(e.target[1].checked)
    console.log(e.target[2].value)
    console.log(e.target[3].value)
    console.log(e.target[4].value)
    e.preventDefault()
    const typeName = e.target[0].value
    var file = new File ([outputs[item.number]], '1.png')
    const invisible = e.target[1].checked
    const typeId = e.target[3].value
    const typeNumber = e.target[4].value

    //set the full image by default
    if(!outputs[item.number])
      file = e.target[2]?.files[0]

    var allCurrentImages = allImgUrl
    //set the new name
    var type = item
    type.type[localStorage.getItem('language')] = typeName
    type.invisible = invisible
    UpdateFirebaseType(type, typePath)

    if (!file) {
      alert(traductionMap.changesSaved)
      return;
    }
    if(file){
      const storageRef = ref(storage, `${boothPath}/typeImages/${typeNumber}.png`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on("state_changed",
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            allCurrentImages.set(typeId, downloadURL)
            setAllImgUrl(allCurrentImages)
            forceUpdate()
  
          });
        }
      );
    }

    alert(traductionMap.changesSaved)
    

  }
  const deleteItem = async (path, item) => {
    console.log(item)
    if (window.confirm('Êtes vous sûr de vouloir supprimmer ?'))
      await DeleteAnyDocById(path, item.id).then(()=>{
        deleteFile(`${path}/typeImages/${item.number}.png`).then(()=>{
          forceUpdate()
        })
      })
  }
  const addItem = async () => {
    var type = {}
    type.typeName = {}
    var number = i + 1
    type.number = number
    const file = document.getElementById('Typefile').value
    type.typeName.default = document.getElementById('typeName').value
    type.typeName[localStorage.getItem('language')] = document.getElementById('typeName').value
    console.log(type)

    AddFirebaseType(type, typePath).then(()=>{
      if (!file) return;
      if(file){
        const storageRef = ref(storage, `${boothPath}/typeImages/${type.number}.png`);
        uploadBytesResumable(storageRef, file).then(()=>{
          window.location.reload()
        })
        
        
      }
    })
  }
  // crop image
  const cropImageNow = async (crop, src, number) => {
    var image = new Image()
    image.src = src
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
  
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )
    canvas.toBlob((blob)=>{
      outputs[number] = blob
    })
    forceUpdate()
  }
  return (
    <div className="App">
      <h3>{traductionMap.categoryList}</h3>
      {types === null ? null : types.map((item) =>
      <div key={item.id} className='types' >
        <div style={{visibility:'hidden'}}>{i=item.number}</div>
        <form id={'form' + item.id} onSubmit={(e)=>handleSubmit(e,item)} style={{textAlign:'left'}}>
        
          <div style={{ height: "28px", marginLeft: "10px", display:'flex' }} >
            
            <input  id={"typeName" + item.id} defaultValue={translate(item.type)}/> 
            <img style={{ height: "28px" }} src={Trash} alt="delete" onClick={() => deleteItem(typePath, item)}/>
          </div>
          <div style={{ height: "28px", marginLeft: "10px", display:'flex' }} >
            <div style={{ marginRight: "10px" }}>{traductionMap.invisible + ' '}</div>
            <input id={'invisible' + item.id}type='checkbox' defaultChecked={item.invisible}></input>
          </div>
          
          
          <input id={'Typefile' + item.id} type='file' onChange={(e)=>{
            srcs[item.number]=URL.createObjectURL(e.target.files[0])
            forceUpdate()
            }} />
          <input id={'typeId' + item.id} style={{ visibility: 'hidden' }} type='text' readOnly value={item.id} />
          <input id={'typeNumber' + item.id} style={{ visibility: 'hidden' }} type='number' readOnly value={item.number} />
          <ReactCrop
          
            src={srcs[item.number]}
            crop={crops[item.number]}
            onChange={(c) => {
              crops[item.number]=(c)
              forceUpdate()}}
            onComplete={() => {cropImageNow(crops[item.number], srcs[item.number], item.number)
            }}
          >
            <img
              alt="Crop me"
              src={srcs[item.number]}
            />
          </ReactCrop> 
          <button type='submit'>{traductionMap.save}</button>
        </form>
        <div>
        
        </div>
        
        
        {/* {
          allImgUrl.get(item.id) &&
          <img id={'image' + item.id} src={images[item.number]} alt='uploaded file' height={200} />
        } */}
      </div>)}
      <div className="boothList-flex-container-4">
        <div  onClick={()=>setAddTypeVisible('visible')}>
          +
        </div>
      </div>
      <div id={'form'}  style={{textAlign:'left', visibility: addTypeVisible}}>
        <input  id={"typeName"} /> 
        <input id={'Typefile'} type='file' />
        <input id={'typeId'} style={{ visibility: 'hidden' }} type='text'/>
        <input id={'typeNumber'} style={{ visibility: 'hidden' }} type='number'  />

        <button onClick={()=>addItem()}>{traductionMap.save}</button>
      </div>
    </div>

  )
}

export default TypeManagement;
