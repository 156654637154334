import React from "react";
import QrReader from "react-qr-reader";
import { getAnyDocById } from "../../../repostirories/firebaseFunctions";
import { loadGateway } from "../../../tools/payrexx";
import { getPosition, translate } from "../../../tools/Tools";
import { traductionMap } from "../../../componants/Traduction";

function ScanPaymentQR()  {
    const delay = 300
    
    const  handleScan = async (data) => {
        if(data){
            data = data.split('||')
            console.log(data)
            const order = await getAnyDocById(data[0], data[1])
            console.log(order)
            //set all data from order in cache
            var itemPath = data[0].substring(0, data[0].indexOf('/commands')) + '/items/'
            localStorage.setItem('itemPath', itemPath)
            localStorage.setItem('order', JSON.stringify(order.orderedItems))
            localStorage.setItem("officialName", order.officialName)
            localStorage.setItem("adress", translate(order.adress))
            localStorage.setItem("locality", translate(order.locality))
            localStorage.setItem("dateHour" + order.id, order.dateHour)
            localStorage.setItem("totalDeposit", 0)
            localStorage.setItem("totalPrice", order.totalPrice)
            localStorage.setItem('vatNumber', order.vatNumber)
            localStorage.setItem('commandId', order.id)
            localStorage.setItem('sqipRate', order.sqipRate)
            const postData = {
                amount: order.totalPrice.toFixed(2)*100,
                currency: 'CHF',
                pm: order.pmList,
                referenceId: order.id,
                successRedirectUrl: window.location.href.substring(0, getPosition(window.location.href, "/", 3)) + '/Commanded',
                cancelRedirectUrl: window.location.href,
                failedRedirectUrl: window.location.href,
                path: '/' + data[0],
                psp:[44]
              };
              
              fetch('https://us-central1-sqipprod.cloudfunctions.net/createPayrexxGateway', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
              })
                .then(response => response.json()
                .then(data =>{
                  console.log(data)
                  if (!response.ok) {
                    throw new Error('Error: ' + response.status);
                  }
                  localStorage.setItem('gateLink', data.link)
                  localStorage.setItem('gateId', data.id)
                  window.location.replace(data.link);
                })
              )
        }
    }
    const handleError = (err) => {
        console.error(err);
    }
return (
    <div style={{marginTop: "100px", textAlign:"center", fontSize:"34px", fontWeight:"bolder"}}>
        <div style={{width: '100%'}}>{traductionMap.pay}</div>
        
        <>
        <QrReader
            delay={delay}
            onError={handleError}
            onScan={handleScan}
            style={{ height: "300px", width: '300px', margin: "0% 10% 0% 10%", border: "orange", borderStyle: "double", zIndex:"1"}}
            />
        </>
    </div>
);
}

export default ScanPaymentQR
