import React, { useState } from "react";
import Select from "react-select";

const today = new Date()
const startDate = today.getFullYear() - 2
const endDate = today.getFullYear() + 8
var defaultYears = []
for (let i = startDate; i <= endDate; i++) {
    defaultYears.push({ value: i, label: i });
}

const YearSelect = ({ onChange }) => {
    
  const [yearOptions, setYearOptions] = useState(defaultYears)
  const [selectedYear, setSelectedYear] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    let currentYear = selectedOption.value;
    let startYear = currentYear - 2;
    let endYear = currentYear + 8;
    updateYearOptions(startYear, endYear);
    onChange(selectedOption);
  };

  const updateYearOptions = (startYear, endYear) => {
    let newYearOptions = [];
    for (let i = startYear; i <= endYear; i++) {
      newYearOptions.push({ value: i, label: i });
    }
    setYearOptions(newYearOptions)
  };

  return (
    <Select
      options={yearOptions}
      placeholder={today.getFullYear()}
      defaultValue={today.getFullYear()}
      value={selectedYear}
      onChange={handleChange}
    />
  );
};

export default YearSelect;