import { useEffect, useState } from 'react';
import firebase, { auth } from '../firestore';
import { getPosition } from "../tools/Tools";

const useInitUser = () => {
    const [userRole, setUserRole] = useState(localStorage.getItem("role"));
    const [user, setUser] = useState("undefined");
    //check autentification
    auth.onAuthStateChanged(function (u) {
        if (u) {
            setUser(u);
        }
        else { }
    });
    useEffect(() => {
        async function initUser() {
            var role = localStorage.getItem("role");
            var path = localStorage.getItem("userPath");
            var paths = localStorage.getItem("userPaths")
            try {
                //find user Path
                if (user !== "undefined" && (role === null || role === "undefined" || role === '')) {
                    var docRef = firebase.firestore().collection("users").doc(user.uid);

                    await docRef.get().then((doc) => {
                        if (doc.exists) {
                            role = doc.data()["role"];
                            path = doc.data()["path"];
                            paths = JSON.stringify(doc.data().paths)
                            var event = path.substring(0, 20);
                            var booth = path.substring(20, 40);

                            localStorage.setItem('eventUserPath', path.substring(0,29) +'menus/')
                            console.log(localStorage.getItem("role"))
                            var pathRole = "/events/" + event + "/menu/" + booth + "/commands";

                            //set loggedCommandPath path in local storage
                            localStorage.setItem("LoggedCommandPath", pathRole);
                        }
                    }).catch((error) => {
                        console.log("Error getting document:", error, "user: ", user);
                    });
                    setUserRole(role);
                    localStorage.setItem("role", role);
                    localStorage.setItem("userPath", path);
                    localStorage.setItem("userPaths", paths);
                    if (userRole === null) {
                        setUserRole("undefined")
                    }
                    window.location.replace(window.location.href.substring(0, getPosition(window.location.href, "/", 3)))
                }
            } catch (Exception) { }
        }
        initUser();
    }, [userRole, user])

    return [userRole, user];
};

export default useInitUser;
