const roleList = ['SuperAdmin', 'EventAdmin', 'BoothAdmin', 'Staff']
const sQip = 
{
    name: 'sQip',
    address: 'Chemin de la Bergère 2',
    locality: '1802 Chardonne',
    country: {default: 'Suisse'},
    bankName: 'Raiffeisen',
    bankCode: 1635,
    iban: 'CH80 8080 8005 5278 9490 1',
    swift: 'RAIFCH22XXX'
}
export {roleList, sQip}