
import fr from '../traductionFiles/fr.json'
import en from '../traductionFiles/en.json'
import de from '../traductionFiles/de.json'
var traductionMap = {}
var availableLanguages = {fr: 'Français', en: 'English' , de: 'Deutsch'}

function getCountryList (){
    var countryList =[]
    for (var key in availableLanguages) {
        if (availableLanguages.hasOwnProperty(key)) {
            countryList.push(availableLanguages[key])
        }
    }
    return countryList
}
function setLanguage(language){
    console.log(language)
    switch(language){
        case 'fr':
        default:
            traductionMap.avoid = fr[0]
            traductionMap.queue = fr[1]
            traductionMap.ordAndPay = fr[2]
            traductionMap.makeOrder = fr[3]
            traductionMap.or = fr[4]
            traductionMap.scanAndAccess = fr[5]
            traductionMap.hello = fr[6]
            traductionMap.manageOrdersAndProducts = fr[7]
            traductionMap.manageOrders = fr[8]
            traductionMap.manageProduct = fr[9]
            traductionMap.about = fr[10]
            traductionMap.sqipDescription = fr[11]
            traductionMap.moreInfos = fr[12]
            traductionMap.loading = fr[13]
            traductionMap.myOrder = fr[14]
            traductionMap.selectPlace = fr[15]
            traductionMap.totalBasket = fr[16]
            traductionMap.pay = fr[17]
            traductionMap.clickToAccept = fr[18]
            traductionMap.terms = fr[19]
            traductionMap.continue = fr[20]
            traductionMap.modifyOrder = fr[21]
            traductionMap.noOrders = fr[22]
            traductionMap.paymentNotConfirmed = fr[23]
            traductionMap.orderNumber = fr[24]
            traductionMap.date = fr[25]
            traductionMap.vat = fr[26]
            traductionMap.total = fr[27]
            traductionMap.saveTicket = fr[28]
            traductionMap.returnBooth = fr[29]
            traductionMap.filters = fr[30]
            traductionMap.storeClosed = fr[31]
            traductionMap.booths = fr[32]
            traductionMap.noStore = fr[33]
            traductionMap.enterPhoneNr = fr[34]
            traductionMap.senCode = fr[35]
            traductionMap.enterCode = fr[36]
            traductionMap.confirmCode = fr[37]
            traductionMap.correctNumber = fr[38]
            traductionMap.resendCode = fr[39]
            traductionMap.help = fr[40]
            traductionMap.userGuide1 = fr[41]
            traductionMap.userGuide2 = fr[42]
            traductionMap.userGuide3 = fr[43]
            traductionMap.userGuide4 = fr[44]
            traductionMap.userGuide5 = fr[45]
            traductionMap.boothManagement = fr[46]
            traductionMap.productList = fr[47]
            traductionMap.categoryManagement = fr[48]
            traductionMap.statistics = fr[49]
            traductionMap.addProductList = fr[50]
            traductionMap.qtyAvailable = fr[51]
            traductionMap.threshold = fr[52]
            traductionMap.glutenFree = fr[53]
            traductionMap.vegeterian = fr[54]
            traductionMap.lactoseFree = fr[55]
            traductionMap.noUnder16 = fr[56]
            traductionMap.noUnder18 = fr[57]
            traductionMap.outOfStock = fr[58]
            traductionMap.scanToAccess = fr[59]
            traductionMap.save = fr[60]
            traductionMap.boothIs = fr[61]
            traductionMap.opened = fr[62]
            traductionMap.closed = fr[63]
            traductionMap.resetOrderNr = fr[64]
            traductionMap.archiveOrders = fr[65]
            traductionMap.totalOrders = fr[66]
            traductionMap.squipShare = fr[67]
            traductionMap.pspShare = fr[68]
            traductionMap.GrossEarnings = fr[69]
            traductionMap.vatShare = fr[70]
            traductionMap.netEarnings = fr[71]
            traductionMap.categoryList = fr[72]
            traductionMap.return = fr[73]
            traductionMap.boothName = fr[74]
            traductionMap.officialName = fr[75]
            traductionMap.address = fr[76]
            traductionMap.city = fr[77]
            traductionMap.modifyBooth = fr[78]
            traductionMap.login = fr[79]
            traductionMap.falseLoginOrPass = fr[80]
            traductionMap.resetPassword = fr[81]
            traductionMap.signIn = fr[82]
            traductionMap.addBooth = fr[83]
            traductionMap.boothList = fr[84]
            traductionMap.eventSettings = fr[85]
            traductionMap.openedEvent = fr[86]
            traductionMap.closedEvent = fr[87]
            traductionMap.modifyEvent = fr[88]
            traductionMap.showOrders = fr[89]
            traductionMap.location = fr[90]
            traductionMap.orderPaid = fr[91]
            traductionMap.status = fr[92]
            traductionMap.ready = fr[93]
            traductionMap.delivered = fr[94]
            traductionMap.editEvent = fr[95]
            traductionMap.orderIssued = fr[96]
            traductionMap.orderCancelled = fr[97]
            traductionMap.orderValid = fr[98]
            traductionMap.noOrder = fr[99]
            traductionMap.refundDep = fr[100]
            traductionMap.amountReturned = fr[101]
            traductionMap.noStock = fr[102]
            traductionMap.putHasStock = fr[103]
            traductionMap.hasStock = fr[104]
            traductionMap.putNoStock = fr[105]
            traductionMap.eventName = fr[106]
            traductionMap.addEvent = fr[107]
            traductionMap.defaultPassword = fr[108]
            traductionMap.addUser = fr[109]
            traductionMap.eventList = fr[110]
            traductionMap.trimming = fr[111]
            traductionMap.scanQr = fr[112]
            traductionMap.home = fr[113]
            traductionMap.order = fr[114]
            traductionMap.adminConsole = fr[115]
            traductionMap.myProducts = fr[116]
            traductionMap.orderList = fr[117]
            traductionMap.scanReceipt = fr[118]
            traductionMap.returnDeposit = fr[119]
            traductionMap.settings = fr[120]
            traductionMap.receipts = fr[121]
            traductionMap.logOut = fr[122]
            traductionMap.createQR = fr[123]
            traductionMap.myOrders = fr[124]
            traductionMap.totalDeposits = fr[125]
            traductionMap.orderReady = fr[126]
            traductionMap.ifSmsNotReceived = fr[127]
            traductionMap.skip = fr[128]
            traductionMap.skipPerm = fr[129]
            traductionMap.cancel = fr[130]
            traductionMap.confirm18 = fr[131]
            traductionMap.confirm = fr[132]
            traductionMap.confirmPerm = fr[133]
            traductionMap.confirm16 = fr[134]
            traductionMap.deposits = fr[135]
            traductionMap.selectNumber = fr[136]
            traductionMap.SMSMessage = fr[137]
            traductionMap.optional = fr[138]
            traductionMap.depositTotal = fr[139]
            traductionMap.givenBackDeposit = fr[140]
            traductionMap.variableDeposit = fr[141]
            traductionMap.productName = fr[142]
            traductionMap.productDescription = fr[143]
            traductionMap.add = fr[144]
            traductionMap.price = fr[145]
            traductionMap.name = fr[146]
            traductionMap.category = fr[147]
            traductionMap.unavailable= fr[148]
            traductionMap.income = fr[149]
            traductionMap.quantity = fr[150]
            traductionMap.product = fr[151]
            traductionMap.date = fr[152]
            traductionMap.xAxis = fr[153]
            traductionMap.yAxis= fr[154]
            traductionMap.postponedPayment = fr[155]
            traductionMap.customerMode = fr[156]
            traductionMap.color = fr[157]
            traductionMap.toBePaid = fr[158]
            traductionMap.itemDeleted = fr[159]
            traductionMap.paidBysQip = fr[160]
            traductionMap.notPaidBysQip = fr[161]
            traductionMap.changesSaved = fr[162]
            traductionMap.lifeGoesBy = fr[163]
            traductionMap.haveYouEver = fr[164]
            traductionMap.usYes = fr[165]
            traductionMap.whatIsSqip = fr[166]
            traductionMap.sqipDescription = fr[167]
            traductionMap.inPractice = fr[168]
            traductionMap.teamInNutshell = fr[169]
            traductionMap.weAre = fr[170]
            traductionMap.inFewFigures = fr[171]
            traductionMap.subItem = fr[172]
            traductionMap.addSubItem = fr[173]
            traductionMap.invoice = fr[174]
            traductionMap.bank = fr[175]
            traductionMap.bankCode = fr[176]
            traductionMap.iban = fr[177]
            traductionMap.swift = fr[178]
            traductionMap.bankDetails = fr[179]
            traductionMap.forInfoPay = fr[180]
            traductionMap.invisible = fr[181]
            traductionMap.errInDateFormatddmmyyyy = fr[182]
            traductionMap.company = fr[183]
            traductionMap.comment = fr[184]
            traductionMap.otherPm = fr[185]
            traductionMap.noPreparation = fr[186]
            traductionMap.refundOrder = fr[187]
            traductionMap.hour = fr[188]
            traductionMap.testMode = fr[189]
        break;
        case 'en':
            traductionMap.avoid = en[0]
            traductionMap.queue = en[1]
            traductionMap.ordAndPay = en[2]
            traductionMap.makeOrder = en[3]
            traductionMap.or = en[4]
            traductionMap.scanAndAccess = en[5]
            traductionMap.hello = en[6]
            traductionMap.manageOrdersAndProducts = en[7]
            traductionMap.manageOrders = en[8]
            traductionMap.manageProduct = en[9]
            traductionMap.about = en[10]
            traductionMap.sqipDescription = en[11]
            traductionMap.moreInfos = en[12]
            traductionMap.loading = en[13]
            traductionMap.myOrder = en[14]
            traductionMap.selectPlace = en[15]
            traductionMap.totalBasket = en[16]
            traductionMap.pay = en[17]
            traductionMap.clickToAccept = en[18]
            traductionMap.terms = en[19]
            traductionMap.continue = en[20]
            traductionMap.modifyOrder = en[21]
            traductionMap.noOrders = en[22]
            traductionMap.paymentNotConfirmed = en[23]
            traductionMap.orderNumber = en[24]
            traductionMap.date = en[25]
            traductionMap.vat = en[26]
            traductionMap.total = en[27]
            traductionMap.saveTicket = en[28]
            traductionMap.returnBooth = en[29]
            traductionMap.filters = en[30]
            traductionMap.storeClosed = en[31]
            traductionMap.booths = en[32]
            traductionMap.noStore = en[33]
            traductionMap.enterPhoneNr = en[34]
            traductionMap.senCode = en[35]
            traductionMap.enterCode = en[36]
            traductionMap.confirmCode = en[37]
            traductionMap.correctNumber = en[38]
            traductionMap.resendCode = en[39]
            traductionMap.help = en[40]
            traductionMap.userGuide1 = en[41]
            traductionMap.userGuide2 = en[42]
            traductionMap.userGuide3 = en[43]
            traductionMap.userGuide4 = en[44]
            traductionMap.userGuide5 = en[45]
            traductionMap.boothManagement = en[46]
            traductionMap.productList = en[47]
            traductionMap.categoryManagement = en[48]
            traductionMap.statistics = en[49]
            traductionMap.addProductList = en[50]
            traductionMap.qtyAvailable = en[51]
            traductionMap.threshold = en[52]
            traductionMap.glutenFree = en[53]
            traductionMap.vegeterian = en[54]
            traductionMap.lactoseFree = en[55]
            traductionMap.noUnder16 = en[56]
            traductionMap.noUnder18 = en[57]
            traductionMap.outOfStock = en[58]
            traductionMap.scanToAccess = en[59]
            traductionMap.save = en[60]
            traductionMap.boothIs = en[61]
            traductionMap.opened = en[62]
            traductionMap.closed = en[63]
            traductionMap.resetOrderNr = en[64]
            traductionMap.archiveOrders = en[65]
            traductionMap.totalOrders = en[66]
            traductionMap.squipShare = en[67]
            traductionMap.pspShare = en[68]
            traductionMap.GrossEarnings = en[69]
            traductionMap.vatShare = en[70]
            traductionMap.netEarnings = en[71]
            traductionMap.categoryList = en[72]
            traductionMap.return = en[73]
            traductionMap.boothName = en[74]
            traductionMap.officialName = en[75]
            traductionMap.address = en[76]
            traductionMap.city = en[77]
            traductionMap.modifyBooth = en[78]
            traductionMap.login = en[79]
            traductionMap.falseLoginOrPass = en[80]
            traductionMap.resetPassword = en[81]
            traductionMap.signIn = en[82]
            traductionMap.addBooth = en[83]
            traductionMap.boothList = en[84]
            traductionMap.eventSettings = en[85]
            traductionMap.openedEvent = en[86]
            traductionMap.closedEvent = en[87]
            traductionMap.modifyEvent = en[88]
            traductionMap.showOrders = en[89]
            traductionMap.location = en[90]
            traductionMap.orderPaid = en[91]
            traductionMap.status = en[92]
            traductionMap.ready = en[93]
            traductionMap.delivered = en[94]
            traductionMap.editEvent = en[95]
            traductionMap.orderIssued = en[96]
            traductionMap.orderCancelled = en[97]
            traductionMap.orderValid = en[98]
            traductionMap.noOrder = en[99]
            traductionMap.refundDep = en[100]
            traductionMap.amountReturned = en[101]
            traductionMap.noStock = en[102]
            traductionMap.putHasStock = en[103]
            traductionMap.hasStock = en[104]
            traductionMap.putNoStock = en[105]
            traductionMap.eventName = en[106]
            traductionMap.addEvent = en[107]
            traductionMap.defaultPassword = en[108]
            traductionMap.addUser = en[109]
            traductionMap.eventList = en[110]
            traductionMap.trimming = en[111]
            traductionMap.scanQr = en[112]
            traductionMap.home = en[113]
            traductionMap.order = en[114]
            traductionMap.adminConsole = en[115]
            traductionMap.myProducts = en[116]
            traductionMap.orderList = en[117]
            traductionMap.scanReceipt = en[118]
            traductionMap.returnDeposit = en[119]
            traductionMap.settings = en[120]
            traductionMap.receipts = en[121]
            traductionMap.logOut = en[122]
            traductionMap.createQR = en[123]
            traductionMap.myOrders = en[124]
            traductionMap.totalDeposits = en[125]
            traductionMap.orderReady = en[126]
            traductionMap.ifSmsNotReceived = en[127]
            traductionMap.skip = en[128]
            traductionMap.skipPerm = en[129]
            traductionMap.cancel = en[130]
            traductionMap.confirm18 = en[131]
            traductionMap.confirm = en[132]
            traductionMap.confirmPerm = en[133]
            traductionMap.confirm16 = en[134]
            traductionMap.deposits = en[135]
            traductionMap.selectNumber = en[136]
            traductionMap.SMSMessage = en[137]
            traductionMap.optional = en[138]
            traductionMap.depositTotal = en[139]
            traductionMap.givenBackDeposit = en[140]
            traductionMap.variableDeposit = en[141]
            traductionMap.productName = en[142]
            traductionMap.productDescription = en[143]
            traductionMap.add = en[144]
            traductionMap.price = en[145]
            traductionMap.name = en[146]
            traductionMap.category = en[147]
            traductionMap.unavailable= en[148]
            traductionMap.income = en[149]
            traductionMap.quantity = en[150]
            traductionMap.product = en[151]
            traductionMap.date = en[152]
            traductionMap.xAxis = en[153]
            traductionMap.yAxis= en[154]
            traductionMap.postponedPayment = en[155]
            traductionMap.customerMode = en[156]
            traductionMap.color = en[157]
            traductionMap.toBePaid = en[158]
            traductionMap.itemDeleted = en[159]
            traductionMap.paidBysQip = en[160]
            traductionMap.notPaidBysQip = en[161]
            traductionMap.changesSaved = en[162]
            traductionMap.lifeGoesBy = en[163]
            traductionMap.haveYouEver = en[164]
            traductionMap.usYes = en[165]
            traductionMap.whatIsSqip = en[166]
            traductionMap.sqipDescription = en[167]
            traductionMap.inPractice = en[168]
            traductionMap.teamInNutshell = en[169]
            traductionMap.weAre = en[170]
            traductionMap.inFewFigures = en[171]
            traductionMap.subItem = en[172]
            traductionMap.addSubItem = en[173]
            traductionMap.invoice = en[174]
            traductionMap.bank = en[175]
            traductionMap.bankCode = en[176]
            traductionMap.iban = en[177]
            traductionMap.swift = en[178]
            traductionMap.bankDetails = en[179]
            traductionMap.forInfoPay = en[180]
            traductionMap.invisible = en[181]
            traductionMap.errInDateFormatddmmyyyy = en[182]
            traductionMap.company = en[183]
            traductionMap.comment = en[184]
            traductionMap.otherPm = en[185]
            traductionMap.noPreparation = en[186]
            traductionMap.refundOrder = en[187]
            traductionMap.hour = en[188]
            traductionMap.testMode = en[189]


        break;
        case 'de':
            traductionMap.avoid = de[0]
            traductionMap.queue = de[1]
            traductionMap.ordAndPay = de[2]
            traductionMap.makeOrder = de[3]
            traductionMap.or = de[4]
            traductionMap.scanAndAccess = de[5]
            traductionMap.hello = de[6]
            traductionMap.manageOrdersAndProducts = de[7]
            traductionMap.manageOrders = de[8]
            traductionMap.manageProduct = de[9]
            traductionMap.about = de[10]
            traductionMap.sqipDescription = de[11]
            traductionMap.moreInfos = de[12]
            traductionMap.loading = de[13]
            traductionMap.myOrder = de[14]
            traductionMap.selectPlace = de[15]
            traductionMap.totalBasket = de[16]
            traductionMap.pay = de[17]
            traductionMap.clickToAccept = de[18]
            traductionMap.terms = de[19]
            traductionMap.continue = de[20]
            traductionMap.modifyOrder = de[21]
            traductionMap.noOrders = de[22]
            traductionMap.paymentNotConfirmed = de[23]
            traductionMap.orderNumber = de[24]
            traductionMap.date = de[25]
            traductionMap.vat = de[26]
            traductionMap.total = de[27]
            traductionMap.saveTicket = de[28]
            traductionMap.returnBooth = de[29]
            traductionMap.filters = de[30]
            traductionMap.storeClosed = de[31]
            traductionMap.booths = de[32]
            traductionMap.noStore = de[33]
            traductionMap.enterPhoneNr = de[34]
            traductionMap.senCode = de[35]
            traductionMap.enterCode = de[36]
            traductionMap.confirmCode = de[37]
            traductionMap.correctNumber = de[38]
            traductionMap.resendCode = de[39]
            traductionMap.help = de[40]
            traductionMap.userGuide1 = de[41]
            traductionMap.userGuide2 = de[42]
            traductionMap.userGuide3 = de[43]
            traductionMap.userGuide4 = de[44]
            traductionMap.userGuide5 = de[45]
            traductionMap.boothManagement = de[46]
            traductionMap.productList = de[47]
            traductionMap.categoryManagement = de[48]
            traductionMap.statistics = de[49]
            traductionMap.addProductList = de[50]
            traductionMap.qtyAvailable = de[51]
            traductionMap.threshold = de[52]
            traductionMap.glutenFree = de[53]
            traductionMap.vegeterian = de[54]
            traductionMap.lactoseFree = de[55]
            traductionMap.noUnder16 = de[56]
            traductionMap.noUnder18 = de[57]
            traductionMap.outOfStock = de[58]
            traductionMap.scanToAccess = de[59]
            traductionMap.save = de[60]
            traductionMap.boothIs = de[61]
            traductionMap.opened = de[62]
            traductionMap.closed = de[63]
            traductionMap.resetOrderNr = de[64]
            traductionMap.archiveOrders = de[65]
            traductionMap.totalOrders = de[66]
            traductionMap.squipShare = de[67]
            traductionMap.pspShare = de[68]
            traductionMap.GrossEarnings = de[69]
            traductionMap.vatShare = de[70]
            traductionMap.netEarnings = de[71]
            traductionMap.categoryList = de[72]
            traductionMap.return = de[73]
            traductionMap.boothName = de[74]
            traductionMap.officialName = de[75]
            traductionMap.address = de[76]
            traductionMap.city = de[77]
            traductionMap.modifyBooth = de[78]
            traductionMap.login = de[79]
            traductionMap.falseLoginOrPass = de[80]
            traductionMap.resetPassword = de[81]
            traductionMap.signIn = de[82]
            traductionMap.addBooth = de[83]
            traductionMap.boothList = de[84]
            traductionMap.eventSettings = de[85]
            traductionMap.openedEvent = de[86]
            traductionMap.closedEvent = de[87]
            traductionMap.modifyEvent = de[88]
            traductionMap.showOrders = de[89]
            traductionMap.location = de[90]
            traductionMap.orderPaid = de[91]
            traductionMap.status = de[92]
            traductionMap.ready = de[93]
            traductionMap.delivered = de[94]
            traductionMap.editEvent = de[95]
            traductionMap.orderIssued = de[96]
            traductionMap.orderCancelled = de[97]
            traductionMap.orderValid = de[98]
            traductionMap.noOrder = de[99]
            traductionMap.refundDep = de[100]
            traductionMap.amountReturned = de[101]
            traductionMap.noStock = de[102]
            traductionMap.putHasStock = de[103]
            traductionMap.hasStock = de[104]
            traductionMap.putNoStock = de[105]
            traductionMap.eventName = de[106]
            traductionMap.addEvent = de[107]
            traductionMap.defaultPassword = de[108]
            traductionMap.addUser = de[109]
            traductionMap.eventList = de[110]
            traductionMap.trimming = de[111]
            traductionMap.scanQr = de[112]
            traductionMap.home = de[113]
            traductionMap.order = de[114]
            traductionMap.adminConsole = de[115]
            traductionMap.myProducts = de[116]
            traductionMap.orderList = de[117]
            traductionMap.scanReceipt = de[118]
            traductionMap.returnDeposit = de[119]
            traductionMap.settings = de[120]
            traductionMap.receipts = de[121]
            traductionMap.logOut = de[122]
            traductionMap.createQR = de[123]
            traductionMap.myOrders = de[124]
            traductionMap.totalDeposits = de[125]
            traductionMap.orderReady = de[126]
            traductionMap.ifSmsNotReceived = de[127]
            traductionMap.skip = de[128]
            traductionMap.skipPerm = de[129]
            traductionMap.cancel = de[130]
            traductionMap.confirm18 = de[131]
            traductionMap.confirm = de[132]
            traductionMap.confirmPerm = de[133]
            traductionMap.confirm16 = de[134]
            traductionMap.deposits = de[135]
            traductionMap.selectNumber = de[136]
            traductionMap.SMSMessage = de[137]
            traductionMap.optional = de[138]
            traductionMap.depositTotal = de[139]
            traductionMap.givenBackDeposit = de[140]
            traductionMap.variableDeposit = de[141]
            traductionMap.productName = de[142]
            traductionMap.productDescription = de[143]
            traductionMap.add = de[144]
            traductionMap.price = de[145]
            traductionMap.name = de[146]
            traductionMap.category = de[147]
            traductionMap.unavailable= de[148]
            traductionMap.income = de[149]
            traductionMap.quantity = de[150]
            traductionMap.product = de[151]
            traductionMap.date = de[152]
            traductionMap.xAxis = de[153]
            traductionMap.yAxis= de[154]
            traductionMap.postponedPayment = de[155]
            traductionMap.customerMode = de[156]
            traductionMap.color = de[157]
            traductionMap.toBePaid = de[158]
            traductionMap.itemDeleted = de[159]
            traductionMap.paidBysQip = de[160]
            traductionMap.notPaidBysQip = de[161]
            traductionMap.changesSaved = de[162]
            traductionMap.lifeGoesBy = de[163]
            traductionMap.haveYouEver = de[164]
            traductionMap.usYes = de[165]
            traductionMap.whatIsSqip = de[166]
            traductionMap.sqipDescription = de[167]
            traductionMap.inPractice = de[168]
            traductionMap.teamInNutshell = de[169]
            traductionMap.weAre = de[170]
            traductionMap.inFewFigures = de[171]
            traductionMap.subItem = de[172]
            traductionMap.addSubItem = de[173]
            traductionMap.invoice = de[174]
            traductionMap.bank = de[175]
            traductionMap.bankCode = de[176]
            traductionMap.iban = de[177]
            traductionMap.swift = de[178]
            traductionMap.bankDetails = de[179]
            traductionMap.forInfoPay = de[180]
            traductionMap.invisible = de[181]
            traductionMap.errInDateFormatddmmyyyy = de[182]
            traductionMap.company = de[183]
            traductionMap.comment = de[184]
            traductionMap.otherPm = de[185]
            traductionMap.noPreparation = de[186]
            traductionMap.refundOrder = de[187]
            traductionMap.hour = de[188]
            traductionMap.testMode = de[189]


    } 
}



export {setLanguage, traductionMap, availableLanguages, getCountryList}