import React, { useEffect, useState } from 'react';
import firebase from '../../firestore';
import Flopy from '../../ressources/flopy.png'
import Trash from '../../ressources/trash.png'
import CsvReader from './CsvReader';
import '../../styles/views/boothAdminViews/itemListView.css';
import { traductionMap } from '../../componants/Traduction';
import { isValidDate, translate } from '../../tools/Tools';
import { useHistory } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import { getFirebaseTypebyBooth } from '../../repostirories/firebaseFunctions';
import { useForceUpdate } from '../../tools/utils';
import SubItems from '../../componants/SubItems';
import SearchBar from '../../componants/SearchBar';
import CategoryIcon from '@mui/icons-material/Category'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'

var db = firebase.firestore();

function ItemListView() {
  const history = useHistory()
  const [items, setItems] = useState(null);
  const forceUpdate = useForceUpdate()
  const menuPath = localStorage.getItem('userPath') + 'items/'
  const typesPath = localStorage.getItem('userPath') + 'types/'
  const [types, setTypes] = useState([])
  const [searchQuery, setSearchQuery] = useState("");
  
  localStorage.setItem('companyVisible', 'true')
  //Import the selected menu from firebase
  useEffect(() => {
    async function InitMenu() {
      db.collection(menuPath).onSnapshot(function (querySnapshot) {
        var itemList = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists) {
            // Convert to item object
            var item;
            item = doc.data();
            item.key = doc.id;
            item.quantity = 0;

            itemList.push(item);
            // Use a item instance method
            //console.log(item.id, item.itemName[language], item.price);
            //console.log("This " + item.toString());

          } else {
            console.log("No such document!")
          }
        })
        if (itemList.length !== 0) {
          //sort the list by item number
          itemList.sort((a, b) => {
            return a.itemNumber - b.itemNumber
          })
          setItems(itemList);
        }
      })
      var options = []
      getFirebaseTypebyBooth(typesPath).then((typeList)=>{
        typeList.forEach(element => {
          var option = {
            value: element.type.default,
            label: translate(element.type)
          }
          options.push(option)
          
        });
        
        
        setTypes(options)
      })

      

    }

    InitMenu();
    // eslint-disable-next-line
  }, []);
  const changeType = (item, o) => {
    item.type.default = o.value
    forceUpdate()
  }

  const saveChanges = () => {
    items.filter((item) => { //filter for the search
      if (searchQuery === '' || translate(item.itemName).toLowerCase().includes(searchQuery.toLowerCase())) {
        return item;
      } else {
        return null;
      }
    }).forEach(item => {
      //check date
      if(!isValidDate(item.date) && item.date){
        alert(traductionMap.errInDateFormatddmmyyyy)
        return;
      }

      var itemName = {}
      itemName[localStorage.getItem('language')] = document.getElementById("itemName" + item.key).value
      var description = {}
      description[localStorage.getItem('language')] = document.getElementById("description" + item.key).value
      var variableDepositName = {}
      variableDepositName[localStorage.getItem('language')] = document.getElementById("variableDepositName" + item.key).value
      var subItems = item.subItems
      db.collection(menuPath).doc(item.key).set({
        itemName: itemName,
        itemNumber: Number(document.getElementById('itemNumber' + item.key).value),
        description: description,
        price: Number(document.getElementById("price" + item.key).value),
        attributs: {
          glutenFree: (document.getElementById("glutenFree" + item.key).checked),
          vegetarian: (document.getElementById("vegetarian" + item.key).checked),
          lactoseFree: (document.getElementById("lactoseFree" + item.key).checked),
          eighteenMin: (document.getElementById("eighteenMin" + item.key).checked),
          sixteenMin: (document.getElementById("sixteenMin" + item.key).checked)
        },
        type: item.type,
        soldOut: document.getElementById("soldOut" + item.key).checked,
        invisible: document.getElementById("invisible" + item.key).checked,
        availableInStock: Number(document.getElementById("availableInStock" + item.key).value),
        stockThreshold: Number(document.getElementById("stockThreshold" + item.key).value),
        tva: Number(document.getElementById("vat" + item.key).value),
        deposit: Number(document.getElementById("deposit" + item.key).value),
        variableDepositName: variableDepositName,
        variableDepositPrice: Number(document.getElementById("variableDepositPrice" + item.key).value),
        subItems: subItems,
        date: document.getElementById("availableDate" + item.key).value,
        hour: document.getElementById("hour" + item.key).value
      }, { merge: true }).then(()=>{
      })
      .catch((e)=>{
        alert(e)
      });
    })
    
    alert("Changements sauvegardés");
  }
  const deleteItem = (id) => {
    if (window.confirm('Êtes vous sûr de vouloir supprimmer cet article ?'))
      db.collection(menuPath).doc(id).delete();
  }
  const sortProducts = (mode) =>{
    var products =[]
    if(mode === 'type'){
      products = items.sort((a, b) => a.type['default'].localeCompare(b.type['default']))
    }
    if(mode === 'alpha'){
      products = items.sort((a, b) => translate(a.itemName).localeCompare(translate(b.itemName)))
    }
    if(mode === 'number'){
      products = items.sort((a, b) =>{
        if (isNaN(a.itemNumber)) 
          a.itemNumber = 0;
        if (isNaN(b.itemNumber))
          b.itemNumber = 0

        return a.itemNumber - b.itemNumber
      } )
    }
    setItems(products)
    forceUpdate()
  }

  function handleDrop(targetItem, draggedItem) {
    const draggedItemNumber = draggedItem.itemNumber;
    const newItems = [...items];
    const draggedItemIndex = newItems.findIndex(item => item.itemNumber === parseInt(draggedItemNumber));
    const targetItemIndex = newItems.findIndex(item => item === targetItem);
    newItems.splice(targetItemIndex, 0, newItems.splice(draggedItemIndex, 1)[0]);
    newItems.forEach((item, index) => {
      item.itemNumber = index + 1;
    });
    setItems(newItems);
    forceUpdate()
  }
  return (
    <div className="App">
      {CsvReader(menuPath)}
      <SearchBar  onChange={e => setSearchQuery(e.target.value)} />

      <h3>{traductionMap.productList}</h3>
      <p/>
      <p/>
      <div className="boothList-flex-container-1">
        <FormatListNumberedIcon onClick={()=>{sortProducts('number')}}/>
        <CategoryIcon onClick={()=>{sortProducts('type')}}/>
        <SortByAlphaIcon onClick={()=>{sortProducts('alpha')}}/>
      </div>
      
      {items === null ? null : items.filter((item) => { //filter for the search
                if (searchQuery === '' || translate(item.itemName).toLowerCase().includes(searchQuery.toLowerCase())) {
                  return item;
                } else {
                  return null;
                }
              }).map((item) =>
              <DropTarget
              key={item.key}
              onHit={dragged => handleDrop(item, dragged.dragData)}
              >
       
          <div className="boothList-flex-container-1">
            
              <DragDropContainer
                dragData={item}
              >
                <input type='number' id={"itemNumber" + item.key} defaultValue={item.itemNumber} style={{ textAlign: "left", fontWeight: "bold", width: "42px" }} 
                value={item.itemNumber} readOnly={true}></input>
                <input id={"itemName" + item.key} defaultValue={translate(item.itemName)} onChange={e => item.itemName = e.currentTarget.value} style={{ fontWeight: "bold" }}></input>
            
              </DragDropContainer>
            <input id={"price" + item.key} defaultValue={item.price} style={{ textAlign: "right", fontWeight: "bold", width: "42px" }} type='number'></input>
            CHF
            <div style={{ height: "28px", marginLeft: "10px" }} onClick={() => deleteItem(item.key)}>
              <img style={{ height: "28px" }} src={Trash} alt="delete" />
            </div>
          </div>
          <div className="boothList-flex-container-2">
            <textarea id={"description" + item.key} defaultValue={translate(item.description)}></textarea>
          </div>
          <div className="boothList-flex-container-2">
            <div>{traductionMap.category} : </div>
            <Dropdown
                className='dropdownList'
                id={item.key}
                placeholder={translate(item.type)}
                options={types}
                onChange={o => { changeType(item, o) }}
              ></Dropdown>
          </div>
          
          <SubItems items={item.subItems} id={item.key}/>
          <div className="boothList-flex-container-2">
            {traductionMap.deposits} :  
            <input id={"deposit" + item.key} defaultValue={item.deposit} onChange={e => item.deposit = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
          </div>
          <div className="boothList-flex-container-2">
            {traductionMap.variableDeposit} :  
            <input id={"variableDepositName" + item.key} defaultValue={translate(item.variableDepositName)} onChange={e => item.deposit = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
            <input type='number' id={"variableDepositPrice" + item.key} defaultValue={item.variableDepositPrice} onChange={e => item.deposit = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
          </div>
          <div className="boothList-flex-container-2">
            {traductionMap.vat} : 
            <input id={"vat" + item.key} defaultValue={item.tva} onChange={e => item.tva = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
          </div>
          <div className="boothList-flex-container-2">
            {traductionMap.qtyAvailable}
            <input id={"availableInStock" + item.key} defaultValue={item.availableInStock} onChange={e => item.availableInStock = Number(e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
            {traductionMap.threshold}
            <input id={"stockThreshold" + item.key} defaultValue={item.stockThreshold} onChange={e => item.stockThreshold = Number(e.currentTarget.value)}  style={{ textAlign: "right", fontWeight: "bold", width: "42px" }}></input>

          </div>
          <div className="boothList-flex-container-2">
            {traductionMap.date + ' : '}
            <input id={"availableDate" + item.key} defaultValue={item.date} onChange={e => item.date = (e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
          </div>
          <div className="boothList-flex-container-2">{traductionMap.hour + ' : '}
            <input type='time' id={"hour" + item.key} defaultValue={item.hour} onChange={e => item.hour = (e.currentTarget.value)} style={{ fontWeight: "bold" }}></input>
          </div>
          <div className="boothList-flex-container-3">
            <div>
              {item.attributs["glutenFree"] ?
                <input type="checkbox" id={"glutenFree" + item.key} defaultChecked={true} ></input>
                :
                <input type="checkbox" id={"glutenFree" + item.key}></input>
              }

              {traductionMap.glutenFree}
            </div>
            <div>
              {item.attributs["vegetarian"] ?
                <input type="checkbox" id={"vegetarian" + item.key} defaultChecked={true} ></input>
                :
                <input type="checkbox" id={"vegetarian" + item.key}></input>
              }
              {traductionMap.vegeterian}
            </div>
            <div>
              {item.attributs["lactoseFree"] ?
                <input type="checkbox" id={"lactoseFree" + item.key} defaultChecked={true} ></input>
                :
                <input type="checkbox" id={"lactoseFree" + item.key}></input>
              }
              {traductionMap.lactoseFree}
            </div>
            <div>
              {item.attributs["sixteenMin"] ?
                <input type="checkbox" id={"sixteenMin" + item.key} defaultChecked={true} ></input>
                :
                <input type="checkbox" id={"sixteenMin" + item.key}></input>
              }
              {traductionMap.noUnder16}
            </div>
            <div>
              {item.attributs["eighteenMin"] ?
                <input type="checkbox" id={"eighteenMin" + item.key} defaultChecked={true} ></input>
                :
                <input type="checkbox" id={"eighteenMin" + item.key}></input>
              }
              {traductionMap.noUnder18}
            </div>
            <div>
              {item.soldOut ?
                <input type="checkbox" id={"soldOut" + item.key} defaultChecked={true} ></input>
                :
                <input type="checkbox" id={"soldOut" + item.key}></input>
              }
              {traductionMap.outOfStock}
            </div>
            <div>
              {item.invisible ?
                <input type="checkbox" id={"invisible" + item.key} defaultChecked={true} ></input>
                :
                <input type="checkbox" id={"invisible" + item.key}></input>
              }
              {traductionMap.invisible}
            </div>
          </div>
          <div className="boothList-flex-container-4" >
            <div onClick={() => saveChanges()}>
              <img src={Flopy} alt="qrCode" />
            </div>
            <div onClick={() => history.push('/AddSingleProduct')}>
              +
            </div>
          </div>
      
          </DropTarget>
      )}
    </div>

  )
}

export default ItemListView;
