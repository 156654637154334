import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../firestore';
import { languageContext } from '../../context/LanguageContext';
import { absoluteLanguageContext } from '../../context/LanguageContext';
import { currentPathContext } from '../../context/CurrentPathContext';
import Button from '../../componants/Button';
import { traductionMap } from '../../componants/Traduction';

var db = firebase.firestore();

function ItemListViewStaff() {
    const [items, setItems] = useState(null);
    const { language } = useContext(languageContext);
    const { absoluteLanguage } = useContext(absoluteLanguageContext);
    const { itemPath } = useContext(currentPathContext);
    const menuPath = localStorage.getItem('userPath') + 'items/'

    //Import the selected menu from firebase
    useEffect(() => {
        let unsubscribe;
        async function InitMenu() {
            unsubscribe = db.collection(menuPath).onSnapshot(function (querySnapshot) {
                var itemList = [];
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    if (doc.exists) {
                        // Convert to item object
                        var item;
                        item = doc.data();
                        item.key = doc.id;
                        item.quantity = 0;
                        if (item.itemName[language] != null)
                            item.name = item.itemName[language];
                        else
                            item.name = item.itemName[absoluteLanguage];

                        if (item.description[language] != null)
                            item.description = item.description[language];
                        else
                            item.description = item.description[absoluteLanguage];

                        itemList.push(item);
                        // Use a item instance method
                        //console.log(item.id, item.itemName[language], item.price);
                        //console.log("This " + item.toString());

                    } else {
                        console.log("No such document!")
                    }
                })
                if (itemList.length !== 0) {
                    setItems(itemList);
                }
            });
        }

        InitMenu();
        return () => { unsubscribe() };
    }, [itemPath, language, absoluteLanguage, menuPath]);


    const changeStock = (item) => {
        if (item.soldOut) {
            db.collection(menuPath).doc(item.key).set({
                soldOut: false
            }, { merge: true });
        } else {
            db.collection(menuPath).doc(item.key).set({
                soldOut: true,
            }, { merge: true });
        }
    }

    return (
        <div className="itemListStaff">

            <h3>{traductionMap.productList}</h3>
            {items === null ? null : items.map((item) =>
                <div className="itemListStaffContent" key={item.key} >
                    <p className="itemNameListStaff">{item.name}</p>
                    <div className="itemListStaffQuantity">
                        {traductionMap.qtyAvailable}
                        <input id={"itemQty" + item.key} defaultValue={item.availableInStock} onChange={e => item.availableInStock = e.currentTarget.value} style={{ fontWeight: "bold" }}></input>
                        {traductionMap.threshold}
                        <input id={"itemThreshold" + item.key} defaultValue={item.stockThreshold} style={{ textAlign: "right", fontWeight: "bold", width: "42px" }}></input>

                    </div>
                    {item.soldOut ?
                        <div className="managmentListStaff">
                            <p> <span className='itemSoldout'>{traductionMap.noStock}</span></p>
                            <Button onClick={() => changeStock(item)}>{traductionMap.putHasStock}</Button>
                        </div>
                        :
                        <div className="managmentListStaff">
                            <p> <span className='itemInStock'>{traductionMap.hasStock}</span></p>
                            <Button onClick={() => changeStock(item)}>{traductionMap.putNoStock}</Button>
                        </div>
                    }
                </div>
            )}
        </div>

    )
}

export default ItemListViewStaff;
