import React from 'react';

import { QRCode } from "react-qr-svg";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

import { formatDate, getDate, translate } from '../../tools/Tools';
import Button from '../../componants/Button';
import { traductionMap } from '../../componants/Traduction';
import WhiteSquare from "../../ressources/whitesquare.png";


var commandMap = new Map();

function MyOrders() {

    var orderList = JSON.parse(localStorage.getItem('myOrders'))
    
    if(orderList){
        //sort desc date
        orderList.sort((a, b) => {
            return a.dateHour  - b.dateHour 
        })

        orderList.reverse((a, b) => {
            return a.dateHour  - b.dateHour 
        })

    
        //set VAT
        orderList.forEach((order) => {
            var tvaMap = new Map();
            var tvaList = []
            console.log(order)
            order.orderedItems.forEach((item)=> {
                if (tvaMap[item.tva] === undefined){
                    tvaMap[item.tva] = 0
                    tvaList.push(item.tva)
                }
                console.log(tvaMap)
                tvaMap[item.tva] = tvaMap[item.tva] + (item.price * item.quantity * item.tva / 100) 
                                    //add deposit tva
                                    + (item.deposit * item.quantity * item.tva / 100)

            })
            console.log(commandMap)
            commandMap[order.id] = tvaMap
            order.tvaList = tvaList
            tvaMap = new Map();
            tvaList = []
        })

    }
    
    const downloadHandler = (event) => {
    event.preventDefault();
    domtoimage.toBlob(document.getElementById('MyOrders'))
    .then(function (blob) {
        saveAs(blob, 'sqipCommandorders.png');
    });
    }
    return (
        <>
            <div className='commanded'>
                <div id="myOrders" className='commandedContent'>
                    <h1>{traductionMap.myOrders}</h1>
                    {orderList?
                    <>
                        {orderList.map((order) =>
                            <div style={{marginBottom: '44px'}}>
                                <div id="command" className='command' >
                                    <div className="commandNumber" >

                                        <div > {traductionMap.orderNumber}  {order.number} </div>
                                        <p style={{ textAlign: 'center', fontSize: '14px' }}>
                                            {order.officialName}<br />
                                            {order.adress}<br />
                                            {order.locality}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>{traductionMap.date} {formatDate(getDate(order.dateHour))}</p>
                                    </div>
                                    {order.orderedItems.map((item) =>
                                        <div key={item.key} className="flexContainer-receipt">
                                            <div style={{ width: "12%" }}> {item.quantity}X</div>
                                            <div style={{ width: "50%" }}> {translate(item.itemName).substring(0, 25)} </div>
                                            <div style={{ textAlign: "right", width: "25%", marginRight: "1px" }}> {(item.price * item.quantity).toFixed(2)} CHF</div>
                                        </div>
                                    )}
                                    {order.totalDeposit > 0?
                                        <div className="flexContainer-receipt" style={{ fontWeight: "bolder",  fontSize: "14px"}}>
                                            <div style={{ width: "45%" }}>{traductionMap.totalDeposits} </div>
                                            <div style={{ textAlign: "right", width: "45%" }}>{order.totalDeposit.toFixed(2)} CHF</div>
                                        </div>:null
                                    }
                                    
                                    {order.tvaList.map((tvaRate) =>
                                        <div className="flexContainer-receipt" style={{ fontWeight: "bold", fontSize: "14px" }}>
                                            <div style={{width: "45%" }}>{traductionMap.vat} {tvaRate}% </div>
                                            <div style={{ textAlign: "right" , width: "45%" }}>{commandMap[order.id][tvaRate].toFixed(2)} CHF</div>
                                        </div>
                                    )}
                                    <div className="flexContainer-receipt" style={{ fontWeight: "bold", fontSize: "14px" }}>
                                        <div style={{ width: "45%" }}>{order.vatNumber} </div>
                                    </div>

                                    <div className="flexContainer-receipt" style={{ fontWeight: "bolder", fontSize: "16px" }}>
                                        <div style={{ width: "45%" }}>{traductionMap.total} </div>
                                        <div style={{ textAlign: "right", width: "45%" }}>{order.totalPrice.toFixed(2)} CHF</div>
                                    </div>
                                    
                                    {order.id !== null ?
                                        <div style={{ textAlign: "center", marginTop: "30px", position: 'relative'}} >
                                            <img src={WhiteSquare} alt=""  />
                                            <QRCode
                                                bgColor="transparent"
                                                fgColor="#000000"
                                                level="Q"
                                                style={{ zIndex: '2000', position: 'absolute', 
                                                top: '0px', left: '0px', right:'0px', width: '160px', marginLeft:'auto', marginRight:'auto',
                                                marginTop: 'auto', marginBottom: 'auto', bottom:'0' }}
                                                value={order.id}
                                            >
                                            </QRCode>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className='buttonSaveCommand' style={{ textAlign: "center", marginTop: "30px" }}>
                                    <Button onClick={downloadHandler}>{traductionMap.saveTicket}</Button>
                                </div>
                            </div>)
                        }
                    </>:null}
                </div>
            </div>
        </>
    )
}
export default MyOrders;
