import React from 'react';

//This is the language chosen by the user
export const languageContext = React.createContext({
    language: "fr", 
    setLanguage: () => {}
});
//This is the language chosen by the event owner
export const absoluteLanguageContext = React.createContext({
    absoluteLanguage: "fr", 
    setAbosluteLanguage: () => {}
});

//list of all supported languages
export const languages = ['fr', 'en', 'de', 'it'];

