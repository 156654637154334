import React from 'react';

const ListItem = ({
    key,
    children,
    onClick
}) => {
    return (
        <div className="listItemContent" key={key}>
            <p onClick={onClick}>
                {children}
            </p>
        </div>
    );
};

export default ListItem;
