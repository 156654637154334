import React, { useEffect, useState } from 'react';
import firebase from '../../../firestore';
import { addDoc, collection} from "firebase/firestore";
import { useHistory } from "react-router-dom";
import Popup from 'reactjs-popup';
import loadingGif from '../../../ressources/queuesQipAnimate.gif';
import Button from '../../../componants/Button';
import CloseIcon from '@mui/icons-material/Close';
import { addZero, translate } from '../../../tools/Tools';
import { traductionMap } from '../../../componants/Traduction';


var db = firebase.firestore();

function PartialOrderView() {
    const  itemPath  = localStorage.getItem('currentItemPath')
    
    const basket = JSON.parse(localStorage.getItem('basket'))
    const [isLoading, setIsLoading] = useState(false)

    const [price, setPrice] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const history = useHistory();
    const boothFullPath = itemPath.substring(0, itemPath.indexOf('/items'))

    useEffect(() => {
        async function setCurrentBasket() {
           
            setPrice(0);

            let tempPrice = 0;
            let totalDeposit = 0;

            basket.map((item) =>{
                tempPrice += (item.quantity*item.price)

                totalDeposit += (item.quantity*item.deposit)
                if(item.variableDepositQty)
                    totalDeposit += (item.variableDepositQty*item.variableDepositPrice)
                return tempPrice
            })
            setPrice(tempPrice);

            isNaN(totalDeposit)?
                setDeposit(0):
                setDeposit(totalDeposit)

            basket.totalPrice = tempPrice
            basket.totalDeposit = deposit
            localStorage.setItem('totalPrice', tempPrice)
            localStorage.setItem('totalDeposit', deposit)
        }

        setCurrentBasket();
        // eslint-disable-next-line
    }, [basket, itemPath]);

    const backButton = () => {
        let push = itemPath.substring(0, itemPath.length)
        history.push("/Items/" + push);
    }

    const commandButton = async () => {
        setIsLoading(true)
        var today = new Date()
        await addDoc(collection(db, boothFullPath + '/partialOrders'), {
            orderedItems: basket.filter(item=>item.quantity>0),
            totalPrice: price,
            paymentStatus: false,
            commandStatus: 1,
            dateHour: today.getFullYear() + "/" + addZero((today.getMonth() + 1),2) + "/" + addZero(today.getDate(),2) + " " + addZero(today.getHours(),2) + ":" + addZero(today.getMinutes(),2)
        }).then(()=>{
            localStorage.setItem('basket', '')
            history.push('/FullOrderView')
            setIsLoading(false)
        })
    }
    return (
        <>
            {isLoading ?
                <div style={{ position: 'absolute', top: '30%', textAlign: 'center', fontSize: '170%' }} >
                    <img width='70%' src={loadingGif} className='loadingGif' alt='loadingGif'></img>
                    <div>{traductionMap.loading} ...</div>
                </div>
                :
                <div className='basketView'>
                    <h1>{traductionMap.myOrder}</h1>
                   
                    <div>
                        <div className="basketContent">
                            {basket.sort((a, b) => a.type > b.type ? -1 : 1).map((item) =>
                                item.quantity > 0 ?
                                    <div style={{borderBotom: 'grey solid 2px' }}>
                                        <span>{item.quantity}</span>
                                        <span> X </span>
                                        <span>{translate(item.itemName)}</span>
                                        <span> (</span>
                                        <span>{item.price} CHF) </span>
                                        <span> = </span>
                                        <span>{(item.price * item.quantity).toFixed(2)} CHF</span>
                                        
                                        <div className='listSubItemContent'>
                                            {item.selectedSubItems !== undefined ?
                                                item.selectedSubItems.map((subItem)=>(
                                                <div >
                                                    {translate(subItem)} 
                                                </div>
                                                )):null
                                            }
                                        </div>
                                        {item.variableDepositQty > 0 ?
                                            <div className='listSubItemContent'>
                                                {item.variableDepositQty + ' ' +translate(item.variableDepositName)}
                                            </div>
                                            :null
                                        }
                                    </div>
                                    :null
                                )}
                            <p className="basketTotalPrice" >
                                <p>{traductionMap.totalBasket} : {(price+ deposit).toFixed(2)} CHF</p>
                            </p>
                        </div>

                        <Popup
                            trigger={<div className='EmailVerification'>
                                <Button>{traductionMap.order}</Button>
                            </div>}
                            modal
                            nested
                        >
                            {close => (
                                <div className="modal">
                                    <div className="content">
                                        <i className="closeButton" onClick={close}>
                                            <CloseIcon />
                                        </i>
                                        <p>{traductionMap.clickToAccept} <br /><a href="https://sqip.ch/CGU.pdf" target="blank">{traductionMap.terms}</a></p>
                                    </div>
                                    <div className="actions">
                                        <Button
                                            onClick={() => {
                                                commandButton();
                                                close();
                                            }}
                                        >
                                            {traductionMap.continue}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div> 
                    

                    <div className='modifyCommand'>
                        <Button onClick={backButton}>{traductionMap.modifyOrder}</Button>
                    </div>

                </div>
            }
        </>
    )
}

export default PartialOrderView;
