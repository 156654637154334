import React, { useEffect, useState } from 'react';
import firebase from '../../../firestore';
import { collection, query, where, getDocs, doc, setDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import loadingGif from '../../../ressources/queuesQipAnimate.gif';
import Button from '../../../componants/Button';
import { addZero, getPosition, translate } from '../../../tools/Tools';
import { traductionMap } from '../../../componants/Traduction'
import WhiteSquare from "../../../ressources/whitesquare.png"
import { QRCode } from "react-qr-svg";
import { generateKey, useForceUpdate } from '../../../tools/utils';
import { getAnyDocById } from '../../../repostirories/firebaseFunctions';


var db = firebase.firestore();

function PartialOrderView() {
    const forceupdate = useForceUpdate()
    const mode = localStorage.getItem('mode')
    var  itemPath  = localStorage.getItem('currentItemPath')
    if(mode !== 'client'){
        itemPath = localStorage.getItem('userPath') + 'items/'
    }
    
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory();
    const boothFullPath = itemPath.substring(0, itemPath.indexOf('/items'))
    const boothPath = itemPath.substring(0, getPosition(itemPath, '/', 3))
    const boothId = itemPath.substring(getPosition(itemPath, '/', 3)+1, itemPath.indexOf('/items'))
    const [order, setOrder] = useState({orderedItems: [], totalPrice: 0})
    const [orderId, setOrderId] = useState('')
    useEffect(() => {
        async function setCurrentOrder() {
            //look for all orders shich aren't paid
            var orders = []
            var id
            const unpaidOrders = query(collection(db, boothFullPath +'/commands'), where("paymentStatus", "==", false))

            const unpaidOrdersSnapshot = await getDocs(unpaidOrders);
            unpaidOrdersSnapshot.forEach((doc) => {
                var order 
                order = doc.data()
                order.id = doc.id
                orders.push(order)
                setOrderId(doc.id)
                id = doc.id
            })
            if(!id && mode === 'client'){
                id = generateKey()
                setOrderId(id)
            }else if(!id){
                id = orderId
            }
            if(id){
                setIsLoading(true)
                var tempOrder = {}
                tempOrder.totalPrice = 0
                tempOrder.orderedItems = []
    
                const q = query(collection(db, boothFullPath + '/partialOrders'), where('paymentStatus', "==", false))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    doc.data().orderedItems.forEach(item => {
                        // add to total
                        tempOrder.totalPrice += item.price * item.quantity
                        //concat if same product 
                        var product = tempOrder.orderedItems.filter(
                            i=>i.itemName.default === item.itemName.default &&
                            i.price === item.price &&
                            i.deposit === item.deposit
                        )
                        if(product.length > 0)
                            item.quantity += product[0].quantity
                        // delete the previous existing product
                        tempOrder.orderedItems = tempOrder.orderedItems.filter(
                            i=> i.itemName.default !== item.itemName.default ||
                            i.price !== item.price ||
                            i.deposit !== item.deposit
                        )
                        // add it again with merged quantity
                        tempOrder.orderedItems.push(item)
                    })
                    
                })
                setOrder(tempOrder)
                const booth = await getAnyDocById(boothPath, boothId)
                const commandsRef = doc(db, boothFullPath + '/commands/', id);
    
    
                var today = new Date();
                var dateCommand = today.getFullYear() + "/" + addZero((today.getMonth() + 1),2) + "/" + addZero(today.getDate(),2) + " " + addZero(today.getHours(),2) + ":" + addZero(today.getMinutes(),2);
                            
                var sqipRate = 0
                if (booth.sqipRate)
                    sqipRate = booth.sqipRate
                //add full order in database with status 0
                setDoc(commandsRef, 
                    { 
                        orderedItems: tempOrder.orderedItems,
                        commandStatus: 0,
                        dateHour: dateCommand,
                        number: 0,
                        paymentStatus: false,
                        totalPrice: tempOrder.totalPrice,
                        totalDeposit: 0,
                        sqipRate: sqipRate,
                        officialName: translate(booth.officialName),
                        adress: booth.adress,
                        locality: booth.locality,
                        vatNumber: booth.vatNumber,
                        SMSMessage: JSON.parse(localStorage.getItem('SMSMessage')),
                        pmList: booth.pmList,
                        postponedPayment: true,
                        paymentSqip: false
                    }, 
                    { merge: true }).then(()=>{
                        forceupdate()
                    }); 
                setIsLoading(false)
            }
            
           
        }
        setCurrentOrder();
        // eslint-disable-next-line
    }, []);

    const backButton = () => {
        let push = itemPath.substring(0, itemPath.length)
        history.push("/Items/" + push);
    }

    const SetOrderPaid = async () => {
        //set all partial orders as paid
        const querySnapshot = await getDocs(collection(db, boothFullPath + '/partialOrders'));
        querySnapshot.forEach((item) => {
            const partialOrderRef = doc(db, boothFullPath + '/partialOrders', item.id);
            setDoc(partialOrderRef, { paymentStatus: true }, { merge: true });
        })
        const commandsRef = doc(db, boothFullPath + '/commands/', orderId)
        setDoc(commandsRef, 
            { 
                commandStatus: 3,
                paymentStatus: true,
                paymentSqip: false
            }, 
            { merge: true }
        ).then(()=>{
            alert(traductionMap.orderPaid)
            window.location.reload()
        })
    }
    return (
        <>
            {isLoading ?
                <div style={{ position: 'absolute', top: '30%', textAlign: 'center', fontSize: '170%' }} >
                    <img width='70%' src={loadingGif} className='loadingGif' alt='loadingGif'></img>
                    <div>{traductionMap.loading} ...</div>
                </div>
                :
                <div className='basketView'>
                    <h1>{traductionMap.myOrder}</h1>
                   
                    <div>
                        <div className="basketContent">
                            {order.orderedItems.map((item) =>
                                item.quantity > 0 ?
                                    <div style={{borderBotom: 'grey solid 2px' }}>
                                        <span>{item.quantity}</span>
                                        <span> X </span>
                                        <span>{translate(item.itemName)}</span>
                                        <span> (</span>
                                        <span>{item.price} CHF) </span>
                                        <span> = </span>
                                        <span>{(item.price * item.quantity).toFixed(2)} CHF</span>
                                        
                                        <div className='listSubItemContent'>
                                            {item.selectedSubItems !== undefined ?
                                                item.selectedSubItems.map((subItem)=>(
                                                <div >
                                                    {translate(subItem)} 
                                                </div>
                                                )):null
                                            }
                                        </div>
                                        {item.variableDepositQty > 0 ?
                                            <div className='listSubItemContent'>
                                                {item.variableDepositQty + ' ' +translate(item.variableDepositName)}
                                            </div>
                                            :null
                                        }
                                    </div>
                                    :null
                                )}
                            <p className="basketTotalPrice" >
                                <p>{traductionMap.totalBasket} : {(order.totalPrice).toFixed(2)} CHF</p>
                            </p>
                            {orderId?
                                <div style={{ textAlign: "center", marginTop: "30px", position: 'relative'}} >
                                    <img src={WhiteSquare} alt=""  />
                                        <QRCode
                                            bgColor="transparent"
                                            fgColor="#000000"
                                            level="Q"
                                            style={{ zIndex: '2000', position: 'absolute', 
                                            top: '0px', left: '0px', right:'0px', width: '160px', marginLeft:'auto', marginRight:'auto',
                                            marginTop: 'auto', marginBottom: 'auto', bottom:'0' }}
                                            value={boothFullPath+'/commands||' + orderId}
                                        >
                                        </QRCode>
                                </div>:null
                            }
                            </div>

                        
                    </div> 
                    {mode !== 'client'?
                    <div className='modifyCommand'>
                        <Button onClick={SetOrderPaid}>{traductionMap.orderPaid}</Button>
                    </div>:null
                    }
                    

                    <div className='modifyCommand'>
                        <Button onClick={backButton}>{traductionMap.return}</Button>
                    </div>

                </div>
            }
        </>
    )
}

export default PartialOrderView;
