import React, { useState } from 'react';
import firebase from '../../firestore';
import { useHistory } from "react-router-dom";
import { loadGateway } from "../../tools/payrexx";
import loadingGif from '../../ressources/queuesQipAnimate.gif';
import { traductionMap } from '../../componants/Traduction';
import twintLogo from '../../ressources/twint-logo.svg';
import Button from '../../componants/Button';


function ChoosePm() {
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory();
   

  
    const addCommand = async (choice) => {
        setIsLoading(true)
        var gateway = JSON.parse(localStorage.getItem('gateway'))
        console.log(gateway)
        // generate payrexx gateway
        if(choice === 'twint'){
            loadGateway(gateway.amount, 'CHF', gateway.referenceId, ['twint'], gateway.testMode, gateway.orderPath)
        } else 
            loadGateway(gateway.amount, 'CHF', gateway.referenceId, gateway.pmList, gateway.testMode, gateway.orderPath)
    }

    const backButton = () => {
        const itemPath = localStorage.getItem('itemPath')
        let push = itemPath.substring(0, itemPath.length)
        history.push("/Items/" + push)
    }
    console.log('in')

    return (
        <>
            {isLoading?
                <div style={{ position: 'absolute', top: '30%', textAlign: 'center', fontSize: '170%' }} >
                    <img width='70%' src={loadingGif} className='loadingGif' alt='loadingGif'></img>
                    <div>{traductionMap.loading} ...</div>
                </div>
                :
                <div className='basketView'>
                    <div style={{marginTop: '300px'}}>
                        <div className='modifyCommand'>
                            <img style={{width: '275px', borderRadius:'70px'}} src={twintLogo} onClick={()=>{addCommand('twint')}}></img>
                        </div>
                        <div className='modifyCommand'>
                           {traductionMap.or}
                        </div>
                        <div className='modifyCommand'>
                            <Button onClick={()=>{addCommand('others')}}>{traductionMap.otherPm}</Button>
                        </div>

                        <div className='modifyCommand'>
                            <Button onClick={backButton}>{traductionMap.modifyOrder}</Button>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}

export default ChoosePm;
