const addOrder = async (postData)=>{
  const response = await fetch('https://us-central1-sqipprod.cloudfunctions.net/addOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  console.log(data)
  localStorage.setItem('currentOrder',JSON.stringify(data))
  
  return data;
}
const changeStatus = async (postData)=>{
const response = await fetch('https://us-central1-sqipprod.cloudfunctions.net/checkOrder', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(postData),
});
const data = await response.json();
console.log(data)
localStorage.setItem('currentOrder',JSON.stringify(data))

return data;
}

//get gateway
const getGatewayServer = async (postData)=>{
  const response = await fetch('https://us-central1-sqipprod.cloudfunctions.net/getGateway?' + new URLSearchParams(postData), {
    method: 'GET',
  });
  const data = await response.json();
  
  return data;
  }
// get transaction
const getTransactionServer = async (postData)=>{
  const response = await fetch('https://us-central1-sqipprod.cloudfunctions.net/getTransaction?' + new URLSearchParams(postData), {
    method: 'GET',
  });
  const data = await response.json();
  
  return data;
  }
// refund transaction
const refundTransactionServer = async (postData)=>{
  try{
    const response = await fetch('https://us-central1-sqipprod.cloudfunctions.net/refundTransaction?' + new URLSearchParams(postData), {
      method: 'GET',
    });
    const data = await response.json();
    
    return data;
  }
  catch(e){
    console.log(e)
    return null
  }
  }
// get sumuptransactions
const getSumUpTransactions = async (postData)=>{
  try{
    const response = await fetch('https://us-central1-sqipprod.cloudfunctions.net/getTransactionList', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });
    const data = await response.json();
    
    return data;
  }
  catch(e){
    console.log(e)
    return null
  }
  }
export {addOrder, changeStatus, getGatewayServer, getTransactionServer, refundTransactionServer, getSumUpTransactions}