import React from 'react';
import '../../styles/views/superAdminViews/AdminConsole.css'
import IconAddEvents from '../../ressources/addEvent.png'
import IconEventList from '../../ressources/eventList.png'
import IconUserAdd from '../../ressources/userAdd.png'
import { useHistory } from "react-router-dom";
import { traductionMap } from '../../componants/Traduction';

function AdminConsole() {
    const history = useHistory();
    const addEvent = () => {
        history.push("/AddEvent")
    }
    const displayEventList = () => {
        localStorage.setItem("eventPath", "events/")
        history.push("/EventList")
    }
    const addUser = () => {
        history.push("/AddUser")
    }
    var role = localStorage.getItem('role')

    return (
        <div >
            <div className='bricksContainer'>
                {role === 'SuperAdmin' ?
                    <>

                        <div onClick={addEvent}>
                            <p>{traductionMap.addEvent}</p>
                            <img src={IconAddEvents} alt="Ajouter un événement"></img>
                        </div>
                        <div onClick={displayEventList}>
                            <p>{traductionMap.eventList}</p>
                            <img src={IconEventList} alt="Afficher la liste des événements"></img>
                        </div>
                        <div onClick={addUser}>
                            <p>{traductionMap.addUser}</p>
                            <img src={IconUserAdd} alt="Ajouter un utilisateur"></img>
                        </div>
                    </>
                    : null}
                {role === 'EventAdmin' ?
                    <>
                        <div onClick={displayEventList}>
                            <p>{traductionMap.eventList}</p>
                            <img src={IconEventList} alt="Afficher la liste des événements"></img>
                        </div>
                    </>
                    : null}
            </div>
        </div>
    );
}
export default AdminConsole;
