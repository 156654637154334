import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import firebase from '../../firestore';
import { setDoc, doc } from "firebase/firestore"; 
import { traductionMap } from '../../componants/Traduction';
import { isValidDate } from '../../tools/Tools';
import Button from '../../componants/Button';
import { Download } from '@mui/icons-material';
import { GetItemsByBooth } from '../../repostirories/firebaseFunctions';
import LoadingScreen from '../../componants/LoadingScreen';

var db = firebase.firestore();

function CsvReader(path) {
  const typespath = path.substr( 0, path.indexOf('/items')) + '/types/'
  const collectionPath = path.substr( 0, path.indexOf('/items') - 20)
  const boothId = path.substr(path.indexOf('/items') - 20, 20)
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
 
  // process CSV data
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
 
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
 
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
 
    setData(list);
  }
  //add data in firebase
  const addItemList = itemList =>{
    
    setIsLoading(true)
    if(itemList.length > 0)
      {
      //set the number of the item
      var itemNumber = 0;
      var typeNumber = 0;
      // var for location number list
      var tempLocationList = []
      // highest vat which will be used for deposit
      var depositVat = 0
      var i = 0
      //delete all
      function deleteCollection(){
        db.collection(path).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            db.collection(path).doc(doc.id).delete();
          });
        });
        //delete types
        db.collection(typespath).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.collection(typespath).doc(doc.id).delete();
          });
        });
      }
      deleteCollection();
      // Add a new document with a generated id.
      itemList.forEach(item => {
        i++
        //set as the deposit vat number if it's higher than current vatNumber
        if (item.tva > depositVat)
          depositVat = item.tva
        //increment the number of the item
        itemNumber = itemNumber + 1;
        // Make ItemName an object
        var itemNameMap = {}
        var itemNameList = item.itemName.split(';')
        itemNameMap['default'] = itemNameList[0]
        itemNameList.shift()
        if(itemNameList.length > 0)
        {
          itemNameList.forEach(item => {
            var itemTab = item.split(':')
            itemNameMap[itemTab[0]] = itemTab[1]
          });
        }
        

        //make description an object
        var descriptionMap = {}
        var descriptionList = item.itemDescription.split(';')
        descriptionMap['default'] = descriptionList[0]
        descriptionList.shift()

        if(descriptionList.length > 0)
        {
          descriptionList.forEach(item => {
            var itemTab = item.split(':')
            descriptionMap[itemTab[0]] = itemTab[1]
          });
        }
        

        //make types an obejct
        var typeMap = {}
        var typeList = item.type.split(';')
        
        typeMap['default'] = typeList[0]
        typeList.shift()

        if(typeList.length > 0)
        {
          typeList.forEach(item => {
            var itemTab = item.split(':')
            typeMap[itemTab[0]] = itemTab[1]
          });
        }
        //make types an obejct
        var typesMap = {}
        var typesList = []
        if(item.types){
          typesList = item.types.split(';')
          typesMap['default'] = typesList[0]
          typesList.shift()

          if(typesList.length > 0)
          {
            typesList.forEach(item => {
              var itemTab = item.split(':')
              typesMap[itemTab[0]] = itemTab[1]
            });
          }
        }
        
        

        // Make subItems an object
        var subItems = []
        if(item.subItems !== '' && item.subItems !== undefined)
        {
          var subItemList = item.subItems.split('|')
          subItemList.forEach(subItem => {

            var itemNameMap = {}
            var itemNameList = subItem.split(';')
            itemNameMap['default'] = itemNameList[0]
            itemNameList.shift()
            itemNameList.forEach(item => {
              var itemTab = item.split(':')
              itemNameMap[itemTab[0]] = itemTab[1]
              // set default (it used when the language is no available and in statistics)
              if(itemNameMap['default'] === null){
                itemNameMap['default'] = itemTab[1]
              }
            })
            subItems.push(itemNameMap)
          })
        }

        // Make variableDepositName an object
        var variableDepositMap = {}
        var variableDepositList = item.variableDepositName.split(';')
        variableDepositMap['default'] = variableDepositList[0]
        variableDepositList.shift()
        if(variableDepositList.length > 0)
        {
          variableDepositList.forEach(item => {
            var itemTab = item.split(':')
            variableDepositMap[itemTab[0]] = itemTab[1]
          });
        }
        // check if date is in the good format
        if(!isValidDate(item.date) && item.date){
          alert(traductionMap.errInDateFormatddmmyyyy)
          return;
        }
        var soldOut = false
        if (item.soldOut){
          soldOut = true
        }
        // add items
        if(item.itemName){
          db.collection(path).add({
            itemNumber: itemNumber,
            itemName: itemNameMap,
            description: descriptionMap,
            price: Number(item.itemPrice),
            tva: Number(item.tva),
            type: typeMap,
            attributs: {alcohol: (item.alcohol==='true'), glutenFree: (item.glutenFree==='true'), 
                        vegetarian: (item.vegetarian==='true'), lactoseFree: (item.lactoseFree==='true'),
                        eighteenMin: (item.eighteenMin==='true'), sixteenMin: (item.sixteenMin==='true') },
            subItems: subItems,
            soldOut: soldOut,
            availableInStock: Number(item.availableInStock),
            stockThreshold: Number(item.stockThreshold),
            stockSupplyThreshold: Number(item.stockSupplyThreshold),
            deposit: Number(item.deposit),
            variableDepositPrice: Number(item.variableDepositPrice),
            variableDepositName: variableDepositMap,
            date: item.date,
            isBrought: item.isBrought==='true'
          }).then(() => {
            // add 
            const boothRef = doc(db, collectionPath, boothId)
            setDoc(boothRef, 
            { 
              sectionList: tempLocationList,
              depositVat: Number(depositVat)
            }, { merge: true })
            // add types
            if(typeMap && item.types)
            {
              typeNumber = typeNumber +1
              db.collection(typespath).add({
                number: typeNumber,
                type: typesMap})
                .then(() => {
                  if(i===itemList.length){
                    setIsLoading(false)
                  }
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
            }
            document.getElementById("dataFile").value = null;
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
        }
        // add location list
        if(item.sections !== "" && item.sections !== undefined)
          tempLocationList.push(item.sections)

        
      })
      
      
    }
    
      
  }
 
  // handle file upload
  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  }
  console.log()
  //download the data back to excel file
  const downloadToXlsx = async () =>{
    // get all the Items
    var items = await GetItemsByBooth(localStorage.getItem('userPath'))
    items.forEach((item)=>{
      item.itemName = fromLangMapToString(item.itemName)
      item.itemDescription = fromLangMapToString(item.description)
      item.itemPrice = item.price
      item.tva = item.tva
      item.type = fromLangMapToString(item.type)
      //add categories
      // if(!types.includes(item.type.default)){
      //   types.push(item.type.default)
      //   item.types = item.type
      // }
      item.alcohol = item.attributs.alcohol
      item.glutenFree = item.attributs.glutenFree
      item.vegetarian = item.attributs.vegetarian
      item.lactoseFree = item.attributs.lactoseFree
      item.eighteenMin = item.attributs.eighteenMin
      item.sixteenMin = item.attributs.sixteenMin
      // get subItem String
      var subItemParts = []
      if(item.subItems){
        item.subItems.forEach((subItem)=>{
          subItemParts.push(fromLangMapToString(subItem))
        })
        item.subItems = subItemParts.join('|')
      }

      
      /////////////////////
      item.soldOut = item.soldOut
      item.availableInStock = item.availableInStock
      item.stockThreshold = item.stockThreshold
      item.stockSupplyThreshold = item.stockSupplyThreshold
      item.deposit = item.deposit
      item.variableDepositPrice = item.variableDepositPrice
      item.variableDepositName = fromLangMapToString(item.variableDepositName)
      item.date = item.date
      if(item.isBrought)
        item.isBrought = item.isBrought
      
      
      //Supprimmer les colonnes inutiles: attributs 
      delete item.attributs
      delete item.id
      delete item.description
    })
    items = items.sort((a,b)=>a.itemNumber - b.itemNumber)
    const ws = XLSX.utils.json_to_sheet(items);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'itemList.xlsx');


  }

  //transform fiels with traduction in a string value
  const fromLangMapToString = (itemName) =>{
    var stringName = ''
    var itemNameParts = [];
    itemNameParts.push(itemName.default)
    if (itemName.default)
      for (const [key, value] of Object.entries(itemName)) { 
        if (key !== 'default')
          itemNameParts.push(`${key}: ${value}`);
      }
      stringName = itemNameParts.join(';')
      
    return stringName
  }
 
  return (
  <>
    {isLoading ?
      <LoadingScreen />
      :
    <div>
      <h3>{traductionMap.addProductList}</h3>
      
      <button style={{backgroundColor:"white", borderWidth: 0, marginRight: '30px' }}><Button onClick={()=>downloadToXlsx()}> <Download/></Button></button>
      <input
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload}
        id="dataFile"
      />
      <button style={{backgroundColor:"orange" , fontWeight:"bolder"}} onClick={() => addItemList(data)}>+</button>
    </div>}
    </>
  );
}
 
export default CsvReader;