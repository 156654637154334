import { useState } from "react";
import QrReader from "react-qr-reader";
import firebase from '../../firestore'
import validateIcon from '../../ressources/validate2.png';
import crossIcon from '../../ressources/cross.png'
import DepositCashBack from "./DepositCashBack";
import { traductionMap } from "../../componants/Traduction";
import Button from "../../componants/Button";
import FullRefund from "./FullRefund";

var db = firebase.firestore();
function ConsignHandler() {

  const commandPath = localStorage.getItem('userPath') + 'commands'
  
  const [command, setCommand] = useState(null)
  const [isValid, setValid] = useState("unknown")
  const [displayCashBack, setDisplayCashBack] = useState(false)
  const [displayRefund, setDisplayRefund] = useState(false)
  const [mode, setMode] = useState(null)

  const handleScan = async (data) => {
    if (data) {
      console.log(commandPath)
      console.log(data)
      var docRef = db.collection(commandPath).doc(data);
      await docRef.get().then((doc) => {
        if (doc.exists) {
          let tempCommand = {}
          tempCommand = doc.data();
          tempCommand.id = doc.id
          setCommand(tempCommand)
          if(mode === 'deposit')
            setDisplayCashBack(true)
          else if (mode === 'refund')
            setDisplayRefund(true)
        }
        else
        {
          setValid("invalid");
          setCommand(null)
          setTimeout(function() {
            setValid("unknown");
          }, 5000);
          
        }
            
      })
    }
  }
  const handleError = (err) => {
    console.error(err);
  }
  
  const changeMode = (mode) => {
    setMode(mode)
    if(mode === 'deposit'){
      setDisplayRefund(false)
    }
    else if (mode === 'refund'){
      setDisplayCashBack(false)
    }
  }

  return (
    <div className="App" style={{textAlign:"center", fontSize:"34px", fontWeight:"bolder"}}>
      <Button onClick={()=>changeMode('deposit')}>{traductionMap.refundDep}</Button>
      <Button onClick={()=>changeMode('refund')}>{traductionMap.refundOrder}</Button>
      {displayCashBack?
        <DepositCashBack command={command}></DepositCashBack>
        :
        <>
        {displayRefund?
          <FullRefund command={command}></FullRefund>
        :
          <>
            {isValid === "valid"?
              <>
              <div className="valid">
                <div>
                </div>
              </div>
              <div className="validIcon">
                  <img src={validateIcon} className="App-icon" alt="validate"/>
              </div></>:
              isValid === "invalid"?
              <>
              <div className="invalid">
                <div>
                </div>
              </div>
              <div className="invalidIcon">
                  <img src={crossIcon} className="App-icon" alt="cross"/>
              </div></>:null
            
            }
            {mode !== null?
              <div style={{ marginBottom:"32px"}}>
                <QrReader
                  delay={100}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ innerHeight: "60%", margin: "0% 10% 0% 10%", border: "orange", borderStyle: "double", zIndex:"1"}}
                  />
              </div>:
              null
            }
            {command !== null && command !== "undefined"?
                  <div>{traductionMap.orderValid} {command.number}</div>:
                  <div>{traductionMap.noOrder}</div>

            }
          </>
        }
        </>
      }
    </div>

  );
}

export default ConsignHandler;
