import React, { useEffect, useState } from 'react';
import firebase from '../../firestore';
import 'react-dropdown/style.css';
import { getPosition,  translate } from '../../tools/Tools';
import { traductionMap } from '../../componants/Traduction';
import { getAnyDocById } from '../../repostirories/firebaseFunctions'
var db = firebase.firestore();

function CommandListPerDay() {
  const [commands, setCommands] = useState({})
  
  var paths = {0: localStorage.getItem('userPath')}
  if(localStorage.getItem('userPaths') !== "undefined" && localStorage.getItem('role') !== 'EventAdmin'){
      paths = JSON.parse(localStorage.getItem('userPaths'))
  }
  const [commandsChange, setCommandsChange] = useState(false)


  //Import the authorized commands from firebase
  useEffect(() => {
    let unsubscribe = ()=>{};
    
    
    async function InitCommands(path) {
      //set booth
      const boothPath = path.substring(0, getPosition(path, '/', 3))
      const boothId = path.substring(getPosition(path, '/', 3)+1).slice(0,-1)
      var boothTemp = await getAnyDocById(boothPath, boothId)
        unsubscribe = db.collection(path  + 'commands').onSnapshot(function (querySnapshot) {
          setCommandsChange(!commandsChange)
              
          var commandList = {}

          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            if (doc.exists) {
              //must not take the "commands" document wich is in the same collection
              if (doc.id.length > 10 && doc.data().commandStatus !== 5) {
                // Convert to command object
                var command;
                command = doc.data();
                command.postponedPayment = boothTemp.postponedPayment
                command.boothPath = path
                command.key = doc.id;
                command.boothName = boothTemp.menuName
                command.color = boothTemp.color
                command.orderedItems.forEach((item)=>{
                  // check if list is already inited for this date
                  if (!commandList[item.date])
                    commandList[item.date] = {}
                  //check if company is already inited
                  if (!commandList[item.date][command.userInfos.company.value]){
                    commandList[item.date][command.userInfos.company.value] = {}
                    commandList[item.date][command.userInfos.company.value].itemList = []

                  }
                  console.log(item)
                  if(commandList[item.date][command.userInfos.company.value].itemList.find(i => i.itemName['default'] === item.itemName['default'])){
                    const index = commandList[item.date][command.userInfos.company.value].itemList.findIndex(i => i.itemName['default'] === item.itemName['default'])
                    commandList[item.date][command.userInfos.company.value].itemList[index].quantity = commandList[item.date][command.userInfos.company.value].itemList[index].quantity + item.quantity
                    command.userInfos.quantity = item.quantity
                    command.userInfos.comment = command.comment
                    commandList[item.date][command.userInfos.company.value].itemList[index].clientList.push(command.userInfos)
                    console.log(commandList[item.date][command.userInfos.company.value].itemList[index].clientList)
                    
                  }
                  else {
                    command.userInfos.quantity = item.quantity
                    command.userInfos.comment = command.comment
                    item.clientList = [command.userInfos]
                    commandList[item.date][command.userInfos.company.value].itemList.push(item)
                  }
                  
                })
                
              }
            }
            else {
              console.log("No such document!")
            }
          })
          console.log(commandList)
          const ordered = Object.keys(commandList)
          .sort((a, b) => new Date(b.split('.').reverse().join('-')) - new Date(a.split('.').reverse().join('-')))
          .reduce((obj, key) => {
            obj[key] = commandList[key];
            return obj;
          }, {});
          setCommands(ordered)
        })
    }
    InitCommands(paths[0])
    return () => { unsubscribe() };
    // eslint-disable-next-line
  }, []);


  return (
    <div className="commandList">

      {commands === null ? null : Object.keys(commands).map(key => 
        Object.keys(commands[key]).map(key2 =>
        <div className='command' key={key+key2} >
          <div className="commandNumberContent">
            <div>{traductionMap.date} {key}</div>
            <div>{traductionMap.location} {key2}</div>
          </div>
          {commands[key][key2].itemList.map((item) =>
            <>
              <div key={item.key} className="commandContent1" >
                <div className='itemFirstLine'>
                  <div className='itemQuantity'> {item.quantity}X</div>
                  <div className='itemNameContent'>
                    <div className='itemName'> {translate(item.itemName).substring(0, 25)} </div>
                  </div>
                  <div className='itemPrice'> {(item.price * item.quantity).toFixed(2)} CHF</div>
                </div>
                 <div style={{paddingTop: '-20px'}}className='listSubItemContent'>
                  { item.clientList.map((client)=>(
                      <div >
                          <p>{client.quantity}X {client.name}</p>
                          <p>{client.phoneNumber}</p>
                          <p>{client.comment}</p>
                      </div>
                      ))
                  } 
                </div>
                <div style={{paddingTop: '-20px'}}className='listSubItemContent'>
                  { item.variableDepositQty >0 ?
                      <div >
                          { item.variableDepositQty+ ' ' + translate(item.variableDepositName)}
                      </div>
                      :null
                  } 
                </div>
              </div>
              
            </>
          )}

        </div>
      )
      )}
    </div>

  )
}

export default CommandListPerDay;
