
import React, { useState } from 'react'
import { auth } from '../../firestore'
import { resetPassword } from '../../repostirories/firebaseFunctions';
import Button from '../../componants/Button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { traductionMap } from '../../componants/Traduction';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authentificationFailed, setAuthentificationFailed] = useState('false');
  const [passwordType, setPasswordType] = useState("password");
  const signInWithEmailFunction = () => {

    //Built in firebase function responsible for authentication
    auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        console.log('You\'re successfully signed in ! ');
        setAuthentificationFailed(false);
        //mode admin
        localStorage.setItem('mode', 'admin')
        //reinitialize the role and path stored in cache
        localStorage.setItem('userPath', "")
        localStorage.setItem('role', "")
      })
      .catch(error => {
        console.error(error);
        setAuthentificationFailed(false);
        setAuthentificationFailed(true);
      });
  }
  //singn in on enter pushed
  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.code === 'Enter') {
      signInWithEmailFunction();
    }
  };

  const togglePassword = () => {
    passwordType === "password" ? setPasswordType("text") : setPasswordType("password");
  }

  return (
    <div className="login" onKeyPress={handleKeypress}>
      <div className="loginContent">
        <h1>{traductionMap.login}</h1>
        <input type='text' placeholder='Entrer son email' value={email} onChange={e => setEmail(e.currentTarget.value)} />
        <p></p>
        <div className="pwd">
          <input type={passwordType} placeholder='Entrer son mot de passe' value={password} onChange={e => setPassword(e.currentTarget.value)} />
          <i onClick={togglePassword}>
            {passwordType === "password" ?
              <Visibility /> : <VisibilityOff />}
          </i>
        </div>
        <p></p>
        {authentificationFailed === true ?
          <p style={{ color: "red" }}>{traductionMap.falseLoginOrPass}</p> : null
        }
        <p className='resetPwd' onClick={() => resetPassword(email)}>{traductionMap.resetPassword}</p>
        <Button onClick={signInWithEmailFunction}>{traductionMap.signIn}</Button>
      </div>

    </div>
  );
}
export default Login;

