import { React, useState, useEffect } from 'react';
import { addUser, getAllEvents, getBoothsByEvent } from "../../repostirories/firebaseFunctions"
import { roleList } from '../../tools/configVariables';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { traductionMap } from '../../componants/Traduction';
import { translate } from '../../tools/Tools';
import Button from '../../componants/Button';
import { useForceUpdate } from '../../tools/utils';

function AddUser() {
    const forceUpdate = useForceUpdate()
    const index = roleList.indexOf(localStorage.getItem('role'))
    const history = useHistory()
    var filteredRoles = []
    const [eventList, setEventList] = useState([])
    const [eventNameList, setEventNameList] = useState([])
    const [boothList, setBoothList] = useState([])
    const [boothNameList, setBoothNameList] = useState([])
    const [path, setPath] = useState('')
    const [paths, setPaths] =useState([])
    const [userRole, setUserRole] = useState('EventAdmin')
    const [eventId, setEventId] = useState('0')
    const [boothNumber, setBoothNumber] = useState([0])
    const [eventNumber, setEventNumber] = useState([0])

    roleList.map((item, i) => {
        if (i > index)
            filteredRoles.push({ value: item, label: item })
        return filteredRoles
    })
    useEffect(() => {

        var events = []
        var eventNames = []
        async function setEvents() {
            events = await getAllEvents();
            setEventList(events)

            events.forEach(item => {
                eventNames.push({ value: item.id, label: translate(item.eventName) })
            });

            setEventNameList(eventNames)
        }
        setEvents()
        // eslint-disable-next-line
    }, [])


    const changeEvent = async (o, number) => {
        //find the id of the event by name
        const i = eventNameList.indexOf(o)
        const id = eventList[i].id
        var boothNames = []
        var booths = await getBoothsByEvent(id)
        setBoothList(booths)

        booths.forEach(item => {
            boothNames.push({ value: item.id, label: translate(item.menuName) })
        });
        setBoothNameList(boothNames)
        
        //set selcted eventId
        setEventId(id)
        //set the path if the user is eventAdmin
        if (userRole === 'EventAdmin'){
            var tempPaths = paths
            
            //set the path that the user will have acces to 
            tempPaths[number] = 'events/' + id + '/'
            
            console.log(tempPaths)
            setPaths(tempPaths)
            if(number === 0)
                setPath('events/' + id + '/')
        }
    }
    const changeBooth = (o, number) => {
        //find the id of the booth by name
        const i = boothNameList.indexOf(o)
        const bootId = boothList[i].id
        var tempPaths = paths
        //set the path that the user will have acces to 
        tempPaths[number] = 'events/' + eventId + '/menus/' + bootId + '/'
        setPaths(tempPaths)
        if(number === 0)
            setPath('events/' + eventId + '/menus/' + bootId + '/')
    }
    const changeRole = (o) => {
        setUserRole(o.value)
    }
    const addUserDb = () => {
        console.log(paths)
        var input = document.getElementById('eMail')
        var email = ''
        console.log(path)
        if (input && path && userRole) {
            email = input.value
            addUser(email, email, userRole, path, paths)
        }
        else
            alert('Veuillez renseigner tous les champs')
    }
    const backButton = () => {
        history.push('/AdminConsole')
    }
    const addBooth = () =>{
        var booths = boothNumber
        var number = booths[booths.length-1]+1
        booths.push(number)
        setBoothNumber(booths)
        forceUpdate()
        

    }

    const addEvent = () =>{
        var events = eventNumber
        var number = events[events.length-1]+1
        events.push(number)
        setEventNumber(events)
        forceUpdate()
    }
    return (
        <div className={"LoginView"}>
            <div className="basketFooter">
                <p className="backBasket" onClick={backButton}>{traductionMap.return}</p>
            </div>
            <br />
            <div className={'LoginView'}>
                <div>
                    <Select
                        id={'role'}
                        defaultInputValue={userRole}
                        options={filteredRoles}
                        onChange={o => { changeRole(o) }}
                    ></Select>
                </div>
                <br />
                <div>
                    {eventNumber.map((item) =>
                        <Select
                            id={'event'}
                            placeholder={"événement"}
                            options={eventNameList}
                            onChange={o => { changeEvent(o,item) }}
                        ></Select>
                    )}
                    {userRole === 'EventAdmin'?
                        <button onClick={() => addEvent()} >{traductionMap.addEvent}</button>
                        :null
                    }       
                </div>
                <br />
                {userRole !== 'EventAdmin' ?
                    <div>
                        {boothNumber.map((item) =>
                            <Select
                                id={'booth'}
                                placeholder={"stand"}
                                options={boothNameList}
                                onChange={o => { changeBooth(o, item) }}
                            ></Select>
                        )}
                        <button onClick={() => addBooth()} >{traductionMap.addBooth}</button>
                    </div>
                    
                    : null}
                <p />
                <div>
                    <input placeholder='eMail' id={"eMail"}></input>
                </div>
                <div>({traductionMap.defaultPassword})</div>
                <div>

                </div>
            </div>

            <p />
            <Button onClick={() => addUserDb()} >{traductionMap.addUser}</Button>
        </div>

    );

}
export default AddUser;
