import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../firestore';
import { currentPathContext } from '../../context/CurrentPathContext';
import { useHistory } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import LoadingScreen from '../../componants/LoadingScreen';
import ListItem from '../../componants/ListItem';
import SearchBar from '../../componants/SearchBar';
import { traductionMap } from '../../componants/Traduction';
import { translate } from '../../tools/Tools';

var db = firebase.firestore();

function Event() {
  const history = useHistory();
  const [items, setItems] = useState(null);
  const { eventPath, setMenuPath } = useContext(currentPathContext);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  //Import the selected menu from firebase
  useEffect(() => {
    //setCurrentPath(menuReference+"/items"); console.log("Nestor log :" + currentPath);
    async function InitMenu() {
      setIsLoading(true)
      var itemList = [];
      await getDocs(collection(db, eventPath)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists && doc.data()["open"] && !doc.data().invisible) {
            // Convert to item object
            var item;
            item = doc.data();
            item.id = doc.id;
            console.log(item)

            itemList.push(item);
            // Use a item instance method
            //console.log(item.id, item.itemName[language], item.price);
            //console.log("This " + item.toString());

          } else {
            console.log("No such document!")
          }
        })
        if (itemList.length !== 0) {
          setItems(itemList);
        }
      });
    }

    InitMenu().then(() => {
      setIsLoading(false)
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ?
        <LoadingScreen />
        :
        <div className="event">
          <div className="eventContent">
            <h1>{traductionMap.makeOrder}</h1>
            <SearchBar placeholder={'Lieu, festival...'} onChange={e => setSearchQuery(e.target.value)} />
            {items === null ? null : items
              .filter((item) => { //filter for the search
                if (searchQuery === '' || translate(item.eventName).toLowerCase().includes(searchQuery.toLowerCase())) {
                  return item;
                } else {
                  return null;
                }
              })
              .map((item) =>
                <ListItem
                  key={item.id}
                  onClick={() => {
                    setMenuPath(eventPath + item.id + "/menus");
                    history.push("/Menus")
                  }}>
                  {translate(item.eventName)}
                </ListItem>
              )}
          </div>
        </div>
      }
    </>
  )
}

export default Event;
