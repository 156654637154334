
import Button from '../../componants/Button'
import LoadingScreen from '../../componants/LoadingScreen'
import { traductionMap } from '../../componants/Traduction'
import { useState } from 'react'
import { UpdateDepositAmount } from '../../repostirories/firebaseFunctions'
import { refundTransaction } from '../../tools/payrexx'

function DepositCashBack(props) {
  var cashbackAmount
  const command = props.command
  const commandPath = localStorage.getItem('userPath') + 'commands'
  // const boothPath = localStorage.getItem('userPath') + 'items'
  const [isLoading, setIsLoading] = useState(false);
  
  console.log(command)
  
  const cashBack = async (amount) =>{
    var givenBackMoney = 0
    if(command.givenBackMoney)
      givenBackMoney = command.givenBackMoney

    setIsLoading(true)
    if(!amount)
      amount = document.getElementById('depositCashBack').defaultValue
    
      givenBackMoney = Number(givenBackMoney) + Number(amount)
    try{
      refundTransaction(command.transactionId, amount*100).then(()=>{
        UpdateDepositAmount(command.id, givenBackMoney, commandPath, false).then(()=>{
          setIsLoading(false)
          alert(traductionMap.amountReturned)
          window.location.reload()
        })

      })
      
    }
    catch(e){
      console.log(e)
    }


  }


  return (
    <div className="App" style={{textAlign:"center", fontSize:"30px", fontWeight:"bolder", height:"100%", position:'absolute'}}>
      {isLoading ?
        <LoadingScreen></LoadingScreen>
      :
      <>
        <br></br>

        <span>Montant à rendre  </span>
        <input id='depositCashBack' style={{fontSize: '30px', width: '80px', margin: '20px'}} defaultValue={command.totalDeposit} onChange = {e => cashbackAmount = e.currentTarget.value}></input>
        CHF
        <Button onClick={()=>cashBack(cashbackAmount)}>Rendre le montant</Button>
      </>}

      
   
    </div>

  );
}

export default DepositCashBack;
