import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../firestore';
import { languageContext } from '../../context/LanguageContext';
import { absoluteLanguageContext } from '../../context/LanguageContext';
import { currentPathContext } from '../../context/CurrentPathContext';
import { useHistory } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import LoadingScreen from '../../componants/LoadingScreen';
import ListItem from '../../componants/ListItem';
import SearchBar from '../../componants/SearchBar';
import { traductionMap } from '../../componants/Traduction';
import { translate } from '../../tools/Tools';
import { roleList } from '../../tools/configVariables';

var db = firebase.firestore();

function Menu() {
  const history = useHistory();
  const [items, setItems] = useState(null);
  const { menuPath, setItemPath } = useContext(currentPathContext);
  const { language } = useContext(languageContext);
  const { absoluteLanguage } = useContext(absoluteLanguageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  //set menu path in local storage
  localStorage.setItem("menuPath", menuPath);
  console.log(menuPath)
  //Import the selected menu from firebase
  useEffect(() => {
    //setCurrentPath(menuReference+"/items"); console.log("Nestor log :" + currentPath);
    async function InitMenu() {
      setIsLoading(true)
      var itemList = [];
      await getDocs(collection(db, menuPath)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.data())
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists && doc.data()["open"] && !doc.data().invisible) {
            console.log(doc.data())
            // Convert to item object
            var item;
            item = doc.data();
            item.id = doc.id;

            itemList.push(item);
            // Use a item instance method
            //console.log(item.id, item.itemName[language], item.price);
            //console.log("This " + item.toString());

          } else {
          }
        })
        if (itemList.length !== 0) {
          setItems(itemList);
        }
      });
    }
    InitMenu().then(() => {
      setIsLoading(false)
    })
    // eslint-disable-next-line
  }, [language, absoluteLanguage]);

  return (
    <>
      {isLoading ?
        <LoadingScreen />
        :
        <div className="event">
          <div className="eventContent">
            <h1>{traductionMap.booths}</h1>
            <SearchBar placeholder={'Bar, stand...'} onChange={e => setSearchQuery(e.target.value)} />
            {items === null ?
              <p className="excuseMessage">{traductionMap.noStore}</p>
              :
              items
                .filter((item) => { //filter for the search
                  if (searchQuery === '' || translate(item.menuName) .toLowerCase().includes(searchQuery.toLowerCase())) {
                    return item;
                  } else {
                    return null;
                  }
                })
                .map((item) =>
                <>
                {roleList.includes(localStorage.getItem('role')) || !item.postponedPayment?
                  <ListItem
                    key={item.id}
                    onClick={() => {
                      setItemPath(menuPath + "/" + item.id + "/items");
                      history.push("/Items" + menuPath + "/" + item.id + "/items")
                    }}>
                    {translate(item.menuName) }
                  </ListItem>
                  :null
                }
                </>
                )}
          </div>

        </div>
      }
    </>
  )
}

export default Menu;
