import React, { useEffect, useState } from 'react';
import firebase from '../../firestore';
import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';
import RigthArrow from '../../ressources/rigthArrow.png'
import NotifSound from '../../ressources/notifSound.wav'
import { getPosition, sendSMS, translate } from '../../tools/Tools';
import { traductionMap } from '../../componants/Traduction';
import { doc, updateDoc} from "firebase/firestore";
import { getAnyDocById } from '../../repostirories/firebaseFunctions'
import CommandListPerDay from './CommandListPerDay';
import { useForceUpdate } from '../../tools/utils';
import SortIcon from '@mui/icons-material/Sort'
var db = firebase.firestore();

function CommandList() {
  const [commands, setCommands] = useState({});
  const [filteredCommands, setFilteredCommands] = useState(null);
  const [alphaOrder, setAlphaOrder] = useState(true)
  const forceUpdate = useForceUpdate()
  
  const options = [
    { value: 1, label: '1. En préparation' },
    { value: 2, label: '2. Prêt' },
    { value: 3, label: '3. Délivré' },
    { value: 4, label: '4. Annulé' },
  ];
  const [checkBoxes, setCheckBoxes] = useState(true);
  const [optionsDictionary, setOptionsDictionary] = useState([
    '0. En cours', '1. En Préparation', '2. Prêt', '3. Délivré', '4. Annulé', '5. Archivé'
  ])
  var paths = {0: localStorage.getItem('userPath')}
  if(localStorage.getItem('userPaths') !== "undefined" && localStorage.getItem('role') !== 'EventAdmin'){
      paths = JSON.parse(localStorage.getItem('userPaths'))
  }
  var notifSound = new Audio(NotifSound);
  const [isDefault, setIsDefault] = useState(true);
  const [commandsChange, setCommandsChange] = useState(false)
  const [booth, setBooth] = useState({})

  

  //Import the authorized commands from firebase
  useEffect(() => {
    let previousCommandList = [];
    let unsubscribe = ()=>{};
    
    
    async function InitCommands(path) {
      //set booth
      const boothPath = path.substring(0, getPosition(path, '/', 3))
      const boothId = path.substring(getPosition(path, '/', 3)+1).slice(0,-1)
      var boothTemp = await getAnyDocById(boothPath, boothId)
      setBooth(boothTemp)
      if(!boothTemp.postponedPayment)
      {
        unsubscribe = db.collection(path  + 'commands').onSnapshot(function (querySnapshot) {
          setCommandsChange(!commandsChange)
          var commandList = [];
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            if (doc.exists) {
              //must not take the "commands" document wich is in the same collection
              if (doc.id.length > 10) {
                // Convert to command object
                var command;
                command = doc.data();
                command.postponedPayment = boothTemp.postponedPayment
                command.boothPath = boothPath
                command.boothId = boothId
                command.fullPath = path
                command.key = doc.id;
                command.boothName = boothTemp.menuName
                command.color = boothTemp.color
                commandList.push(command);
                if(command.number === 59){
                  console.log(command)
                }
                
              }
            }
            else {
              console.log("No such document!")
            }
          })
          
          if (commandList.length !== 0) {
            //sort the list by date hour
            commandList.sort((a, b) => {
              return new Date(a.dateHour + 'Z') - new Date(b.dateHour + 'Z')
            })
            commands[path] = commandList
            var newCommands = commands
            setCommands({})
            setCommands(newCommands)
            setCommandsChange(!commandsChange)

          }
          // play the notification if an item is added
          if (previousCommandList !== null) {
            if (commandList.length > previousCommandList.length) {
              previousCommandList = commandList
              notifSound.play();
            }
          }
        });
      }
      else
      {
        unsubscribe = db.collection(path  + 'partialOrders').onSnapshot(function (querySnapshot) {
          
              
          console.log('doc.data()2') 
          setCommandsChange(!commandsChange)
          var commandList = [];
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            if (doc.exists) {
              //must not take the "commands" document wich is in the same collection
              if (doc.id.length > 10) {
                // Convert to command object
                var command;
                command = doc.data();
                command.postponedPayment = boothTemp.postponedPayment
                command.boothPath = boothPath
                command.boothId = boothId
                command.fullPath = path
                command.key = doc.id
                command.boothName = boothTemp.menuName
                command.color = boothTemp.color
                commandList.push(command)
              }
            }
            else {
              console.log("No such document!")
            }
          })
          if (commandList.length !== 0) {
            //sort the list by date hour
            commandList.sort((a, b) => {
              return new Date(a.dateHour + 'Z') - new Date(b.dateHour + 'Z')
            })
            commands[path] = commandList
            var newCommands = commands
            setCommands({})
            setCommands(newCommands)
            setCommandsChange(!commandsChange)

          }
          // play the notification if an item is added
          if (previousCommandList !== null) {
            if (commandList.length > previousCommandList.length) {
              previousCommandList = commandList
              notifSound.play();
            }
          }
        });
      }
    }
    if(paths){
      Object.keys(paths).forEach(key =>{
        InitCommands(paths[key]).then(()=>{
          
        })
      })
    }
    return () => { unsubscribe() };
    // eslint-disable-next-line
  }, []);

  // automatically check status 1 once commands are inited
  setTimeout(()=>{
    console.log(booth.bringToCompany)
    if (isDefault && !booth.bringToCompany) {
      try{
      document.getElementById("status1").checked = true;
      setIsDefault(false);
      }
      catch(e){
        console.log(e)
      }
    }
  }, 1000)

  useEffect(() => {
    if (window.screen.width < 860){
      setOptionsDictionary([
        '0', '1', '2', '3', '4', '5'
      ])
    }
    function filterCommands() {
      var megedCommands =[]
      Object.keys(commands).forEach(key=>{
        commands[key].forEach(order=>{
          megedCommands.push(order)
        })
      })
      if (megedCommands.length > 0 && !booth.bringToCompany) {
        var commandsList = [];
        megedCommands.forEach((command) => {
          if (document.getElementById("status1").checked === true) {
            if (command.commandStatus === 1)
              commandsList.push(command);
          }
          if (document.getElementById("status2").checked === true) {
            if (command.commandStatus === 2)
              commandsList.push(command);
          }
          if (document.getElementById("status3").checked === true) {
            if (command.commandStatus === 3)
              commandsList.push(command);
          }
          if (document.getElementById("status4").checked === true) {
            if (command.commandStatus === 4)
              commandsList.push(command);
          }
          //sort the list by date hour
          commandsList.sort((a, b) => {
            if(alphaOrder)
              return new Date(a.dateHour + 'Z') - new Date(b.dateHour + 'Z')
            else
              return new Date(b.dateHour + 'Z') - new Date(a.dateHour + 'Z')
          })
          setFilteredCommands(commandsList);
        })
      }
    }
    filterCommands();
    // eslint-disable-next-line
  }, [commands, checkBoxes, isDefault, commandsChange])
  const changeStatus = async (command, status) => {
    
    var path = command.fullPath
    
    if (command.postponedPayment){
      path = path + 'partialOrders'
    }
    else{
      path = path + 'commands'
    }
    db.collection(path).doc(command.key).set({
      commandStatus: status.value,
    }, { merge: true }).then(()=>{
      
      setCommandsChange(!commandsChange)
    })

    if (status.value === 2) {
      let message = "Votre commande num. " + command.number + " est servie : \n\n"
      + command.link + '  \n' + translate(command.SMSMessage) +'\n - sQip'
      sendSMS(command.phoneNumber, message)
      // send in app notification
      console.log(command.key)
      const docRef = doc(db, "Notifications", command.key);
      updateDoc(docRef, {
        commandStatus: 2,
        message: message
      });
    }
  }

  // const changeStatusPayed = (command) => {
  //   var path = command.fullPath
    
  //   if (command.postponedPayment){
  //     path = path + 'partialOrders'
  //   }
  //   else{
  //     path = path + 'commands'
  //   }
  //   if (command.paymentStatus) {
  //     db.collection(path).doc(command.key).set({
  //       paymentStatus: false
  //     }, { merge: true });
  //   } else {
  //     db.collection(path).doc(command.key).set({
  //       paymentStatus: true,
  //     }, { merge: true });
  //     //pass to status 3 if the status is 2
  //     // if (command.commandStatus === 2) {
  //     //   db.collection(command.fullPath).doc(command.key).set({
  //     //     commandStatus: 3,
  //     //   }, { merge: true })
  //     // }
  //   }
  // }
  const switchStatus = async (command) => {
    //deduct waiting time 
    console.log(command)
    
    var commandStatus = command.commandStatus + 1 // status = 2
    var path = command.fullPath
    
    if (command.postponedPayment){
      commandStatus = commandStatus +1 //status = 3
      path = path + 'partialOrders'
    }
    else{
      path = path + 'commands'
    }
    db.collection(path).doc(command.key).set({
      commandStatus: commandStatus,
    }, { merge: true }).then(()=>{
      setCommandsChange(!commandsChange)
    }).catch(e=>{
      console.log(e)
    })

    if (command.commandStatus === 1 && !command.postponedPayment) {
      let message = "Votre commande num. " + command.number + " est servie : \n\n"
        + command.link + '  \n' + translate(command.SMSMessage) +'\n - sQip'
      sendSMS(command.phoneNumber, message)

      // send in app notification
      const docRef = doc(db, "Notifications", command.key);
      updateDoc(docRef, {
        commandStatus: commandStatus,
        message: message
      });
    }

  }
  const sortCommands = () =>{
    var list =[]
    if(alphaOrder){
      list = filteredCommands.sort((a, b) => b.dateHour.localeCompare(a.dateHour))
    }
    else 
      list = filteredCommands.sort((a, b) => a.dateHour.localeCompare(b.dateHour))

    setAlphaOrder(!alphaOrder)
    setFilteredCommands(list)
    forceUpdate()
  }
  return (
    <>
      {booth.bringToCompany?
        <CommandListPerDay/>:
      <div className="commandList">
        <div className="checkboxesContent">
          <span>{traductionMap.showOrders}</span> 
          <div>
            <p>{optionsDictionary[1]}</p>
            <div>
              <input type="checkbox" id="status1" onChange={setCheckBoxes}></input>
            </div>
          </div>
          <div>
          <p>{optionsDictionary[2]}</p>
          <div>
            <input type="checkbox" id="status2" onChange={setCheckBoxes}></input>
          </div>
          </div>
          <div>
          <p>{optionsDictionary[3]}</p>
          <div>
            <input type="checkbox" id="status3" onChange={setCheckBoxes}></input>
          </div>
          </div>
          <div>
          <p>{optionsDictionary[4]}</p>
          <div>
            <input type="checkbox" id="status4" onChange={setCheckBoxes}></input>
          </div>
          </div>
          
        </div>
        <SortIcon onClick={()=>{sortCommands()}}/>
        {filteredCommands === null ? null : filteredCommands.map((command) =>
          <div className='command' key={command.key} style={{backgroundColor: command.color}}>
            <div className="commandNumberContent">
              <div > {translate(command.boothName)} </div>
              {!command.postponedPayment?
              <div > {traductionMap.orderNumber}  {' ' + command.number} </div>
              :null
              }
              {!command.section? null :
                <div>{traductionMap.location} {command.section}</div>
              }
            </div>
            <div style={{marginLeft: '20px'}}> {command.dateHour} </div>
            {command.orderedItems.map((item) =>
              <>
                <div key={item.key} className="commandContent1" >
                  <div className='itemFirstLine'>
                    <div className='itemQuantity'> {item.quantity}X</div>
                    <div className='itemNameContent'>
                      <div className='itemName'> {translate(item.itemName).substring(0, 25)} </div>
                    </div>
                    <div className='itemPrice'> {(item.price * item.quantity).toFixed(2)} CHF</div>
                  </div>
                  <div style={{paddingTop: '-20px'}}className='listSubItemContent'>
                    { item.selectedSubItems !== undefined ?
                        item.selectedSubItems.map((subItem)=>(
                        <div >
                            {translate(subItem)}
                        </div>
                        )):null
                    } 
                  </div>
                  <div style={{paddingTop: '-20px'}}className='listSubItemContent'>
                    { item.variableDepositQty >0 ?
                        <div >
                            { item.variableDepositQty+ ' ' + translate(item.variableDepositName)}
                        </div>
                        :null
                    } 
                  </div>
                </div>
                
              </>
            )}
          {!command.comment || command.comment === 'null'? null :
              <div>{command.comment}</div>
          }
            {command.totalDeposit > 0?
              <div className="commandContent2">
                <div style={{fontWeight: 'normal'}}>{traductionMap.totalDeposits} : {command.totalDeposit.toFixed(2)} CHF</div>
              </div>:null
            } 
            <div className="commandContent2">
              <div >{traductionMap.total} {command.totalPrice.toFixed(2)} CHF</div>
              
            </div>
            <div className="commandContent2">
              {command.paymentStatus === false ?
                <>
                  {/* <p className="cmdePayedCommandList" onClick={() => changeStatusPayed(command)}>{traductionMap.orderPaid}</p> */}
                  <div style={{ color: 'red', fontWeight: "bolder", width: "30%" }}>{traductionMap.toBePaid}</div>
                </>
                :
                null
              } 
            </div>

            <div className="commandContent3">
              <div className='statusContent'>
                <span>{traductionMap.status} </span>
                <Dropdown
                  className='dropdownList'
                  id={command.key}
                  placeholder={optionsDictionary[command.commandStatus]}
                  options={options}
                  onChange={o => { changeStatus(command, o) }}
                ></Dropdown>
              </div>
              {command.commandStatus < 3 ?
                <>
                  <div className="arrowImg" >
                    <img src={RigthArrow} alt='Statut suivant' onClick={() => switchStatus(command)}></img>
                    {command.commandStatus === 1 ? 
                      command.postponedPayment?
                      <span onClick={() => switchStatus(command)}>{traductionMap.delivered}</span> :
                      <span onClick={() => switchStatus(command)}>{traductionMap.ready}</span> : <></>}
                    {command.commandStatus === 2 ? <span onClick={() => switchStatus(command)}>{traductionMap.delivered}</span> : <></>}
                  </div>
                </>
                :
                null
              }
            </div>

          </div>
        )}
      </div>
      }
    </>

  )
}

export default CommandList;
