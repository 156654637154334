import React, { useState } from 'react';
import { BarChart, Bar, Tooltip, XAxis, YAxis, LineChart, Line,  ResponsiveContainer, PieChart, Pie, LabelList, } from 'recharts';
import CustomizedAxisTick from './CustomizedAxisTick';
import {BarChart as BarChartIcon, PieChart as PieChartIcon, ShowChart} from '@mui/icons-material'
import { translate } from '../tools/Tools';
import { traductionMap } from './Traduction';
import Dropdown from 'react-dropdown';


function Chart (props){
  var data 
  var orders = props.orders
  const dateValue = 'date'
  const productValue = 'product'
  const quantityValue = 'quantity'
  const incomeValue = 'income'
  const typeValue = 'type'
  
  const [chartType, setChartType] = useState(props.chartType)
  var tempItemMap={}
  const [xAxisData, setXAxisData] = useState(productValue)
  const [yAxisData, setYAxisData] = useState(incomeValue)
  const xOptions = [
    {
      label: traductionMap.date,
      value: dateValue
    },
    {
      label: traductionMap.product,
      value: productValue
    },
    {
      label: traductionMap.category,
      value: typeValue
    },
    
  ]
  const yOptions = [
    {
      label: traductionMap.income,
      value: incomeValue
    },
    {
      label: traductionMap.quantity,
      value: quantityValue
    },
  ]

  
  var currentQuantity = 0
  var currentPrice = 0
  //init deposit 
  tempItemMap['deposit'] = {}
  tempItemMap['deposit']['name'] = traductionMap.deposits
  tempItemMap['deposit'][traductionMap.income] = 0
  
  if(orders){
    orders.forEach(order => {
      var orderDate = new Date(order.dateHour)
      orderDate.setHours(0)
      orderDate.setMinutes(0)
      order.orderedItems.forEach(item => {
        var orderDate = new Date(order.dateHour)
        orderDate.setHours(0)
        orderDate.setMinutes(0)
        
        
    
        // xAxisCase
        switch (xAxisData){
          case productValue:
            if(!tempItemMap[item.itemName.default] ){
              tempItemMap[item.itemName.default] = {}
            }
            tempItemMap[item.itemName.default]['name'] = translate(item.itemName)
            // y Axis case
            switch(yAxisData){
              case incomeValue:
                  if(!tempItemMap[item.itemName.default][traductionMap.income]){
                    tempItemMap[item.itemName.default][traductionMap.income] = 0
                  }
                  currentPrice = tempItemMap[item.itemName.default][traductionMap.income] 
                  tempItemMap[item.itemName.default][traductionMap.income] = currentPrice + ((item.price)*item.quantity)
                
                  if(item.deposit){
                    tempItemMap['deposit'][traductionMap.income] = tempItemMap['deposit'][traductionMap.income] + item.deposit * item.quantity
                      
                  }
                  break;
              case quantityValue:
                delete tempItemMap['deposit']
                if(!tempItemMap[item.itemName.default][traductionMap.quantity])
                    tempItemMap[item.itemName.default][traductionMap.quantity] = 0
                currentQuantity = tempItemMap[item.itemName.default][traductionMap.quantity] 
                tempItemMap[item.itemName.default][traductionMap.quantity] = currentQuantity + item.quantity
                break;
              default: tempItemMap[item.itemName.default][traductionMap.quantity] = 0
            }
            break;
          
          case typeValue:
            if(!tempItemMap[item.type.default] ){
              tempItemMap[item.type.default] = {}
            }
            tempItemMap[item.type.default]['name'] = translate(item.type)
            // y Axis case
            switch(yAxisData){
              case incomeValue:
                  if(!tempItemMap[item.type.default][traductionMap.income]){
                    tempItemMap[item.type.default][traductionMap.income] = 0
                  }
                  currentPrice = tempItemMap[item.type.default][traductionMap.income] 
                  tempItemMap[item.type.default][traductionMap.income] = currentPrice + ((item.price)*item.quantity)
                
                  if(item.deposit){
                    tempItemMap['deposit'][traductionMap.income] = tempItemMap['deposit'][traductionMap.income] + item.deposit * item.quantity
                  }
                  break;
              case quantityValue:
                delete tempItemMap['deposit']
                if(!tempItemMap[item.type.default][traductionMap.quantity])
                    tempItemMap[item.type.default][traductionMap.quantity] = 0
                currentQuantity = tempItemMap[item.type.default][traductionMap.quantity] 
                tempItemMap[item.type.default][traductionMap.quantity] = currentQuantity + item.quantity
                break;
              default: tempItemMap[item.type.default][traductionMap.quantity] = 0
            }
            break;
            
          case dateValue:
            if(!tempItemMap[orderDate]){
              tempItemMap[orderDate] = {}
            }
            delete tempItemMap.deposit
            tempItemMap[orderDate]['name'] = new Date(orderDate).getTime()
            // y Axis case
            switch(yAxisData){
              case incomeValue:
                  if(!tempItemMap[orderDate][traductionMap.income]){
                      tempItemMap[orderDate][traductionMap.income] = 0
                  }
                  currentPrice = tempItemMap[orderDate][traductionMap.income] 
                  tempItemMap[orderDate][traductionMap.income] = currentPrice + ((item.price)*item.quantity) + item.deposit * item.quantity
                
                  break;
              case quantityValue:
                  if(!tempItemMap[orderDate][traductionMap.quantity])
                      tempItemMap[orderDate][traductionMap.quantity] = 0
                  currentQuantity = tempItemMap[orderDate][traductionMap.quantity] 
                  tempItemMap[orderDate][traductionMap.quantity] = currentQuantity + item.quantity
                  break;
              default: tempItemMap[orderDate][traductionMap.quantity] = 0
            }
            break;
          default: 
            tempItemMap[orderDate]['name'] = 'name'
        }
        // decrease given back money from deposit
        if(tempItemMap['deposit']){
          if(order.givenBackMoney > 0 && tempItemMap['deposit'][traductionMap.income] > 0)
          tempItemMap['deposit'][traductionMap.income] = tempItemMap['deposit'][traductionMap.income] - order.givenBackMoney
        }
        
      })
    })
    
  }
  if(tempItemMap['deposit']){
    if(tempItemMap['deposit'][traductionMap.income] === 0){
      delete tempItemMap.deposit
    }
  }
  var tempItemAccumulation = []
  // put graph material into arrays
  Object.keys(tempItemMap).forEach(key => {
      tempItemAccumulation.push(tempItemMap[key])
  });
  if(incomeValue){
    tempItemAccumulation.forEach(function(item) {
      if(item.Revenu)
        item.Revenu = parseFloat(item.Revenu.toFixed(2));
    });
  }
  //sort x axis by name
  tempItemAccumulation.sort(function (a, b) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
  });

  




  data = tempItemAccumulation
  
  //set width dependin on the number of item
  const width = data.length*100
  //get the label of y axis
  var value1
  if(data[0])
    value1 = Object.keys(data[0])[1]

  const selectChartType = (type) => {
    setChartType(type)
  }

  let renderLabelPieChart = function(entry) {
    return entry.name;
  }

  const dateFormatter = date => {
    // return moment(date).unix();
    return new Date(date).toLocaleDateString()
  };

  const DateTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
          <p className="label">{`${new Date(label).toLocaleDateString()}`}</p>
          <p style={{color: '#fbc306' }}>{`${payload[0].name} : ${payload[0].value}`}</p>
          
        </div>
      );
    }
  
    return null;
  }
  return (
    <div>
      <Dropdown
        id='xAxisDrop'
        className='dropdownList'
        placeholder={traductionMap.product}
        options={xOptions}
        onChange={o => { setXAxisData(o.value) }}
      ></Dropdown>
      <Dropdown
        id='xAxisDrop'
        className='dropdownList'
        placeholder={traductionMap.income}
        options={yOptions}
        onChange={o => { setYAxisData(o.value) }}
      ></Dropdown>
      <div>
        <BarChartIcon onClick={()=>selectChartType('bar')}/>
        <ShowChart onClick={()=>selectChartType('line')}/>
        <PieChartIcon onClick={()=>selectChartType('pie')}/>
      </div>
      <ResponsiveContainer  height={777} minWidth={width}>
        {
          {
            'product':
            {
              'line':
              <LineChart data={data}>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>} interval={0} height={200}/>
                <YAxis />
                <Tooltip />
                <Line dataKey={value1} fill="#fbc306" stroke="#fbc306"  label/>
              </LineChart>,
              'bar':
              <BarChart data={data}>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/> }  interval={0} height={200} />
                <YAxis />
                <Tooltip />
                <Bar dataKey={value1} fill="#fbc306" label/>
              </BarChart>,
              'pie':
              
              <PieChart width={200} height={200}>
                <Pie data={data} dataKey={value1} nameKey="name"  fill="#fbc306"  label={renderLabelPieChart}>
                  <LabelList dataKey={value1} position="inside" color='black'   />
                </Pie>
              </PieChart>
            }[chartType]
            ,
            'type':
            {
              'line':
              <LineChart data={data}>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>} interval={0} height={200}/>
                <YAxis />
                <Tooltip />
                <Line dataKey={value1} fill="#fbc306" stroke="#fbc306"  label/>
              </LineChart>,
              'bar':
              <BarChart data={data}>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/> }  interval={0} height={200} />
                <YAxis />
                <Tooltip />
                <Bar dataKey={value1} fill="#fbc306" label/>
              </BarChart>,
              'pie':
              
              <PieChart width={200} height={200}>
                <Pie data={data} dataKey={value1} nameKey="name"  fill="#fbc306"  label={renderLabelPieChart}>
                  <LabelList dataKey={value1} position="inside" color='black'   />
                </Pie>
              </PieChart>
            }[chartType]
            ,
            'date':
            {
              
              'line':
              <LineChart data={data}>
                <XAxis 
                  dataKey="name"  
                  angle={45} 
                  dx={44}
                  dy={44}
                  minTickGap={-200}
                  axisLine={false} 
                  type="date" 
                  scale='time' 
                  tickFormatter={dateFormatter}  
                  interval={0} 
                  height={200}/>
                <YAxis />
                <Tooltip content={<DateTooltip/>}/>
                <Line dataKey={value1} fill="#fbc306" stroke="#fbc306"  label/>
              </LineChart>,
              'bar':
              <BarChart data={data}>
                <XAxis 
                  dataKey="name"  
                  angle={45} 
                  dx={44}
                  dy={44}
                  minTickGap={-200}
                  axisLine={false} 
                  type="date" 
                  scale='time' 
                  tickFormatter={dateFormatter}  
                  interval={0} 
                  height={200}/>
                <YAxis />
                <YAxis />
                <Tooltip content={<DateTooltip/>} />
                <Bar dataKey={value1} fill="#fbc306" label/>
              </BarChart>,
              'pie':
              
              <div style={{fontWeight: 'bolder', color:'red'}}>{traductionMap.unavailable}</div>
            }[chartType]
            
          }[xAxisData]
        }
      </ResponsiveContainer>
    </div>
  );
}

export default Chart
