import firebase from 'firebase/compat'
import { authDomain } from './tools/utils';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: authDomain,
  projectId: "sqipprod",
  storageBucket: "sqipprod.appspot.com",
  messagingSenderId: "1083022196245",
  appId: "1:1083022196245:web:0c71165648e21676d2981c",
  measurementId: "G-5D76S8PKRC"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const firestore = firebase.firestore();
  firestore.enablePersistence()
    .then(() => {
      // La persistance a été activée avec succès
      console.log('Persistence is enabled.');
    })
    .catch((error) => {
      // Gérer les erreurs liées à l'activation de la persistance
      console.error('Error enabling persistence:', error);
    });

export default firebase;

export const auth = firebase.auth();