
import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../firestore';
import { languageContext } from '../../context/LanguageContext';
import { absoluteLanguageContext } from '../../context/LanguageContext';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import "../../styles/views/boothAdminViews/Receipts.css"
import { arrayToCSV, translate } from '../../tools/Tools';
import { traductionMap } from '../../componants/Traduction';

var db = firebase.firestore();

function Receipts() {
  const [commands, setCommands] = useState(null);
  const commandPath = localStorage.getItem('userPath') + 'commands/'
  const { language } = useContext(languageContext);
  const { absoluteLanguage } = useContext(absoluteLanguageContext);
  var commandMap = new Map();

  // download the quittances as png
  const downloadHandler = (event) => {
    event.preventDefault();
    domtoimage.toBlob(document.getElementById('receipts'))
      .then(function (blob) {
        saveAs(blob, 'receipts.png');
      });
  }
  //Import the selected menu from firebase
  useEffect(() => {
    let unsubscribe;
    async function InitMenu() {
      unsubscribe = db.collection(commandPath).onSnapshot(function (querySnapshot) {
        var commandList = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists) {
            //must not take the "commands" document wich is in the same collection and only status "Delivered"
            if (doc.id.length > 10 ) {
              // Convert to command object
              var command;
              command = doc.data();
              command.key = doc.id;
              commandList.push(command);
            }

          } else {
            console.log("No such document!")
          }
        })
        if (commandList.length !== 0) {
          //sort the list by date hour
          commandList.sort((a, b) => {
            return new Date(a.dateHour + 'Z') - new Date(b.dateHour + 'Z')
          })
          setCommands(commandList);
        }

      });
    }



    InitMenu();
    return () => { unsubscribe() };
    // eslint-disable-next-line
  }, [language, absoluteLanguage]);
  if (commands !== null) {
    //set TVA
    commands.forEach((command) => {
      var tvaMap = new Map();
      var tvaList = []
      command.orderedItems.forEach((item)=> {
        if (tvaMap[item.tva] === undefined){
          tvaMap[item.tva] = 0
          tvaList.push(item.tva)
        }
        tvaMap[item.tva] = tvaMap[item.tva] + (item.price * item.quantity * item.tva / 100)
                            //add deposit tva
                            + (item.deposit * item.quantity * item.tva / 100)


      })
      commandMap[command.key] = tvaMap
      command.tvaList = tvaList
      tvaMap = new Map();
      tvaList = []
    })
  }
  const downloadCsv = (list) => {
    const csvFile = arrayToCSV(list)
    const blob = new Blob([csvFile], { type: 'text/csv' })
    console.log(csvFile)
    saveAs(blob, 'receipts.csv');
  }
  // create list of TVA
  return (
    <div>
      <div id="receipts">
        {commands === null ? null : commands.map((command) =>
          <div key={command.key} className='receipt'>
            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
              <div > {traductionMap.orderNumber}  {' ' + command.number} </div>
            </div>
            {command.orderedItems.map((item) =>
              <div key={item.key} className="flex-container-receipt-1">
                <div style={{ }}> {item.quantity}X</div>
                <div style={{ }}> {translate(item.itemName).substring(0, 25)} </div>
                <div style={{ textAlign: "right", marginRight: "1px" }}> {(item.price * item.quantity).toFixed(2)} CHF</div>

              </div>
            )}
            <div className="flex-container-receipt-1" style={{ fontWeight: "bold", fontSize: "14px" }}>
              <div style={{ }}>{traductionMap.totalDeposits} </div>
              <div style={{ textAlign: "right"}}>{command.totalDeposit.toFixed(2)} CHF</div>
            </div>
            {command.tvaList.map((tvaRate) =>
              <div className="flex-container-receipt-1" style={{ fontWeight: "bold", fontSize: "14px" }}>
                <div style={{ }}>TVA {tvaRate}% </div>
                <div style={{ textAlign: "right"}}>{commandMap[command.key][tvaRate].toFixed(2)} CHF</div>
              </div>
            )}
            <div className="flex-container-receipt-1" style={{ fontWeight: "bold", fontSize: "14px" }}>
              <div style={{ }}>{command.vatNumber} </div>
            </div>


            <div className="flex-container-receipt-1" style={{ fontWeight: "bolder", fontSize: "16px" }}>
              <div style={{ }}>{traductionMap.total} </div>
              <div style={{ textAlign: "right" }}>{command.totalPrice.toFixed(2)} CHF</div>
            </div>
          </div>
        )}
      </div>
      <button onClick={downloadHandler}>Sauvegarder</button>
      <button onClick={() => downloadCsv(commands)}>Sauvegarder csv</button>
    </div>
  )
}

export default Receipts;
