import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../firestore';
import { doc, getDoc } from "firebase/firestore";
import { currentPathContext } from '../../context/CurrentPathContext';
import { useHistory } from "react-router-dom";
import Popup from 'reactjs-popup';
import ChooseCompany from './ChooseCompany';
import SMSVerification from './SMSVerification';
import { loadGateway } from "../../tools/payrexx";
import loadingGif from '../../ressources/queuesQipAnimate.gif';
import { getAnyDocById } from '../../repostirories/firebaseFunctions';
import ReactDropdown from 'react-dropdown';
import Button from '../../componants/Button';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../tools/Tools';
import { traductionMap } from '../../componants/Traduction';
import { generateKey } from '../../tools/utils';


var db = firebase.firestore();

function BasketView() {
    var { itemPath } = useContext(currentPathContext);
    const basket = JSON.parse(localStorage.getItem('basket'))
    const [isLoading, setIsLoading] = useState(false)

    const [price, setPrice] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const history = useHistory();
    console.log(itemPath)
    if(itemPath[0] !== '/')
        itemPath = '/' + itemPath
    const collectionPath = itemPath.substring(0, itemPath.indexOf('/items') - 20)
    const orderPath = itemPath.substring(0, itemPath.indexOf('items')) +'commands'
    localStorage.setItem('orderPath', orderPath)
    const boothId = itemPath.substring(itemPath.indexOf('/items') - 20, itemPath.indexOf('/items'))
    const [sectionList, setSectionList] = useState(null)
    const [selectedSection, setSelectedSection] = useState('null')
    const [isSmsVerificationVisible, setIsSmsVerificationVisible] = useState(false)
    const [isSectionListVisible, setIsSectionListVisible] = useState(false)
    const [SMSMessage, setSMSMessage] = useState({default: ' '})
    const [comment, setComment] = useState(null)
    const [booth, setBooth] = useState({})
    const [companyVisible, setCompanyVisible] = useState(true)
    const [pmList, setPmList] = useState([])
    const [testMode, setTestMode] =useState(false)
    var defaultSection = localStorage.getItem('sectionNumber')
    localStorage.setItem('itemPath', itemPath)
    console.log(defaultSection)

    //if there's a list of section ask the section to the user
    useEffect(() => {
        async function getBooth() {
            var item = await getAnyDocById(collectionPath, boothId)
            setSectionList(item.sectionList)
            if(item.SMSMessage)
                setSMSMessage(item.SMSMessage)

            if (item.sectionList.length === 0)
                localStorage.setItem('sectionName', '')
            setBooth(item)
        }
        getBooth()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function setCurrentBasket() {
           
            setPrice(0);

            let tempPrice = 0;
            let totalDeposit = 0;

            basket.map((item) =>{
                tempPrice += (item.quantity*item.price)

                totalDeposit += (item.quantity*item.deposit)
                if(item.variableDepositQty)
                    totalDeposit += (item.variableDepositQty*item.variableDepositPrice)
                return tempPrice
            })
            //set if we need the sectionList dropdown or the phone number or both
            
            basket.forEach(item =>{

                if(item.quantity > 0){
                    item.isBrought?
                    setIsSectionListVisible(true)
                    :
                    setIsSmsVerificationVisible(true)
                }
               
            })
            

            setPrice(tempPrice);

            isNaN(totalDeposit)?
                setDeposit(0):
                setDeposit(totalDeposit)

            basket.totalPrice = tempPrice
            basket.totalDeposit = deposit
            localStorage.setItem('totalPrice', tempPrice)
            localStorage.setItem('totalDeposit', deposit)

            //find the command number

            localStorage.setItem("commandPath", localStorage.getItem("itemPath"))

            //look for adress of the booth
            const boothPath = localStorage.getItem("itemPath").substring(0, localStorage.getItem("itemPath").length - 26)
            const boothId = localStorage.getItem("itemPath").substring(35, 55)
            const boothRef = doc(db, boothPath, boothId);
            const docSnap = await getDoc(boothRef);
                console.log(boothPath)
                console.log(boothId)
            //booth informations
            if (docSnap.exists()) {
                basket.officialName = docSnap.data().officialName
                localStorage.setItem('officialName', translate(docSnap.data().officialName))
                basket.adress = docSnap.data().adress
                localStorage.setItem('adress', translate(docSnap.data().adress))
                basket.locality = docSnap.data().locality
                localStorage.setItem('locality', translate(docSnap.data().locality))
                basket.bringToCompany = docSnap.data().bringToCompany
                localStorage.setItem('bringToCompany', docSnap.data().bringToCompany)

                basket.vatNumber = docSnap.data().vatNumber
                localStorage.setItem('vatNumber', docSnap.data().vatNumber)
                basket.sqipRate = docSnap.data().sqipRate
                localStorage.setItem('noPreparation', docSnap.data().noPreparation)
                console.log(localStorage.getItem('noPreparation'))
                if (docSnap.data().sqipRate)
                    localStorage.setItem('sqipRate', docSnap.data().sqipRate)
                else 
                    localStorage.setItem('sqipRate', 0)
                setPmList(docSnap.data().pmList)
                console.log(docSnap.data().testMode)
                setTestMode(docSnap.data().testMode)
                



                
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }

        setCurrentBasket();
        // eslint-disable-next-line
    }, []);
    const addCommand = async () => {
        //put loading screen
        setIsLoading(true)
        //reset the code
        localStorage.setItem("validationCode", "");
        
        localStorage.setItem('companyVisible', 'true')
        console.log(basket)
        //generateId
        var referenceId = generateKey()
        //take only elements with quantity superior to 0
        var order = basket.filter(item => item.quantity>0)
        //get comment
        localStorage.setItem('comment', comment)
        // set sms message
        localStorage.setItem('SMSMessage', JSON.stringify(SMSMessage))
        localStorage.setItem('commandId', referenceId)
        //once the order with final quantity is set, put it in localstorage
        localStorage.setItem('order', JSON.stringify(order))
        //reset tempNoSMS
        localStorage.setItem('tempNoSms', '')
        // generate payrexx gateway
        console.log('test')
        console.log(testMode)
        if(isNaN(deposit)){
            localStorage.setItem('gateway', JSON.stringify({amount: price, pmList: pmList, referenceId: referenceId, testMode: testMode, orderPath: orderPath}))
            if(pmList.length === 1 || !pmList.includes('twint'))
                loadGateway(price, 'CHF', referenceId, pmList, testMode, orderPath)
            else 
                history.push({pathname:'/ChoosePm'})

        } else{
            localStorage.setItem('gateway', JSON.stringify({amount: price+deposit, pmList: pmList, referenceId: referenceId, testMode: testMode, orderPath: orderPath}))
            if(pmList.length === 1 || !pmList.includes('twint'))
                loadGateway(price+deposit, 'CHF', referenceId, pmList, testMode, orderPath )
            else 
                history.push({pathname:'/ChoosePm'})
        }
    }

    const backButton = () => {
        let push = itemPath.substring(0, itemPath.length)
        history.push("/Items/" + push);
    }

    const commandButton = () => {
        addCommand()

    }
    const changeSelectedSection = (section) => {
        setSelectedSection(section)
        localStorage.setItem('sectionName', section.value)
    }
    return (
        <>
            {isLoading || sectionList === null ?
                <div style={{ position: 'absolute', top: '30%', textAlign: 'center', fontSize: '170%' }} >
                    <img width='70%' src={loadingGif} className='loadingGif' alt='loadingGif'></img>
                    <div>{traductionMap.loading} ...</div>
                </div>
                :
                <div className='basketView'>
                    <h1>{traductionMap.myOrder}</h1>
                    {sectionList.length > 0 && selectedSection === 'null' && isSectionListVisible?
                        <div className='basketDropdown'>
                            {traductionMap.selectPlace}
                        </div> :
                        null}

                    {sectionList.length > 0 && isSectionListVisible?
                        <div className='basketDropdown'>
                            <ReactDropdown
                                id={'section'}
                                value={defaultSection}
                                placeholder={"Emplacement"}
                                options={sectionList}
                                onChange={o => { changeSelectedSection(o) }}
                            ></ReactDropdown>
                        </div> :
                        <div></div>}

                    {
                        booth.bringToCompany && companyVisible?
                            <ChooseCompany setVisible={setCompanyVisible}/>:
                        (localStorage.getItem("validePhoneNumber") === "true")
                        || (sectionList.length > 0 && selectedSection !== 'null') 
                        || localStorage.getItem('tempNoSms') 
                        || localStorage.getItem('noSms')
                        || (booth.bringToCompany && companyVisible === false)
                        || booth.noPreparation?
                        <div>

                            <div className="basketContent">
                                {basket.sort((a, b) => a.type > b.type ? -1 : 1).map((item) =>
                                    item.quantity > 0 ?
                                        <div style={{borderBotom: 'grey solid 2px' }}>
                                            <span>{item.quantity}</span>
                                            <span> X </span>
                                            <span>{translate(item.itemName)}</span>
                                            <span> (</span>
                                            <span>{item.price} CHF) </span>
                                            <span> = </span>
                                            <span>{(item.price * item.quantity).toFixed(2)} CHF</span>
                                            
                                            <div className='listSubItemContent'>
                                                {item.selectedSubItems !== undefined ?
                                                    item.selectedSubItems.map((subItem)=>(
                                                    <div >
                                                        {translate(subItem)} 
                                                    </div>
                                                    )):null
                                                }
                                            </div>
                                            {item.variableDepositQty > 0 ?
                                                <div className='listSubItemContent'>
                                                    {item.variableDepositQty + ' ' +translate(item.variableDepositName)}
                                                </div>
                                                :null
                                            }
                                        </div>
                                        
                                        : null
                                )}
                                {deposit > 0?
                                    <p className="basketTotalPrice">
                                        <p>{traductionMap.totalDeposits} : {deposit.toFixed(2)} CHF</p>
                                    </p>:null
                                }
                                <p className="basketTotalPrice" >
                                    <p>{traductionMap.totalBasket} : {(price+ deposit).toFixed(2)} CHF</p>
                                </p>
                                <div className="basketTotalPrice"  style={{display: 'block', width:'100%'}}>
                                    <div>{traductionMap.comment} : </div>
                                    <textarea onChange={(e)=>setComment(e.target.value)} placeholder={traductionMap.commentEx} ></textarea>
                                </div>
                            </div>


                            <Popup
                                trigger={<div className='EmailVerification'>
                                    <Button>{traductionMap.pay}</Button>
                                </div>}
                                modal
                                nested
                            >
                                {close => (
                                    <div className="modal">
                                        <div className="content">
                                            <i className="closeButton" onClick={close}>
                                                <CloseIcon />
                                            </i>
                                            <p>{traductionMap.clickToAccept} <br /><a href="https://sqip.ch/CGU.pdf" target="blank">{traductionMap.terms}</a></p>
                                        </div>
                                        <div className="actions">
                                            <Button
                                                onClick={() => {
                                                    commandButton();
                                                    close();
                                                }}
                                            >
                                                {traductionMap.continue}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div> :
                        isSmsVerificationVisible && !localStorage.getItem('tempNoSms') && !localStorage.getItem('noSms')?
                            <SMSVerification /> :
                            null
                    }

                    <div className='modifyCommand'>
                        <Button onClick={backButton}>{traductionMap.modifyOrder}</Button>
                    </div>

                </div>
            }
        </>
    )
}

export default BasketView;
