import { traductionMap } from '../../componants/Traduction';
import logo from '../../ressources/Logo.png';
import React from 'react'

function UserGuide() {

  return (
    <div className="userGuide">
      <div className="userGuideContent">
        <h1>{traductionMap.help}</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <ul>
          <li>
            <div>
              <span>1</span>
            </div>
            <p>{traductionMap.userGuide1}</p>
          </li>
          <li>
            <div>
              <span>2</span>
            </div>
            <p>{traductionMap.userGuide2}</p>
          </li>
          <li>
            <div>
              <span>3</span>
            </div>
            <p>{traductionMap.userGuide3}</p>
          </li>
          <li>
            <div>
              <span>4</span>
            </div>
            <p>{traductionMap.userGuide4}</p>
          </li>
          <li>
            <div>
              <span>5</span>
            </div>
            <p>{traductionMap.userGuide5}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default UserGuide;
