
import Button from '../../componants/Button'
import LoadingScreen from '../../componants/LoadingScreen'
import { traductionMap } from '../../componants/Traduction'
import { useState } from 'react'
import { UpdateDepositAmount } from '../../repostirories/firebaseFunctions'
import { refundTransaction } from '../../tools/payrexx'
import { doc, updateDoc } from "firebase/firestore";
import firebase from '../../firestore';

var db = firebase.firestore()
function FullRefund(props) {
  const command = props.command
  const commandPath = localStorage.getItem('userPath') + 'commands'
  // const boothPath = localStorage.getItem('userPath') + 'items'
  const [isLoading, setIsLoading] = useState(false);
  
  console.log(command)
  
  const cashBack = async (amount) =>{
    var givenBackMoney = 0
    if(command.givenBackMoney)
      givenBackMoney = command.givenBackMoney

    setIsLoading(true)
    try{
      refundTransaction(command.transactionId, amount*100).then(async ()=>{
        const ref = doc(db, commandPath, command.id)
        await updateDoc(ref, {
          isRefund: true
        }).then(()=>{
          setIsLoading(false)
        });

      })
      
    }
    catch(e){
      console.log(e)
    }


  }


  return (
    <div className="App" style={{textAlign:"center", fontSize:"30px", fontWeight:"bolder", height:"100%", position:'absolute'}}>
      {isLoading ?
        <LoadingScreen></LoadingScreen>
      :
      <>
        <br></br>

        <span>Montant à rendre  </span>
        <div id='depositCashBack' style={{fontSize: '30px', margin: '20px', textAlign: 'center'}} >{command.totalPrice} CHF</div>
        
        <Button onClick={()=>cashBack(command.totalPrice)}>Rembourser</Button>
      </>}

      
   
    </div>

  );
}

export default FullRefund;
