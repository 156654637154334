 
import React, {  useEffect, useState } from 'react';
import firebase from '../../firestore';
import { useHistory } from "react-router-dom";
import trashIcon from '../../ressources/trash.png'
import { DeleteFirebaseEvent } from '../../repostirories/firebaseFunctions';
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useForceUpdate } from '../../tools/utils';
import ListItem from '../../componants/ListItem';
import { traductionMap } from '../../componants/Traduction';
import { getPosition, translate } from '../../tools/Tools';

var db = firebase.firestore();

function EventList() {
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [items, setItems] = useState(null);
  const userRole = localStorage.getItem('role')
  var paths = {0: localStorage.getItem('userPath')}
  if(localStorage.getItem('userPaths') !== "undefined"){
      paths = JSON.parse(localStorage.getItem('userPaths'))
  }
  // set path
  localStorage.setItem("eventPath", "events/")

  useEffect(() => {
    //Import the selected menu from firebase
    async function InitMenu() {
      var itemList = [];
      if(userRole === 'SuperAdmin'){
        await getDocs(collection(db, localStorage.getItem("eventPath"))).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if (doc.exists) {
              // Convert to item object
              var item;
              item = doc.data();
              item.id = doc.id;
  
              itemList.push(item);
  
            } else {
              console.log("No such document!")
            }
          })
          if (itemList.length !== 0) {
            setItems(itemList);
          }
        });
      }
      else{
        console.log(paths)
        paths.forEach(async (path)=>{
          const eventId = path.substring(getPosition(path, '/', 1)+1).slice(0,-1)

          const docRef = doc(db, localStorage.getItem("eventPath"), eventId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // Convert to item object
            var item;
            item = docSnap.data();
            item.id = docSnap.id;

            itemList.push(item);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
          console.log(itemList)
          if (itemList.length !== 0) {
            setItems(itemList);
          }
          forceUpdate()
        })
      }
    }
    InitMenu()
    // eslint-disable-next-line
  }, [])

  const deleteEvent = (event, path, items) => {
    DeleteFirebaseEvent(event, path, items)
    forceUpdate()
  }
  const backButton = () => {
    history.push('/AdminConsole')
  }
  return (
    <div className="event">
      <div className="eventContent">
        {items === null ? null : items.map((item) =>
          <ListItem key={item.id}>
            <div style={{width: '80%'}} onClick={() => {
              localStorage.setItem("eventPath", "events/" + item.id + "/")
              localStorage.setItem("pseudoRole", 'EventAdmin');
              localStorage.setItem("eventUserPath", localStorage.getItem("eventPath") + 'menus/')
              history.push("/EventAdminConsole")
              localStorage.setItem('selectedEventId', item.id)
            }
            }>{translate(item.eventName)}
            </div>
            <div >
              <img onClick={() => deleteEvent(item, 'events/', items)} style={{ height: '44px', width: 'auto' }} src={trashIcon} alt="Supprimer un événement"></img>
            </div>

          </ListItem>)
        }
        <div className="footerButton">
          <p className="backBasket" onClick={backButton}>{traductionMap.return}</p>
        </div>
      </div>
    </div>
  )
}

export default EventList;
