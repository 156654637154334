import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../firestore';
import { BasketContext } from '../../context/Basket'
import { useHistory } from "react-router-dom";
import DisplayItems from "../../componants/DisplayItems";
import IconGlutenFree from "../../ressources/IconGluten2Color.png";
import IconVegetarian from "../../ressources/IconVegan2Color.png";
import IconLactoseFree from "../../ressources/IconLactose2Color.png";
import IconAlcool from "../../ressources/IconAlcohol2Color.png"
import IconGlutenFreeBW from "../../ressources/IconGluten2.png";
import IconVegetarianBW from "../../ressources/IconVegan2.png";
import IconLactoseFreeBW from "../../ressources/IconLactose2.png";
import IconAlcoolBW from "../../ressources/IconAlcohol2.png"
import { ref, getDownloadURL, getStorage } from "firebase/storage";
import { useForceUpdate } from '../../tools/utils';
import ConfirmScreen from '../../componants/ConfirmScreen';
import LoadingScreen from '../../componants/LoadingScreen';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import Button from '../../componants/Button';
import { traductionMap } from '../../componants/Traduction';
import { getPosition, isPositiveInteger, translate } from '../../tools/Tools';
import { getAnyDocById } from '../../repostirories/firebaseFunctions';
import { roleList } from '../../tools/configVariables';

var db = firebase.firestore();

function Menu() {
  const itemPath = window.location.href.substring(window.location.href.indexOf('events/'))
  localStorage.setItem('sectionNumber', window.location.href.substring(window.location.href.indexOf('/Items') + 7, window.location.href.indexOf('/Items') + 8))
  const { basket, setBasket } = useContext(BasketContext);
  const [totalPrice, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [basketReady, setBasketReady] = useState(false);
  const [vege, setVege] = useState(false);
  const [gluten, setGluten] = useState(false);
  const [milk, setMilk] = useState(false);
  const [alcohol, setAlcohol] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchDisplay, setSearchDisplay] = useState(false);
  const [types, setTypes] = useState([]);
  const [sixteenMinConst, setSixteenMin] = useState(false);
  const [eighteenMinConst, setEighteenMin] = useState(false);
  const [confirmScreen18, setConfirmScreen18] = useState(false)
  const [confirmScreen16, setConfirmScreen16] = useState(false)
  const storage = getStorage();
  const boothFullPath = itemPath.substring(0, itemPath.indexOf('/items'))
  const boothPath = itemPath.substring(0, getPosition(itemPath, '/', 3))
  const boothId = itemPath.substring(getPosition(itemPath, '/', 3)+1, itemPath.indexOf('/items'))
  const [booth, setBooth] = useState({})
  const forceUpdate = useForceUpdate()
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  //set menu path in local storage
  localStorage.setItem("itemPath", itemPath)
  //set the url for qrCode
  localStorage.setItem("urlItems", window.location.href);
  

  window.location.replace("https://sqipprod.web.app/Items/"+ itemPath)
  //Import the selected menu from firebase
  useEffect(() => {
    async function InitMenu() {
      //set booth
      var boothTemp = await getAnyDocById(boothPath, boothId)
      setBooth(boothTemp)
      
      // set items
      db.collection(itemPath).onSnapshot(function (querySnapshot) {
        var itemList = [];
        querySnapshot.forEach(function (doc) {
          const today = new Date()
          var dateString
          var dateParts
          var date
          var yesterday = today
          if(doc.data().date){
            dateString = doc.data().date
            dateParts = dateString.split(".")
            date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
            yesterday = date 
            yesterday.setDate(date.getDate() - 1)
            var hour = '14:00'
            if(doc.data().hour)
              hour = doc.data().hour
            hour = hour.split(':')
            yesterday.setHours(hour[0])
            yesterday.setMinutes(hour[1])
          }
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists && (yesterday.getTime() > today.getTime() || !doc.data().date)) {
            // Convert to item object
            var item;
            item = doc.data();
            item.key = doc.id;
            item.display = false;
            item.variableDepositQty = 0

            //catch null exception
            try {
              //get the context for the quantity
              var previousBasket 
              if(localStorage.getItem("basket"))
                previousBasket = JSON.parse(localStorage.getItem("basket"))
              else  
                previousBasket = []
              previousBasket.map((itemToTest) =>
                item.soldOut ? null :
                item.key === itemToTest.key && (itemToTest.quantity <= item.availableInStock-item.stockThreshold || !isPositiveInteger(item.stockThreshold))? item.quantity = itemToTest.quantity :null
              )
              // set sub Items
              previousBasket.map((itemToTest) =>
                item.soldOut ? null :
                item.key === itemToTest.key? item.selectedSubItems = itemToTest.selectedSubItems :null
              )
              // set variable qty
              previousBasket.map((itemToTest) =>
                item.soldOut ? null :
                item.key === itemToTest.key? item.variableDepositQty = itemToTest.variableDepositQty :null
              )
            }
            catch (Exception) { console.log(Exception)}

            itemList.push(item);


          } else {
            console.log("No such document!")
          }
        })

        if (itemList.length !== 0) {
          itemList.sort((a, b) => {
            return a.itemNumber - b.itemNumber
          })
          setBasket(itemList);
          basket.map((item) =>
            item.quantity > 0 ?
              (
                item.attributs['eighteenMin'] === true ? setEighteenMin(true) : null,
                item.attributs['sixteenMin'] === true ? setSixteenMin(true) : null
              )
            : null)
        } else {
          setBasket([]);
        }

        //check if booth is opened
        db.collection(itemPath.substring(0, 34)).doc(itemPath.substring(34, 54)).get().then((doc) => {
          if (doc.exists) {
            if (doc.data()["open"] === false)
              setOpen(false);
          }
        })

      })

    }
    async function initTypes() {
      setIsLoading(true)
      //init list of types
      db.collection(itemPath.substring(0, '54') + '/types').onSnapshot(function (querySnapshot) {
        var typeList = [];
        var i = 0
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists) {
            var item;
            item = doc.data();
            item.key = doc.id;
            types[i] ?
              item.open = types[i] :
              item.open = false

            // dowload images of types  
            const gsReference = ref(storage, boothFullPath + '/typeImages/' + item.number + '.png')
            getDownloadURL(gsReference)
              .then((url) => {
                item.imageRef = url

                setIsLoading(false)
                forceUpdate()

              }).catch((e) => {
                console.log(e)
                setIsLoading(false)
              })
          }
          i++;
          typeList.push(item);

          //set image of types
          // Create a reference from a Google Cloud Storage URI

        })
        if (typeList.length !== 0) {
          //sort the list by type number
          typeList.sort((a, b) => {
            return a.number - b.number
          })
          setTypes(typeList)
        } else 
        setIsLoading(false)
      })
    }
    initTypes()
    InitMenu()
    
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    basket.forEach(item => {
      let goIn = true;

      let isAlcohol = true;
      if (item.attributs['sixteenMin'] || item.attributs['eighteenMin']) {
        isAlcohol = false;
      }

      if (gluten && !item.attributs['glutenFree']) {
        goIn = false;
      }

      if (milk && !item.attributs['lactoseFree']) {
        goIn = false;
      }

      if (vege && !item.attributs['vegetarian']) {
        goIn = false;
      }

      if (alcohol && !isAlcohol) {
        goIn = false;
      }
      if (goIn) {
        item.display = true
      }
      else
        item.display = false
    });
    forceUpdate()
    // eslint-disable-next-line
  }, [alcohol, vege, milk, gluten, basket])

  useEffect(() => {
    async function updateBasket() {
      setPrice(0);
      setQuantity(0);

      let price = 0;
      let quantity = 0;
      // eslint-disable-next-line
      let wasNotnull = false
      //set undefined items to 0 to calculate the price even then the quantity is undefined
      basket.forEach(item => {
        if (typeof item.quantity === 'undefined')
          item.quantity = 0
      });
      // eslint-disable-next-line
      basket.map((item) =>{
        if(item.quantity > 0){
          price += (item.quantity * item.price)
        }
      }
      )
      basket.map((item) =>
      (
        // check if 
        item.quantity > 0 ?
          // eslint-disable-next-line
          wasNotnull = true : null,

        quantity += item.quantity
      )
      )
      setPrice(price);
      setQuantity(quantity);

      if (quantity / quantity !== 1) {
        setQuantity(0);
        setPrice(0);
      }
      setEighteenMin(false)
      setSixteenMin(false)
      basket.map((item) =>
        item.quantity > 0 ?
          (
            item.attributs['eighteenMin'] === true ? setEighteenMin(true) : null,
            item.attributs['sixteenMin'] === true ? setSixteenMin(true) : null
          ) : null
      )

      if (basketReady) {
        
        //store baske in local storage
        var stringBasket = JSON.stringify(basket)
        localStorage.setItem("basket", stringBasket)
        
      }

    }

    updateBasket();

  }, [basket, basketReady]);
  useEffect(() => {
    async function updateSearchDisplay() {
      if (searchDisplay) {
        document.getElementById("searchText").style.opacity = "1"
        document.getElementById("searchText").style.width = "60%"
        document.getElementById("searchIcon").style.color = "#ec7c34"
        setTypes(
          types.map(modifyType =>
            modifyType.key !== null ?
              { ...modifyType, open: true }
              : modifyType
          )
        )
      }
      else {
        document.getElementById("searchText").style.opacity = "0"
        document.getElementById("searchText").style.width = "0%"
        document.getElementById("searchIcon").style.color = "#fff"
      }
    }
    updateSearchDisplay();
    // eslint-disable-next-line
  }, [searchDisplay]);
  //the effect of the submit button
  const buyBasket = async function (price) {
    if (eighteenMinConst && localStorage.getItem('dontAsk18') !== 'true') {
      setConfirmScreen18(true)
    }
    else if (sixteenMinConst && localStorage.getItem('dontAsk18') !== 'true' && localStorage.getItem('dontAsk16') !== 'true') {
      setConfirmScreen16(true)
    }
    else if (price > 0){
      history.push("/Basket")
    }
    // else
    //   history.push("/Basket");
  }
  const addToOrder = (price) =>{
    localStorage.setItem('currentItemPath', itemPath)
    if(price > 0){
      history.push("/PartialOrderView")
    }
  }
  //update the state of the type
  const changeTypeOpen = type => {
    setTypes(
      types.map(modifyType =>
        modifyType.key === type.key ?
          { ...modifyType, open: !modifyType.open }
          : modifyType
      )
    )
  }
  const dontAskAge = (age) => {
    localStorage.setItem('dontAsk' + age, 'true')
    history.push("/Basket")
  }

  const checkIfTypeExists = (type) =>{
    if ( type.invisible)
      return false
    var result = basket.some(item => item.type['default'] === type.type['default'])
    return result
  }
  return (
    <>
      {isLoading ?
        <LoadingScreen />
        :
        <div className='item'>
          {roleList.includes(localStorage.getItem('role')) || !booth.postponedPayment?
          <>
            <i id="searchIcon" onClick={() => setSearchDisplay(!searchDisplay)}><SearchIcon className='searchIcon' /></i>
            <input id="searchText" type='text' placeholder='Recherche' value={searchText} onChange={e => setSearchText(e.currentTarget.value)} />
            <div className="itemContent">
              <div className="itemFilters">
                <p>{traductionMap.filters} </p>
                <div>
                  {gluten ? <img src={IconGlutenFree} alt="Sans gluten" onClick={() => setGluten(!gluten)} /> : <img src={IconGlutenFreeBW} alt="sans gluten" onClick={() => setGluten(!gluten)} />}
                  {vege ? <img src={IconVegetarian} alt="Végétarien" onClick={() => setVege(!vege)} /> : <img src={IconVegetarianBW} alt="Végétarien" onClick={() => setVege(!vege)} />}
                  {milk ? <img src={IconLactoseFree} alt="Sans gluten" onClick={() => setMilk(!milk)} /> : <img src={IconLactoseFreeBW} alt="Sans gluten" onClick={() => setMilk(!milk)} />}
                  {alcohol ? <img src={IconAlcool} alt="alcohol" onClick={() => setAlcohol(!alcohol)} /> : <img src={IconAlcoolBW} alt="alcohol" onClick={() => setAlcohol(!alcohol)} />}
                </div>
              </div>
              {!open ?
                <div className="flexLeft">
                  <h1 style={{ textAlign: "center", marginTop: "60px" }}>{traductionMap.storeClosed}</h1>
                </div> :
                <div className="itemListContent">
                  {types === null ? null : types.map((item) =>
                    <div key={item.key}>
                      
                      {checkIfTypeExists(item)?
                      <div className="itemListTitle" onClick={() => changeTypeOpen(item)}>
                        {item.imageRef ?
                          <>
                            <img src={item.imageRef} alt='type'></img>
                            <h2>{translate(item.type)}</h2>
                          </>
                          :
                          <h2 style={{ backgroundColor: 'black', width: '100%', margin: 0, padding: '40px' }}>{translate(item.type)}</h2>
                        }
                      </div>:null}

                      {item.open ?
                        <DisplayItems typeOfItem={item.type} setBasketReady={setBasketReady} searchText={searchText} />
                        :
                        null
                      }
                    </div>
                  )}
                </div>
              }
              {confirmScreen18 ?
                <ConfirmScreen message={traductionMap.confirm18} labelYes={traductionMap.confirm} labelOthers={traductionMap.confirmPerm}
                  labelNo={traductionMap.cancel} functionYes={() => history.push("/Basket")} functionNo={() => setConfirmScreen18(false)} functionOthers={() => dontAskAge(18)} />
                : confirmScreen16 ?
                  <ConfirmScreen message={traductionMap.confirm16} labelYes={traductionMap.confirm}
                    labelNo={traductionMap.cancel} labelOthers={traductionMap.confirmPerm}
                    functionYes={() => history.push("/Basket")} functionNo={() => setConfirmScreen16(false)} functionOthers={() => dontAskAge(16)} />
                  : null
              }
              <div className="commandContent" onClick={buyBasket}>
                <ShoppingCartIcon className='shopIcon' />
                <span>{quantity}</span>
                <p id="totalPrice">{totalPrice.toFixed(2)} CHF</p>
                {!booth.postponedPayment?
                  <Button onClick={()=>buyBasket(totalPrice)}>{traductionMap.makeOrder}</Button>
                  :
                  <Button onClick={()=>addToOrder(totalPrice)}>{traductionMap.makeOrder}</Button>

                }
              </div>
            
            </div>
          </>
          :null
          }
        </div>
      }
    </>
  )
}

export default Menu;
