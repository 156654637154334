import React, { useEffect, useState } from 'react';
import { QRCode } from "react-qr-svg";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import '../../styles/views/boothAdminViews/QrCodeView.css'
import { traductionMap } from '../../componants/Traduction';
import { getPosition } from '../../tools/Tools';
import { getAnyDocById } from '../../repostirories/firebaseFunctions';
import Button from '../../componants/Button';
function QrCode() {
    const fullPath = localStorage.getItem('userPath')
    console.log(fullPath)
    const path = fullPath.substring(0, getPosition(fullPath, '/',3))
    const boothId = fullPath.substring(getPosition(fullPath, '/', 3))
    const [sectionList, setSectionList] = useState([1, 2, 3, 4])
    const [sectionMode, setSectionMode] = useState(false)

    useEffect(() => {
        async function getSectionList(){
            const docSnap = await getAnyDocById(path, boothId)
            if (docSnap) {
                setSectionList( docSnap.sectionList)
            } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            }

        }
        getSectionList()
        // eslint-disable-next-line
    },[])

    const downloadHandler = (id) => {
        domtoimage.toBlob(document.getElementById(id))
            .then(function (blob) {
                saveAs(blob, 'qrCode.png')
            });
    }
    return (
        <div className="qrCode" >
            <Button onClick={()=>setSectionMode(true)}>Par section</Button>
            <Button onClick={()=>setSectionMode(false)}>Par stand</Button>
            {!sectionMode?
            <>
            <div id="menuQrCode">
                <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    style={{ width: 256 }}
                    value={localStorage.getItem("urlItems")}
                >
                </QRCode>
            </div>
            <button onClick={()=>downloadHandler("menuQrCode")}>{traductionMap.save}</button>
            </>
            :
            <>
            <div id={"sectionQrCode"}>
            {sectionList.map((section)=>
                    <div key={section} id={"menuQrCode" + section} >
                    <QRCode
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        style={{ width: 256 }}
                        value={localStorage.getItem("urlItems").replace('Items', 'Items/' + section)}
                    >
                    </QRCode>
                    <div style={{fontSize: '40px'}}>
                        {section}
                    </div>
                </div>
            )}
            </div>
            
            <button onClick={()=>downloadHandler("sectionQrCode")}>{traductionMap.save}</button>
            </>}
        </div>
    );
}
export default QrCode;
