import {React} from 'react';
import {useHistory} from "react-router-dom";
import {AddFirebaseBooth} from "../../repostirories/firebaseFunctions"
import { traductionMap } from '../../componants/Traduction';

function AddBooth() {
    const history = useHistory();

    const addBooth = () => {
        var booth = {};
        booth.boothName = {}
        booth.officialName = {}
        booth.adress = {}
        booth.locality = {}
        booth.SMSMessage = {}
        // default payment
        booth.postponedPayment = false
        // add booth name in all languages
        
        booth.boothName['default'] = document.getElementById("boothName").value
        booth.officialName['default'] = document.getElementById("officialName").value
        booth.adress['default'] = document.getElementById("adress").value
        booth.locality['default'] = document.getElementById("locality").value
        booth.vatNumber = document.getElementById("vatNumber").value
        booth.SMSMessage['default'] = document.getElementById("SMSMessage").value

            
        

        AddFirebaseBooth(booth, localStorage.getItem("eventPath")+ "menus/");
        
        history.push("/EventAdminConsole")
    } 
    
    
    const backButton = () =>{
        history.push('/EventAdminConsole')
    } 
    return (
        <div className={"App"}>
            <div className="basketFooter">
                <p className="backBasket" onClick={backButton}>Retour</p>
            </div>
            <br/>
            
            <div>{traductionMap.boothName}</div>
            <div>
                <input id={"boothName"}></input>
            </div>
            <div>{traductionMap.officialName}</div>
            <div>
                <input id={"officialName"}></input>
            </div>
            <div>{traductionMap.address}</div>
            <div>
                <input id={"adress"}></input>
            </div>
            <div>{traductionMap.city}</div>
            <div>
                <input id={"locality"}></input>
            </div>
            <div>{traductionMap.vat}</div>
            <div>
                <input id={"vatNumber"}></input>
            </div>
            <div>{traductionMap.SMSMessage} ({traductionMap.optional})</div>
            <div>
                <input id={"SMSMessage"}></input>
            </div>
            <p></p>
            
            <div className={'LoginView'}><button  onClick={addBooth}>{traductionMap.addBooth}</button></div>
        </div>

    );

}
export default AddBooth;