import {React, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {getAnyDocById, UpdateFirebaseBooth, UpdateFirebaseBoothOpen} from "../../repostirories/firebaseFunctions"
import LoadingScreen from '../../componants/LoadingScreen';
import { getPosition, translate } from '../../tools/Tools';
import { traductionMap } from '../../componants/Traduction';

function UpdateBooth() {
    const history = useHistory();
    const [booth, setBooth] = useState({})
    const fullPath = localStorage.getItem('userPath')
    const path = fullPath.substring(0, getPosition(fullPath, '/',3))
    const boothId = fullPath.substring(getPosition(fullPath, '/', 3))
    console.log(booth)
    useEffect(() => {
        var item;
        async function getBooth(){
            item = await getAnyDocById(path, boothId)
            if(!item.SMSMessage)
                item.SMSMessage = ""
            if(!item.vatNumber)
                item.vatNumber = ""
            if(!item.testMode)
                item.testMode = false
            setBooth(item)
        }
        getBooth()
    // eslint-disable-next-line
    },[])
    const updateBooth = () => {
        console.log(booth.vat)
        if(!booth.postponedPayment)
            booth.postponedPayment= false
        if(!booth.noPreparation)
            booth.noPreparation= false
        //update booth
        UpdateFirebaseBooth(booth, path)
        
        history.push("/BoothAdminConsole")
    } 

    const openCloseBooth = () => {
        UpdateFirebaseBoothOpen(booth, path)
        setBooth(s =>{
            return{
                ...s,
                open: !booth.open
                
            }
        })
    } 



    const backButton = () =>{
        history.push('/BoothAdminConsole')
    } 
    return (
    booth.id?
    <div className={"App"}>
        <div className="footerButton">
            <p className="backBasket" onClick={backButton}>{traductionMap.return}</p>
        </div>
        <br/>
        <div>
            {booth.open ?
                <span className="open" onClick={()=>openCloseBooth(booth.open)}>{traductionMap.booth} {traductionMap.opened}</span>
            :
                <span className="close" onClick={()=>openCloseBooth(booth.open)}>{traductionMap.booth} {traductionMap.closed}</span>}
        </div>
            <div className={'LoginView'}>
                <div>{traductionMap.boothName}</div>
                <div>
                    <input id={"boothName"} 
                    defaultValue={booth.menuName['default']}
                    onChange = {e => booth.menuName['default'] = e.currentTarget.value}></input>
                </div>
                <div>{traductionMap.officialName}</div>
                <div>
                    <input id={"officialName"}
                    defaultValue={booth.officialName['default']}
                    onChange = {e => booth.officialName['default'] = e.currentTarget.value}></input>
                </div>
                <div>{traductionMap.address}</div>
                <div>
                    <input id={"adress"}
                    defaultValue={booth.adress['default']}
                    onChange = {e => booth.adress['default'] = e.currentTarget.value}></input>
                </div>
                <div>{traductionMap.city}</div>
                <div>
                    <input id={"locality"}
                    defaultValue={booth.locality['default']}
                    onChange = {e => booth.locality['default'] = e.currentTarget.value}></input>
                </div>
                <div>{traductionMap.vat}</div>
                <div>
                    <input id={"vatNumber"}
                    defaultValue={booth.vatNumber}
                    onChange = {e => booth.vatNumber = e.currentTarget.value}></input>
                </div>
                <div>{traductionMap.SMSMessage}</div>
                <div>
                    <input id={"SMSMessage"}
                    defaultValue={translate(booth.SMSMessage)}
                    onChange = {e => booth.SMSMessage[localStorage.getItem('language')] = e.currentTarget.value}></input>
                </div>
                <div>{traductionMap.postponedPayment}</div>
                <div>
                    <input id={"postPonedPayment"}
                    type='checkbox'
                    defaultChecked={booth.postponedPayment}
                    onChange = {e => booth.postponedPayment = e.currentTarget.checked}></input>
                </div>
                <div>{traductionMap.noPreparation}</div>
                <div>
                    <input id={"noPreparation"}
                    type='checkbox'
                    defaultChecked={booth.noPreparation}
                    onChange = {e => booth.noPreparation = e.currentTarget.checked}></input>
                </div>
                <div>{traductionMap.testMode}</div>
                <div>
                    <input id={"testMode"}
                    type='checkbox'
                    defaultChecked={booth.testMode}
                    onChange = {e => booth.testMode = e.currentTarget.checked}></input>
                </div>
                <div>{traductionMap.color}</div>
                <div>
                    <input id={"boothColor"}
                    type='color'
                    defaultValue={booth.color}
                    onChange = {e => booth.color = e.currentTarget.value}></input>
                </div>
                <p></p>
            </div>
        <p/>
        <div className={'LoginView'}><button  onClick={updateBooth}>{traductionMap.modifyBooth}</button></div>
    </div>:
    <LoadingScreen/>
    

);

}
export default UpdateBooth;