import React, { useContext, useState } from 'react';
import { BasketContext } from "../context/Basket";
import IconGlutenFree from "../ressources/IconGluten2.png";
import IconVegetarian from "../ressources/IconVegan2.png";
import IconLactoseFree from "../ressources/IconLactose2.png";
import IconSixteen from "../ressources/sixteen.png";
import IconEighteen from "../ressources/eighteen.png";
import { isPositiveInteger, translate } from '../tools/Tools';
import { traductionMap } from './Traduction';
import  Button  from './Button';

function DisplayItems(props) {

    const { basket, setBasket } = useContext(BasketContext);
    const [visibility, setVisibility] = useState('hidden')
    const [variableDepositVisible, setVariableDepositVisible ] = useState('hidden')
    const [keyVisible, setKeyVisible] = useState(null)
    const [variableDepositQty, setVariableDepositQty] = useState(0)


    const typeOfItem = props.typeOfItem
    const setBasketReady = props.setBasketReady
    const searchText = props.searchText

    const [plusButtonClicked, setPlusButtonClicked] = useState(false)
    const reduceQuantity = (item) => {
        deleteLastSubItem(item)
        setBasketReady(true);
        setBasket(
            basket.map(modifyItem =>
                modifyItem.key === item.key ?
                    { ...modifyItem, quantity: modifyItem.quantity - 1 }
                    : modifyItem
            )
        )
        
    }
    const increaseQuantity = (item) => {
        setKeyVisible(item.key)
        setPlusButtonClicked(true)
        setBasketReady(true);
        setBasket(
            basket.map(modifyItem =>
                modifyItem.key === item.key ?
                    { ...modifyItem, quantity: modifyItem.quantity + 1 }
                    : modifyItem
            )
        )
        if(item.subItems){
            if ((item.subItems[0] !== "" && item.subItems[0] !== undefined) || item.variableDepositPrice > 0) {
                setBasket(
                    basket.map(modifyItem =>
                        modifyItem.key === item.key ?
                            { ...modifyItem, quantity: modifyItem.quantity }
                            : modifyItem
                    )
                )
                setVisibility('visible')
                setVariableDepositVisible('visible')
            }
        }
    }
    const deleteLastSubItem = (item) => {
        if(Array.isArray(item.selectedSubItems))
            item.selectedSubItems.pop()

        if((item.quantity-1) === 0){
            item.variableDepositQty = 0
        }
    }
    // put subitems selected in localstorage
    const selectSubItem = (o, item) => {
        setKeyVisible('')
        setVisibility('hidden')
        if(!Array.isArray(item.selectedSubItems))
            item.selectedSubItems = []

        item.selectedSubItems.push(o)
        setBasket(
            basket.map(modifyItem =>
                modifyItem.key === item.key ?
                    { ...item, quantity: modifyItem.quantity + 1}
                    : modifyItem
            )
        )
    }

    //validate the number of variable deposit and add it in basket
    const confirmVariableDepositNumber = (item) => {
        setKeyVisible('')
        setVariableDepositVisible('hidden')
        item.variableDepositQty = variableDepositQty

        setBasket(
            basket.map(modifyItem =>
                modifyItem.key === item.key ?
                    { ...item, quantity: modifyItem.quantity + 1
                    }
                    : modifyItem
            )
        )
    }



    return (
        <>
            {basket === null || typeof basket === 'undefined' ? null : basket.map((item) =>
            
                <>
                {item.invisible?null:
                <div className="displayItemsContent" key={item.key}>
                    {item.type.default.toLowerCase() === typeOfItem.default.toLowerCase() && item.display && translate(item.itemName).toLowerCase().includes(searchText.toLowerCase()) ?
                        <>
                            <div className="displayItemsRow1">
                                <div className='itemName'>{translate(item.itemName)}</div>
                                {item.soldOut === false &&
                                    (item.stockThreshold < item.availableInStock || !isPositiveInteger(item.availableInStock)) ? <>
                                    <div className='itemPrice'>{item.price.toFixed(2)} CHF</div>

                                </>
                                    :
                                    <>
                                        {item.quantity === undefined ? item.quantity = 0 : null}
                                        <div className='itemSoldout'>{traductionMap.outOfStock}</div>
                                    </>
                                }
                                {plusButtonClicked ?
                                    setPlusButtonClicked(false) : null}

                            </div>

                            <div className="displayItemsRow2">
                                <div>{translate(item.description)}</div>
                                <div className='listSubItemContent'>
                                    {item.selectedSubItems?
                                        item.selectedSubItems.map((subItem)=>(
                                        <div >
                                            {translate(subItem)}
                                        </div>
                                        )):null
                                    }
                                </div>
                                <div className='listSubItemContent' >
                                    {item.variableDepositQty > 0 ?
                                        item.variableDepositQty + ' ' + translate(item.variableDepositName)
                                        :null
                                    }
                                </div>
                                {item.subItems?
                                    item.subItems[0] !== "" && keyVisible === item.key && item.subItems[0] !== undefined ?
                                    <div className='actionSheetContainer' style={{ visibility: visibility }} onClick={setVisibility.bind(this, 'hidden')}>
                                        <div className='actionSheetTitle'>{traductionMap.trimming}</div>
                                        <br />
                                        <p></p>
                                        <div style={{ overflow: 'auto', maxHeight: '65%' }}>
                                            {item.subItems.map((option) => (
                                                <div className='actionSheet' key={translate(option)} onClick={selectSubItem.bind(this, option, item)}  >{translate(option)}</div>
                                            ))}
                                        </div>
                                    </div>
                                    : null:null
                                }
                                {item.variableDepositPrice > 0 && keyVisible === item.key ?
                                    <div className='actionSheetContainer' style={{ visibility: variableDepositVisible }} >
                                        <div className='actionSheetTitle'>{traductionMap.selectNumber} {translate(item.variableDepositName )}</div>
                                        <div className='actionSheet2'>
                                            <button onClick={() =>
                                                variableDepositQty > 0 ? setVariableDepositQty(variableDepositQty-1) : setBasketReady(true)

                                            }>- </button>

                                            <span> {variableDepositQty} </span>

                                            <button onClick={() =>
                                                variableDepositQty< 15 ? setVariableDepositQty(variableDepositQty+1) : setBasketReady(true)
                                            }> +</button>
                                        
                                        </div>
                                        
                                        <Button onClick={()=>confirmVariableDepositNumber(item)}>{traductionMap.confirm}</Button>
                                    </div>
                                    : null}
                            </div>
                           
                            <div className="displayItemsRow3">
                                {item.attributs["glutenFree"] ? <img src={IconGlutenFree} alt="Sans gluten" /> : null}
                                {item.attributs["vegetarian"] ? <img src={IconVegetarian} alt="Végétarien" /> : null}
                                {item.attributs["lactoseFree"] ? <img src={IconLactoseFree} alt="sans Lactose" /> : null}
                                {item.attributs["sixteenMin"] ? <img src={IconSixteen} alt="Interdit aux moins de 16 ans" /> : null}
                                {item.attributs["eighteenMin"] ? <img src={IconEighteen} alt="Interdit aux moins de 18 ans" /> : null}
                                {item.soldOut === false &&
                                    (item.stockThreshold < item.availableInStock || !isPositiveInteger(item.availableInStock)) ?
                                <div className='itemRemoveOrAdd'>
                                    <button onClick={() =>
                                        item.quantity > 0 ? reduceQuantity(item) : setBasketReady(true)

                                    }>- </button>

                                    <span> {item.quantity === undefined ? item.quantity = 0 : item.quantity} </span>

                                    <button onClick={() =>
                                        item.quantity < 15 && (item.quantity < item.availableInStock-item.stockThreshold || !isPositiveInteger(item.stockThreshold))? increaseQuantity(item) : setBasketReady(true)
                                    }> +</button>
                                </div>
                                :
                                null}
                            </div>
                            <div className="itemSeparator">
                                <div></div>
                            </div>
                        </>
                        : null}
                </div>
                }
            </>
            )}
        </>
    );
}
export default DisplayItems;
