import React from 'react';
import { Search } from '@mui/icons-material';

const SearchBar = ({
    placeholder,
    onChange
}) => {
    return (
        <div className="searchBar">
            <input placeholder={placeholder} onChange={onChange} />
            <i><Search /></i>
        </div>
    );
};

export default SearchBar;
