import React, { useEffect, useState } from "react";
import { traductionMap } from "../componants/Traduction";
import { GetItemsByBooth, GetOrdersByBooth, GetOrdersByEvent, getAnyDocById } from "../repostirories/firebaseFunctions";
import { translate } from "../tools/Tools";
import { generateKey } from "../tools/utils";
import { loadGatewayPOS } from "../tools/payrexx";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { addOrder, getSumUpTransaction, getSumUpTransactions } from "../repostirories/serverFunctions";
import { talkToDrawer } from "./POSControls";
import { HomeRounded } from "@mui/icons-material";
import { orange, yellow } from "@mui/material/colors";
import LoadingScreen from "../componants/LoadingScreen";

// graphics settings
let borderWidth = "5px"
let fontSize1 = "24px"
// css style

const styles = {
    container: {
        display: "flex",
        flexDirection: 'column',
        height: "100vh",
        width: "100vw",
        position: "fixed",
        backgroundColor: "white",
        fontSize: "18px",
        zIndex: 10000000
      },
  app: {
    display: "flex",
    height: "90%",
    width: "100%",
    backgroundColor: "white",
    fontSize: "18px",
  },

  leftSection: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingRight: "10px",
    borderRight: borderWidth + " solid orange",
    overflowY: "auto",
  },

  rightSection: {
    marginTop: "12px",
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "10px",
    overflowY: "auto"
  },
  
};

const POSReconciliation = () => {
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [cart, setCart] = useState([]);
    const boothPath = localStorage.getItem('userPath')
    const [products, setProducts] = useState([])
    const [booth, setBooth] = useState([])
    const itemPath = localStorage.getItem('userPath') + 'items'
    const history = useHistory();
    const[isLoading, setIsLoading] = useState(false)
    console.log(localStorage.getItem('userPath'))
    // set order path
    const boothId = itemPath.substring(itemPath.indexOf('/items') - 20, itemPath.indexOf('/items'))
    const commandPath = '/' + itemPath.substring(0, itemPath.indexOf('/items') - 20) + boothId + '/commands/'
    const [orders, setOrders] = useState([])
    const [sumUpTrans, setSumUpTrans] = useState([])
    var postData = {}
    useEffect(async ()=>{
      
      
      // Appel des fonctions pour obtenir le jeton et la liste des transactions
      const main = async () => {
        var tempOrders = await GetOrdersByBooth(commandPath)

        
        
        const filteredOrders = tempOrders.filter(order => order.paymentMean === "sumupCard");
        console.log(filteredOrders)
        //gat start date and end date
        filteredOrders.sort((a, b) => new Date(a.isoDate) - new Date(b.isoDate));
        var startDate = filteredOrders[0]?.isoDate || null;
        var endDate = filteredOrders[filteredOrders.length - 1]?.isoDate || null;
        console.log(startDate, endDate)
        var matchingTransactions = []
        
        try {
          startDate = new Date(startDate).toISOString().slice(0, 10);
          endDate = new Date(endDate).toISOString().slice(0, 10);
          
          console.log(startDate, endDate)
          postData = {startDate: startDate, endDate: endDate}
          const transactions = await getSumUpTransactions(postData);
          console.log(transactions)
          var sumUpTransactions = JSON.parse(transactions)
        } catch (error) {
          console.error('Une erreur s\'est produite :', error);
        }
        filteredOrders.forEach(order => {
          const orderIsoDate = new Date(order.isoDate);
          const orderTotalPrice = order.totalPrice;
      
          // Initialize variables to keep track of the closest transaction
          let closestTransaction = null;
          let closestTimestampDifference = Infinity;
          // get only the orders with the same price
          var filteredSumUpTrans = sumUpTransactions.filter(sumUpOrder => sumUpOrder.amount === orderTotalPrice)
          console.log(sumUpTransactions)
          console.log(filteredSumUpTrans)
          // Loop through the sumUpTransactions to find the closest match
          filteredSumUpTrans.forEach(transaction => {
              const transactionTimestamp = new Date(transaction.timestamp);
              const transactionAmount = transaction.amount;
      
              // Calculate the absolute time difference in milliseconds
              const timestampDifference = Math.abs(transactionTimestamp - orderIsoDate);
      
              // Compare the amount and find the closest transaction
              if (timestampDifference < closestTimestampDifference && transactionAmount === orderTotalPrice) {
                  closestTransaction = transaction;
                  closestTimestampDifference = timestampDifference;
              }
          });
      
          if (closestTransaction) {
              sumUpTransactions=sumUpTransactions.filter(trans => trans.id !== closestTransaction.id)
              matchingTransactions.push({ orderId: order.id, transactionId: closestTransaction.id, amount: closestTransaction.amount });
          }
      });
      
      console.log("Matching Transactions:", matchingTransactions);
      };
      
      // Appel de la fonction principale
      main();

    }, [])

    return (
        <>
        {isLoading?
        <LoadingScreen></LoadingScreen>
        :<></>}
            <div style={styles.container}>

                <div style={styles.app}>
                    <div style={styles.leftSection}>
                        
                        
                    </div>
                    <div style={{ ...styles.rightSection }}>
                       
                    
                    </div>
                </div>
                
            </div>
            
        </>
    );
};

export default POSReconciliation;
