import React from 'react';
import IconBoothAdd from '../../ressources/boothAdd.png'
import IconBoothList from '../../ressources/boothList.png'
import IconEventConfig from '../../ressources/eventConfig.png'
import {useHistory} from "react-router-dom";
import { traductionMap } from '../../componants/Traduction';

function EventAdminConsole () {
    const history = useHistory();
    const addBooth = () => {
        localStorage.setItem("boothPath", "")
        history.push("/AddBooth")
    }
    const displayBoothList = () => {
        localStorage.setItem("boothPath", localStorage.getItem("userPath") + "menus")
        history.push("/BoothList")
    }
    const updateEvent = () => {
        history.push("/UpdateEvent")
    }
    
    return (
        <div>
            <div className='bricksContainer'>
                <div onClick={addBooth}>
                    <p>{traductionMap.addBooth}</p>
                    <img  src={IconBoothAdd} alt="Ajouter un stand"></img>
                </div>
                <div onClick={displayBoothList}>
                    <p>{traductionMap.boothList}</p>
                    <img src={IconBoothList} alt="Liste des stands"></img>
                </div>
                <div onClick={updateEvent}>
                    <p>{traductionMap.eventSettings}</p>
                    <img  src={IconEventConfig} alt="Paramètres de l'événement"></img>
                </div>
                
            </div>
        </div>
    );
}
export default EventAdminConsole;