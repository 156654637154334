import QrCodeReader from '../../componants/QrCodeReader';
import React from 'react';

const QRScanner = () => {
    return (
        <div className='qrScanner'>
            <QrCodeReader />
        </div>
    );
};

export default QRScanner;
