import React, { useEffect, useState } from "react";
import { traductionMap } from "../componants/Traduction";
import { GetItemsByBooth, getAnyDocById } from "../repostirories/firebaseFunctions";
import { addZero, translate } from "../tools/Tools";
import { generateKey } from "../tools/utils";
import { loadGatewayPOS } from "../tools/payrexx";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { addOrder, changeStatus } from "../repostirories/serverFunctions";

// graphics settings
let borderWidth = "5px"
let fontSize1 = "24px"
// css style

const styles = {
  app: {
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100vh",
    width: "100vw",
    position: "fixed",
    backgroundColor: "white",
    fontSize: "20vw",
    zIndex: 10000001
  },
};


const POSAmount =  () => {
    
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const total = queryParams.get('total');
    const id = queryParams.get('id')
    const collectionPath = queryParams.get('collectionPath')
    var today = new Date();
    var dateCommand = today.getFullYear() + "/" + addZero((today.getMonth() + 1),2) + "/" + addZero(today.getDate(),2) + " " + addZero(today.getHours(),2) + ":" + addZero(today.getMinutes(),2);
            
    var postData = {orderPath: collectionPath, orderId: id, dateHour: dateCommand}
    var order = JSON.parse(localStorage.getItem('orderPOS'))
    console.log(order)
    useEffect(async ()=>{
        await addOrder(order).then(()=>{
            changeStatus(postData)
            history.push('/POSMain')
        }) 
    })          
    


    return (
        <div style={styles.app} onClick={()=> history.push('/POSMain')}>
          {total}
        </div>
    );
};

export default POSAmount;
