import React, { useState, useEffect } from 'react';
import '../../styles/views/boothAdminViews/Settings.css'
import firebase from "../../firestore";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { traductionMap } from '../../componants/Traduction';
import { useHistory } from 'react-router-dom';
import { getPosition } from '../../tools/Tools';

var db = firebase.firestore();

function Settings() {

    const [showingAlertSuccess, setAlertSuccess] = useState(false);
    const [showingAlertFailure, setAlertFailure] = useState(false);
    const [showingCommandAlert, setShowingCommandAlert] = useState(false);
    const [open, setOpen] = useState(true);
    const commandPath = localStorage.getItem('userPath')
    const userPath = localStorage.getItem("userPath")
    const userRole = localStorage.getItem("role")
    const collectionPath = userPath.substring(0, userPath.indexOf('/menus') + 7)
    const boothId = userPath.substring(userPath.indexOf('/menus') + 7, 54)
    const history = useHistory();

    const docRef = doc(db, collectionPath, boothId);
    useEffect(() => {
        async function setOpenClose() {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setOpen(docSnap.data()["open"]);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

        }

        if (userPath !== null) {
            setOpenClose();
        }
    });

    const openCloseBooth = async () => {
        console.log()
        if (open) {
            setOpen(false)

            setDoc(docRef, { open: false }, { merge: true })

        } else {
            setOpen(true)

            setDoc(docRef, { open: true }, { merge: true })

        }
    }

    const handleClickShowSuccess = () => {
        setAlertSuccess(true);

        setTimeout(() => setAlertSuccess(false), 2500);
    }

    const handleClickShowFailure = () => {
        setAlertFailure(true);

        setTimeout(() => setAlertFailure(false), 2500);
    }

    const resetCommandNumber = async () => {

        try {
            await db.collection(userPath + "commands").doc("command").set({
                lastCommand: 0,
            });

            handleClickShowSuccess();
        } catch (e) {
            console.log(e);
            handleClickShowFailure();
        }
    }

    const createQR = () => {
        var ref = window.location.href
        localStorage.setItem(
            'urlItems', ref.substring(0, getPosition(ref, '/', 3)) + 
            '/Items/' +
            localStorage.getItem("userPath") +
            'items')
        history.push('/QRCode')

    }

    const archiveCommand = () => {

        db.collection(commandPath).onSnapshot(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                if (doc.exists) {
                    //must not take the "commands" document wich is in the same collection
                    if (doc.id.length > 10) {
                        if (doc.data()['commandStatus'] === 3) {
                            db.collection(commandPath).doc(doc.id).set({
                                commandStatus: 5,
                            }, { merge: true })
                        }
                    }
                }
                else {
                    console.log("No such document!")
                }
            })
        });

        setShowingCommandAlert(true);

        setTimeout(() => setShowingCommandAlert(false), 2500);

    }


    return (
        <div>
            {userRole === "BoothAdmin" || userRole === "EventAdmin" || userRole === "SuperAdmin" ?
                <div>
                    <p className="boothState">{traductionMap.boothIs}
                        {open ?
                            <span className="open" onClick={openCloseBooth}>{traductionMap.opened}</span>
                            :
                            <span className="close" onClick={openCloseBooth}>{traductionMap.closed}</span>}
                    </p>
                </div>
                :
                null}
                <div className="resetCommand" >
                    <p onClick={resetCommandNumber}>{traductionMap.resetOrderNr}</p>
                </div>
                <div className="resetCommand" >
                    <p onClick={createQR}>{traductionMap.createQR}</p>
                </div>
            
            {userRole === "BoothAdmin" || userRole === "EventAdmin" || userRole === "SuperAdmin" ?
                <div className="buttonArchiveCommand">
                    <p onClick={archiveCommand}>{traductionMap.archiveOrders}</p>
                </div>
                :
                null}

            <div className={`alert alert-success ${showingAlertSuccess ? 'alert-shown' : 'alert-hidden'}`}>N° de commande réinitialisé !</div>
            <div className={`alert alert-success ${showingAlertFailure ? 'alert-shown-failure' : 'alert-hidden-failure'}`}>Erreur, quelque chose s'est mal passé !</div>
            <div className={`alert alert-success ${showingCommandAlert ? 'alert-shown' : 'alert-hidden'}`}>Commandes archivées</div>
        </div>
    );
}
export default Settings;
