import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import React from 'react'

import EventView from '../views/consumerViews/EventView';
import MenuView from '../views/consumerViews/MenuView';
import ItemView from '../views/consumerViews/ItemView';
import Login from '../views/authentificationViews/LoginView';
import Home from '../views/consumerViews/Home';
import ItemListView from '../views/boothAdminViews/ItemListView';
import CommandListView from '../views/staffViews/CommandListView';
import QrCodeView from '../views/boothAdminViews/QrCodeView';
import BasketView from "../views/consumerViews/BasketView";
import CommandedView from "../views/consumerViews/Commanded";
import ItemListViewStaff from "../views/staffViews/ItemListViewStaff";
import Settings from "../views/boothAdminViews/Settings";
import Statistics from "../views/boothAdminViews/Statistics";
import About from "../views/consumerViews/About";
import UserGuide from "../views/consumerViews/UserGuide";
import Receipts from "../views/boothAdminViews/Receipts";
import CommandScanner from "../views/staffViews/CommandScanner";
import AdminConsole from "../views/superAdminViews/AdminConsole";
import AddEventView from "../views/superAdminViews/AddUpdateEvent";
import EventListView from "../views/superAdminViews/EventList";
import EventAdminConsole from "../views/eventAdminViews/EventAdminConsole";
import AddUpdateBooth from "../views/eventAdminViews/AddUpdateBooth"
import BoothList from '../views/eventAdminViews/BoothList'
import AddUser from '../views/superAdminViews/AddUser'
import UpdateEvent from "../views/eventAdminViews/UpdateEvent";
import BoothAdminConsole from "../views/boothAdminViews/BoothAdminConsole";
import UpdateBooth from "../views/boothAdminViews/UpdateBooth";
import TypeManagement from '../views/boothAdminViews/TypeManagement'
import NavConsumer from "./NavConsumer";
import useInitUser from "../hooks/useInitUser";
import NavAdmin from "./NavAdmin";
import QRScanner from "../views/consumerViews/QRScanner";
import ConsignHandler from "../views/staffViews/ConsignHandler";
import MyOrders from "../views/consumerViews/MyOrders";
import AddSingleProduct from "../views/boothAdminViews/AddSingleProduct";
import PartialOrderView from "../views/consumerViews/postponedPayment/PartialOrderView";
import FullOrderView from "../views/consumerViews/postponedPayment/FullOrderView";
import ScanPaymentQR from "../views/consumerViews/postponedPayment/ScanPaymentQR";
import  SelectBill  from "../views/administratives/SelectBill";
import DueAmount from "../views/administratives/DueAmount";
import ChoosePm from "../views/consumerViews/ChoosePm";
import POSMain from "../POS/POSMain";
import POSAmount from "../POS/POSAmount";
import POSReconciliation from "../POS/POSReconciliation";


// import SmsStressTest from "../tests/SmsStressTest";

const mode = localStorage.getItem('mode')

function Routes() {
  const [userRole] = useInitUser();

  return (
    <div style={{ display: "flex" }}>

      <BrowserRouter>
        {userRole === "undefined" || userRole === null || mode === 'client' ?
          <NavConsumer /> : <NavAdmin />}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/Events">
            <EventView />
          </Route>
          <Route relative path="/Menus">
            <MenuView />
          </Route>
          <Route relative path="/Items">
            <ItemView />
          </Route>
          <Route path="/QRScanner">
            <QRScanner />
          </Route>
          <Route path="/Login">
            <Login />
          </Route>
          <Route path="/ItemListView">
            <ItemListView />
          </Route>
          <Route path="/ItemListViewStaff">
            <ItemListViewStaff />
          </Route>
          <Route path="/Basket">
            <BasketView />
          </Route>
          <Route path="/Commands">
            <CommandListView />
          </Route>
          <Route path="/QRCode">
            <QrCodeView />
          </Route>
          <Route path="/Commanded">
            <CommandedView />
          </Route>
          <Route path="/Settings">
            <Settings />
          </Route>
          <Route path="/Statistics">
            <Statistics />
          </Route>
          <Route path="/About">
            <About />
          </Route>
          <Route path="/UserGuide">
            <UserGuide />
          </Route>
          <Route path="/Receipts">
            <Receipts />
          </Route>
          <Route path="/CommandScanner">
            <CommandScanner />
          </Route>
          <Route path="/AdminConsole">
            <AdminConsole />
          </Route>
          <Route path="/AddEvent">
            <AddEventView />
          </Route>
          <Route path="/EventList">
            <EventListView />
          </Route>
          <Route path="/EventAdminConsole">
            <EventAdminConsole />
          </Route>
          <Route path="/AddBooth">
            <AddUpdateBooth />
          </Route>
          <Route path="/BoothList">
            <BoothList />
          </Route>
          <Route path="/AddUser">
            <AddUser />
          </Route>
          <Route path="/UpdateEvent">
            <UpdateEvent />
          </Route>
          <Route path="/BoothAdminConsole">
            <BoothAdminConsole />
          </Route>
          <Route path="/UpdateBooth">
            <UpdateBooth />
          </Route>
          <Route path="/TypeManagement">
            <TypeManagement />
          </Route>
          <Route path="/ConsignHandler">
            <ConsignHandler />
          </Route>
          <Route path="/MyOrders">
            <MyOrders/>
          </Route>
          <Route path="/AddSingleProduct">
            <AddSingleProduct/>
          </Route>
          <Route path="/PartialOrderView">
            <PartialOrderView/>
          </Route>
          <Route path="/FullOrderView">
            <FullOrderView/>
          </Route>
          <Route path="/ScanPaymentQR">
            <ScanPaymentQR/>
          </Route>
          <Route path="/SelectBill">
            <SelectBill/>
          </Route>
          <Route path="/DueAmount">
            <DueAmount/>
          </Route>
          <Route path="/ChoosePm">
            <ChoosePm/>
          </Route>
          <Route path="/POSMain">
            <POSMain/>
          </Route>
          <Route path="/POSAmount">
            <POSAmount/>
          </Route>
          <Route path="/POSReconciliation">
            <POSReconciliation/>
          </Route>
          
          
          
          {/* <Route path="/SmsStressTest">
          <SmsStressTest/>
      </Route> */}







        </Switch>

      </BrowserRouter>

    </div>


  );
}
export {Routes as default, mode}
