import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss'
import App from './App';
import reportWebVitals from './tools/reportWebVitals';

//redirect to https
if (window.location.protocol === 'http:' && !window.location.href.includes("localhost")) {

  console.log("you are accessing us via "
    + "an insecure protocol (HTTP). "
    + "Redirecting you to HTTPS.");

  window.location.href =
    window.location.href.replace(
      'http:', 'https:');
}

//Set the list of items read by the qrcode
if (!window.location.href.includes("Basket") && !window.location.href.includes("Commanded") && !window.location.href.includes("ScanPaymentQR") && !window.location.href.includes("ChoosePm")){
  localStorage.setItem("itemPath", window.location.href.substring(window.location.href.indexOf('events/')));
}



ReactDOM.render(
  <React.StrictMode>
    <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


