import zIndex from '@mui/material/styles/zIndex';
import React from 'react';
import ReactLoading from "react-loading";

function LoadingScreen () {
    var loadWidth = 120
    var loadHeight = 120
    // var totalWidth = document.getElementById('loadingDiv').clientWidth;
    return (
        <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)', zIndex:'10000000000'}} >
            <ReactLoading type="spin" color="orange"
            height={loadHeight} width={loadWidth}/>
        </div>
    )
}
export default LoadingScreen;