import logo from '../../ressources/Logo.png';
import { useHistory } from "react-router-dom";
import Button from '../../componants/Button';
import { auth } from '../../firestore.js';
import { useState } from 'react';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import { traductionMap } from '../../componants/Traduction';
import React from 'react';
import { mode } from '../../Router/Routes';

function Home() {
  let userRole = localStorage.getItem("role");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const goToEvent = () => {
    history.push("/Events")
  }

  const goToScanner = () => {
    history.push("/QRScanner")
  }

  const goToProducts = () => {
    history.push("/ItemListViewStaff")
  }

  const goToOrders = () => {
    history.push("/Commands")
  }

  auth.onAuthStateChanged(user => {
    if (user) {
      setIsLoading(false);
    }
  })
  const initClientMode = () => {
    localStorage.setItem('mode', 'client')
    window.location.reload()
    
  }

  return (
    <div className="home">
      <div className="homeContent">
        <img src={logo} className="App-logo" alt="logo" />
        {userRole === "undefined" || userRole === null || mode === 'client' ?
          <>
            <p>
              <span>{traductionMap.avoid}</span> {traductionMap.queue} !
            </p>
            <p>{traductionMap.ordAndPay} !</p>
            <div className="centerText">
              <Button onClick={goToEvent}>{traductionMap.makeOrder}</Button>
              <p className='textSeparator'>{traductionMap.or}</p>
              <div className="scanContainer">
                <QrCodeScannerRoundedIcon className='qrIcon' />
                <p className='scannerLink' onClick={goToScanner}>{traductionMap.scanAndAccess}</p>
              </div>

            </div>
          </>
          :
          <>
            <p>
              {traductionMap.hello} {isLoading ? "" : auth.currentUser.email.substring(0, auth.currentUser.email.indexOf('@'))}
            </p>
            <p>{traductionMap.manageOrdersAndProducts} !</p>
            <div className="adminButtons">
              <Button onClick={goToOrders}>{traductionMap.manageOrders}</Button>
              <Button onClick={goToProducts}>{traductionMap.manageProduct}</Button>
              <Button onClick={initClientMode}>{traductionMap.customerMode}</Button>
            </div>
          </>
        }

      </div>
    </div>
  );
}

export default Home;
