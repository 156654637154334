import React, { useState } from "react";
import QrReader from "react-qr-reader";
import firebase from '../../firestore'
import validateIcon from '../../ressources/validate2.png';
import crossIcon from '../../ressources/cross.png'
import '../../styles/views/staffViews/CommandScanner.css'
import { traductionMap } from "../../componants/Traduction";

var db = firebase.firestore();
function CommandScanner() {

  const commandPath = localStorage.getItem('userPath') + 'commands'

  const [command, setCommand] = useState(null)
  const [isValid, setValid] = useState("unknown")

  const handleScan = async (data) => {
    if (data) {
      var docRef = db.collection(commandPath).doc(data);
      await docRef.get().then((doc) => {
        if (doc.exists) {
          setCommand(doc.data())
          if (doc.data().commandStatus <= 2) {
            setValid("valid")
            setTimeout(function () {
              setValid("unknown");
            }, 5000);
          }
          else {
            setValid("invalid")
            setTimeout(function () {
              setValid("unknown");
            }, 5000);
          }
        }
        else {
          setValid("invalid");
          setCommand(null)
          setTimeout(function () {
            setValid("unknown");
          }, 5000);

        }

      })
    }
  }
  const handleError = (err) => {
    console.error(err);
  }


  return (
    <div className="App" style={{ width: "100%", extAlign: "center", fontSize: "34px", fontWeight: "bolder" }}>
      <p></p>
      <br></br>
      {isValid === "valid" ?
        <>
          <div className="valid">
            <div>
            </div>
          </div>
          <div className="validIcon">
            <img src={validateIcon} className="App-icon" alt="validate" />
          </div></> :
        isValid === "invalid" ?
          <>
            <div className="invalid">
              <div>
              </div>
            </div>
            <div className="invalidIcon">
              <img src={crossIcon} className="App-icon" alt="cross" />
            </div></> : null

      }
      <div style={{ marginBottom: "32px" }}>
        <QrReader
          delay={100}
          onError={handleError}
          onScan={handleScan}
          style={{  b2order: "orange", borderStyle: "double", zIndex: "1", width: '100%' }}
        />
      </div>
      {command !== null && command !== "undefined" ?
        command.commandStatus === 3 || command.commandStatus >= 5 ?
          <div>{traductionMap.orderIssued} {command.number}</div> :
          command.commandStatus === 4 ?
            <div>{traductionMap.orderCancelled } {command.number}</div> :
            <div>{traductionMap.orderValid} {command.number}</div> :
        <div>{traductionMap.noOrder}</div>

      }
    </div>

  );
}

export default CommandScanner;
