import './styles/App.css';
import React, { useState, useEffect, useRef } from 'react';
import Routes from './Router/Routes.js';
import { currentPathContext } from './context/CurrentPathContext';
import { BasketContext } from "./context/Basket";
import { availableLanguages, setLanguage } from './componants/Traduction.js';
import Notification from './componants/Notification.js';
import { generateKey } from './tools/utils.js';


const App = () => {
  //Set the language
  if(localStorage.getItem('language'))
    setLanguage(localStorage.getItem('language')) //get the language setted manually
  else if(availableLanguages[navigator.language.substring(0,2)]){
    setLanguage(navigator.language.substring(0,2)) //get the browser language
    localStorage.setItem('language', navigator.language.substring(0,2))
  }
  else
  {
    setLanguage('fr') //set the default language (fr)
    localStorage.setItem('language', 'fr')
  }
  // set Consumer ID
  if(!localStorage.getItem('customerId'))
  {
    localStorage.setItem('customerId', generateKey())
  }



  const [eventPath, updateEventPath] = useState("/events/")
  function setEventPath(selectedPath) {
    updateEventPath(selectedPath);
  }
  //get the path stored in local
  const [menuPath, updateMenuPath] = useState(localStorage.getItem("menuPath"))
  function setMenuPath(selectedPath) {
    updateMenuPath(selectedPath);
  }
  //get the path stored in local
  const [itemPath, updateItemPath] = useState(localStorage.getItem("itemPath"))
  function setItemPath(selectedPath) {
    updateItemPath(selectedPath);
  }

  const prevItemPath = usePrevious(itemPath);

  //get the content of the basket
  const [basket, setBasket] = useState([]);

  //get the command number
  const [commandNumberContext, setCommandNumberContext] = useState(0);


  return (
    <BasketContext.Provider value={{ basket, setBasket }}>
      <currentPathContext.Provider value={{ eventPath, setEventPath, menuPath, setMenuPath, itemPath, setItemPath, prevItemPath, commandNumberContext, setCommandNumberContext }}>
        <div style={{marginBottom: '100px'}}>
        <Routes />
        <Notification/>
        </div>
      </currentPathContext.Provider>
    </BasketContext.Provider>
  )
}

export default App;

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
