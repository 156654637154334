import React, { useEffect, useState } from "react";
import { traductionMap } from "../componants/Traduction";
import { GetItemsByBooth, getAnyDocById, openCashDrawer } from "../repostirories/firebaseFunctions";
import { addZero, translate } from "../tools/Tools";
import { generateKey } from "../tools/utils";
import { loadGatewayPOS } from "../tools/payrexx";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { addOrder, changeStatus } from "../repostirories/serverFunctions";
import { talkToDrawer } from "./POSControls";
import { HomeRounded } from "@mui/icons-material";
import { orange, yellow } from "@mui/material/colors";
import LoadingScreen from "../componants/LoadingScreen";

// graphics settings
let borderWidth = "5px"
let fontSize1 = "24px"
// css style

const styles = {
    container: {
        display: "flex",
        flexDirection: 'column',
        height: "100vh",
        width: "100vw",
        position: "fixed",
        backgroundColor: "white",
        fontSize: "18px",
        zIndex: 10000000
      },
  app: {
    display: "flex",
    height: "90%",
    width: "100%",
    backgroundColor: "white",
    fontSize: "18px",
  },

  leftSection: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingRight: "10px",
    borderRight: borderWidth + " solid orange",
    overflowY: "auto",
  },
  categoriesContainer: {
    margin: "20px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "white",
    paddingRight: "10px",
    borderBottom: borderWidth + " solid orange",
    borderRadius: "10px"
  },
  categories: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap", // Allow categories to wrap to the next line
    marginTop: "25px",
    width: "100%",
    borderRadius: "10px", // Ensure categories take up the full width
    alignItems: "bottom"
  },
  categoryButton: {
    marginRight: "10px",
    padding: "10px 15px",
    backgroundColor: "lightgray",
    border: "none",
    cursor: "pointer",
    fontSize: "24px",
    borderRadius: "10px"
  },
  selectedCategoryButton: {
    backgroundColor: "orange",
    color: "white"
  },
  productsList: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    width:"95%",
    margin: "20px",
  },
  productTile: {
    width: "23%",
    height: "24vh",
    marginBottom: '10px',
    border: "1px solid lightgray",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "5px",
    margin: "5px",
    cursor: "pointer",
    fontSize: fontSize1,
    borderRadius: "10px",
    fontWeight: "bold",
  },
  quantityWrapper: {
    display: "flex",
    alignItems: "center",
    color: "white",
    backgroundColor: "orange",
    padding: "2px 5px",
    borderRadius: "3px",
    marginRight: "5px",
    marginLeft: "5px",
    borderRadius: "10px"
  },

  rightSection: {
    marginTop: "12px",
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "10px",
    overflowY: "auto"
  },
  cartSection: {
    marginTop: "12px",
    width: "100%",
    height: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "10px",
    overflowY: "auto"
  },
  cartItem: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "5px 10px",
    fontSize: fontSize1,
  },
  cartItemQuantity: {
    display: "flex",
    alignItems: "center"
  },
  cartItemPrice: {
    marginLeft: "auto",
    marginRight: "14px"
  },
  totalSection: {
    width: "100%",
    height: "35%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: borderWidth + " solid orange"
  },
  total: {
    display: "flex",
    fontWeight: "bold",
    marginBottom: "10px",
    margin: "5px",
    textAlign: "right",
    width: "100%",
    fontSize: "30px" // Adjust font size
  },
  totalTitle: {
    marginLeft: "12px"
  },
  price: {
    marginLeft: "auto",
    marginRight: "20px",
    textAlign: "right",
  },
  paymentButtons: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    textAlign: "center",
    width: "90%"
  },
  paymentButton: {
    alignSelf: "left",
    margin: "5px",
    border: "none",
    cursor: "pointer",
    borderRadius: "3px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "30%", // Adjust width
    textAlign: "center",
    fontSize: "42px", // Adjust font size
    lineHeight: "1.2",
    borderRadius: "10px" // Adjust line height
  },
  cashButton: {
    backgroundColor: "gray",
    color: "white",
    width: "100%"
  },
  cardButton: {
    backgroundColor: "orange",
    color: "white",
    width: "100%"
  },
  twintButton: {
    backgroundColor: "black",
    color: "white",
    width: "90%"
  },
  deleteCartButton: {
    alignSelf: "flex-end",
    marginRight: "10px",
    marginBottom: "10px",
    border: "none",
    cursor: "pointer",
    backgroundColor: "orange",
    color: "white",
    borderRadius: "3px",
    padding: "5px 10px",
    fontSize: "18px",
  },
  removeItemButton: {
    cursor: "pointer",
    color: "orange",
    marginRight: "12px"
  },
  bottomSection: {
    width: "100%",
    height: "10%",
    borderTop: borderWidth + " solid orange",
    justifyContent: 'center',
    textAlign: 'center',
    bottom: "10px",
  },
  
};

const POSMain = () => {
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [cart, setCart] = useState([]);
    const boothPath = localStorage.getItem('userPath')
    const [products, setProducts] = useState([])
    const [booth, setBooth] = useState([])
    const itemPath = localStorage.getItem('userPath') + 'items'
    const history = useHistory();
    const[isLoading, setIsLoading] = useState(false)

    //finish payrexx payment
    useEffect( () => {

        async function findGateway() {
           if(localStorage.getItem('paymentInProcess') === 'true'){
               var today = new Date();
               var dateCommand = today.getFullYear() + "/" + addZero((today.getMonth() + 1),2) + "/" + addZero(today.getDate(),2) + " " + addZero(today.getHours(),2) + ":" + addZero(today.getMinutes(),2);
               var postData = {orderPath: commandPath, orderId: localStorage.getItem('referenceId'), dateHour: dateCommand}
                       
                await changeStatus(postData).then(async (data)=>{
                    console.log(data)
                   localStorage.setItem('paymentInProcess', 'false')
               })
            }
        }
       
       findGateway();
       // eslint-disable-next-line 
   },[]);
    
    // set order path
    const boothId = itemPath.substring(itemPath.indexOf('/items') - 20, itemPath.indexOf('/items'))
    const collectionPath = itemPath.substring(0, itemPath.indexOf('/items') - 21) 
    const commandPath = '/' + itemPath.substring(0, itemPath.indexOf('/items') - 20) + boothId + '/commands/'
    var postData = {}
    useEffect(async ()=>{
        //set products
        var itemList = await GetItemsByBooth(boothPath)
        setProducts(itemList)

        // set booth
        var tempBooth = await getAnyDocById(collectionPath, boothId)
        setBooth(tempBooth)

    },[])


    const addToCart = (product) => {
        const existingCartItem = cart.find((item) => item.id === product.id);

        if (existingCartItem) {
        existingCartItem.quantity += 1;
        setCart([...cart]);
        } else {
        setCart([...cart, { ...product, quantity: 1 }]);
        }
    };

    const decreaseQuantity = (item) => {
        if (item.quantity > 0) {
        item.quantity -= 1;
        setCart([...cart]);
        }
    };

    const increaseQuantity = (item) => {
        item.quantity += 1;
        setCart([...cart]);
    };

    const calculateTotal = () => {
        return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    };

    const clearCart = () => {
        setCart([]);
    };

    const removeCartItem = (item) => {
        const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
        setCart(updatedCart);
    };

    const cashOrder = async () =>{
        
        if(calculateTotal() > 0){
            postData.orderedItems = cart
            postData.link = window.location.href
            postData.sqipRate = booth.sqipRate
            postData.officialName = booth.officialName
            postData.adress = booth.adress
            postData.locality = booth.locality
            postData.vatNumber = booth.vatNumber
            postData.path = collectionPath
            postData.referenceId = generateKey()
            var order = {
                orderedItems: postData.orderedItems,
                commandStatus: null,
                dateHour: null,
                number: null,
                paymentStatus: false,
                totalPrice: null,
                totalDeposit: 0,
                link: postData.link,
                sqipRate: Number(postData.sqipRate),
                payrexxFee: Number(null),
                section: postData.section,
                officialName: postData.officialName,
                adress: postData.adress,
                locality: postData.locality,
                transactionId: null,
                vatNumber: postData.vatNumber,
                paymentSqip: true,
                orderPath: commandPath,
                orderId: postData.referenceId,
                paymentMean: 'cash'
            }
            localStorage.setItem('orderPOS', JSON.stringify(order))
            openCashDrawer()
            history.push({pathname: '/POSAmount', search: `?total=${calculateTotal()}&id=${postData.referenceId}&collectionPath=${commandPath}`})
          
        }
    }

    const cardOrder = async () =>{
        if(calculateTotal() > 0){
            postData.orderedItems = cart
            postData.link = window.location.href
            postData.sqipRate = booth.sqipRate
            postData.officialName = booth.officialName
            postData.adress = booth.adress
            postData.locality = booth.locality
            postData.vatNumber = booth.vatNumber
            postData.path = collectionPath
            postData.referenceId = generateKey()
            var order = {
                orderedItems: postData.orderedItems,
                commandStatus: null,
                dateHour: null,
                number: null,
                paymentStatus: false,
                totalPrice: null,
                totalDeposit: 0,
                link: postData.link,
                sqipRate: Number(postData.sqipRate),
                payrexxFee: Number(null),
                section: postData.section,
                officialName: postData.officialName,
                adress: postData.adress,
                locality: postData.locality,
                transactionId: null,
                vatNumber: postData.vatNumber,
                paymentSqip: true,
                orderPath: commandPath,
                orderId: postData.referenceId,
                paymentMean: 'sumupCard'
            }
            localStorage.setItem('orderPOS', JSON.stringify(order))
            
            history.push({pathname: '/POSAmount', search: `?total=${calculateTotal()}&id=${postData.referenceId}&collectionPath=${commandPath}`})
            
        }
        
        
    }
    const twintOrder = () =>{
        postData.orderedItems = cart
        postData.link = window.location.href
        postData.sqipRate = booth.sqipRate
        postData.officialName = booth.officialName
        postData.adress = booth.adress
        postData.locality = booth.locality
        postData.vatNumber = booth.vatNumber
        postData.path = collectionPath
        var referenceId = generateKey()
        if(calculateTotal() > 0){
            localStorage.setItem('paymentInProcess', 'true')
            localStorage.setItem('referenceId', referenceId)
            setIsLoading(true)
            console.log(referenceId)
            loadGatewayPOS(calculateTotal(), 'CHF', referenceId, ['twint'], postData)
            
        }

        
    }

    const filteredProducts =
        selectedCategory === "All"
        ? products
        : products.filter((product) => product.type.default === selectedCategory);

    if(!localStorage.getItem('cashDrawerKey')){
      localStorage.setItem('cashDrawerKey', generateKey())
    }
    return (
        <>
        {isLoading?
        <LoadingScreen></LoadingScreen>
        :<></>}
            <div style={styles.container}>

                <div style={styles.app}>
                    <div style={styles.leftSection}>
                        <div style={styles.categoriesContainer}>
                        <div style={styles.categories}>
                            <button
                            style={{
                                ...styles.categoryButton,
                                ...(selectedCategory === "All"
                                ? styles.selectedCategoryButton
                                : {})
                            }}
                            onClick={() => setSelectedCategory("All")}
                            >
                            Tous
                            </button>
                            {[...new Set(products.map((product) => product.type.default))].map(
                            (category) => (
                                <div
                                key={category}
                                style={{
                                    ...styles.categoryButton,
                                    ...(selectedCategory === category
                                    ? styles.selectedCategoryButton
                                    : {}),
                                    marginBottom: "5px" // Add some margin between categories
                                }}
                                onClick={() => setSelectedCategory(category)}
                                >
                                {category}
                                </div>
                            )
                            )}
                        </div>
                        </div>
                        <div style={{ ...styles.productsList }}>
                        {filteredProducts.map((product) => (
                            <div
                            key={product.id}
                            style={styles.productTile}
                            onClick={() => addToCart(product)}
                            >
                            <span>{translate(product.itemName)}</span>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div style={{ ...styles.rightSection }}>
                        <button style={styles.deleteCartButton} onClick={clearCart}>
                        Annuler la commande
                        </button>
                        <div style={{ ...styles.cartSection }}>
                            {cart.map((item) => (
                            <div key={item.id} style={styles.cartItem}>
                                <div style={styles.cartItemQuantity}>
                                <div
                                    style={styles.quantityWrapper}
                                    onClick={() => decreaseQuantity(item)}
                                >
                                    −
                                </div>
                                <div>{item.quantity}</div>
                                <div
                                    style={styles.quantityWrapper}
                                    onClick={() => increaseQuantity(item)}
                                >
                                    +
                                </div>
                                </div>
                                <span style={{marginLeft:"12px"}}>{translate(item.itemName)}</span>
                                <span style={styles.cartItemPrice}>
                                {(item.price * item.quantity).toFixed(2)}
                                </span>
                                <div
                                style={styles.removeItemButton}
                                onClick={() => removeCartItem(item)}
                                >
                                &#10006;
                                </div>
                            </div>
                            ))}
                        </div>
                        <div style={styles.totalSection}>
                            <div style={styles.total}>
                                <div style={styles.totalTitle}>{traductionMap.total} </div>
                                <div style={styles.price}> {calculateTotal()} CHF</div>
                            </div>
                            <div style={styles.paymentButtons}>
                                <button style={{ ...styles.paymentButton, ...styles.cashButton }} onClick={()=>{cashOrder()}}>
                                    Cash
                                </button>
                                <button style={{ ...styles.paymentButton, ...styles.cardButton }} onClick={()=>{cardOrder()}}>
                                    Carte
                                </button>
                            </div>
                            <button style={{ ...styles.paymentButton, ...styles.twintButton }} onClick={()=>{twintOrder()}}>
                                Twint
                            </button>
                        </div>
                    
                    </div>
                </div>
                
                <div style={styles.bottomSection}>
                </div>
            </div>
            
        </>
    );
};

export default POSMain;
