import { addOrder, getGatewayServer, getTransactionServer, refundTransactionServer } from '../repostirories/serverFunctions'
import { getPosition } from './Tools'



const loadGateway = async (gPrice, gCurrency, gDocId, paymentMeanList, testMode, orderPath) => {
  const itemPath = localStorage.getItem('itemPath')
  // set order path
  const boothId = itemPath.substring(itemPath.indexOf('/items') - 20, itemPath.indexOf('/items'))
  const collectionPath = itemPath.substring(0, itemPath.indexOf('/items') - 20) + boothId + '/commands/'
  // generate order data
  // if payment is in test mode, put test psp
  var psp = 44
  if(testMode){
    psp = 36
    paymentMeanList = []
  }
  var order = {
    orderedItems: JSON.parse(localStorage.getItem('order')),
    commandStatus: null,
    dateHour: null,
    number: null,
    paymentStatus: false,
    totalPrice: null,
    totalDeposit: Number(localStorage.getItem('totalDeposit')),
    phoneNumber: localStorage.getItem("phoneNumber"),
    link: window.location.href.substring(0, window.location.href.indexOf('/Commanded')) + '/MyOrders',
    sqipRate: Number(localStorage.getItem('sqipRate')),
    payrexxFee: Number(null),
    section: localStorage.getItem('sectionName'),
    officialName: localStorage.getItem("officialName"),
    adress: localStorage.getItem("adress"),
    locality: localStorage.getItem("locality"),
    transactionId: null,
    vatNumber: localStorage.getItem('vatNumber'),
    SMSMessage: JSON.parse(localStorage.getItem('SMSMessage')),
    paymentSqip: true,
    userInfos: JSON.parse(localStorage.getItem('userInfos')),
    comment: localStorage.getItem('comment'),
    orderPath: collectionPath,
    bringToCompany: localStorage.getItem('bringToCompany'),
    noPreparation: localStorage.getItem('noPreparation'),
    paymentMean: 'payrexx',
    orderId: gDocId
  }
  
  console.log(psp)
  addOrder(order).then(()=>{
    const postData = {
      amount: gPrice.toFixed(2)*100,
      currency: gCurrency,
      pm: paymentMeanList,
      referenceId: gDocId,
      successRedirectUrl: window.location.href.substring(0, getPosition(window.location.href, "/", 3)) + '/Commanded',
      cancelRedirectUrl: window.location.href,
      failedRedirectUrl: window.location.href,
      path: localStorage.getItem('orderPath'),
      psp: [psp]
    };
    
    fetch('https://us-central1-sqipprod.cloudfunctions.net/createPayrexxGateway', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json()
      .then(data =>{
        console.log(data)
        if (!response.ok) {
          throw new Error('Error: ' + response.status);
        }
        localStorage.setItem('gateLink', data.link)
        localStorage.setItem('gateId', data.id)
        window.location.replace(data.link);
      })
    )
    
  })
  // generate payment data
  
  
  
  // var gate = response.data.data[0]
  // localStorage.setItem('gateLink', gate.link)
  // localStorage.setItem('gateId', gate.id)
  // window.location.replace(gate.link)
};

const loadGatewayPOS = async (gPrice, gCurrency, gDocId, paymentMeanList, orderData) => {
  const itemPath = localStorage.getItem('userPath') + 'items'
  console.log(paymentMeanList)
  // set order path
  const boothId = itemPath.substring(itemPath.indexOf('/items') - 20, itemPath.indexOf('/items'))
  const collectionPath = '/' + itemPath.substring(0, itemPath.indexOf('/items') - 20) + boothId + '/commands/'
  
  console.log(collectionPath)
  // generate order data
  var order = {
    orderedItems: orderData.orderedItems,
    commandStatus: null,
    dateHour: null,
    number: null,
    paymentStatus: false,
    totalPrice: null,
    totalDeposit: 0,
    link: orderData.link,
    sqipRate: Number(orderData.sqipRate),
    payrexxFee: Number(null),
    section: orderData.section,
    officialName: orderData.officialName,
    adress: orderData.adress,
    locality: orderData.locality,
    transactionId: null,
    vatNumber: orderData.vatNumber,
    paymentSqip: true,
    orderPath: collectionPath,
    orderId: gDocId,
    paymentMean: orderData.paymentMean,
    origin: 'POS'
  }

  addOrder(order).then(()=>{
    console.log("path: " + orderData.path)
    // generate payment data
    const postData = {
      amount: Number(gPrice).toFixed(2)*100,
      currency: gCurrency,
      pm: paymentMeanList,
      referenceId: gDocId,
      successRedirectUrl: orderData.link,
      cancelRedirectUrl: window.location.href,
      failedRedirectUrl: window.location.href,
      path: collectionPath.slice(0, -1),
      psp: [44]
    };
    
    console.log(postData.pm)
    fetch('https://us-central1-sqipprod.cloudfunctions.net/createPayrexxGateway', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json()
      .then(data =>{
        console.log(data)
        if (!response.ok) {
          throw new Error('Error: ' + response.status);
        }
        localStorage.setItem('gateLink', data.link)
        localStorage.setItem('gateId', data.id)
        window.location.replace(data.link);
      })
    )
    

  })
  
  // var gate = response.data.data[0]
  // localStorage.setItem('gateLink', gate.link)
  // localStorage.setItem('gateId', gate.id)
  // window.location.replace(gate.link)
};

const getGateway = async (id) => {
  var postdata = { id: id}
  const response =  await getGatewayServer(postdata)
  console.log(id)
  console.log(response)
  localStorage.setItem('gateStatus', id+'|'+response.status)
  return response
}; 
const getTransaction = async (id) =>{
  var postdata = { id: id}
  const response =  await getTransactionServer(postdata)
  const transaction = response
  console.log(transaction)
  return transaction
}

const refundTransaction = async (id, amount) => {
  var postdata = { id: id, amount: amount}
  const response = await refundTransactionServer(postdata)
  console.log(response)
  return response
}







export {loadGateway, getGateway, getTransaction, refundTransaction, loadGatewayPOS}
