import React from 'react';
import IconProductList from '../../ressources/productList.png'
import IconBoothConfig from '../../ressources/boothConfig.png'
import IconStatistics from '../../ressources/statistics.png'
import IconTypeList from '../../ressources/typesManagement.png'
import {useHistory} from "react-router-dom";
import { traductionMap } from '../../componants/Traduction';

function BoothAdminConsole () {
    const history = useHistory();
    const addBooth = () => {
        localStorage.setItem("boothPath", "")
        history.push("/UpdateBooth")
    }
    const displayProductList = () => {
        history.push("/ItemListView")
    }
    const displayTypesList = () => {
        history.push("/TypeManagement")
    }
    const displayStatistics = () => {
        history.push("/Statistics")
    }
    
    return (
        <div>
            <div className='bricksContainer'>
                <div onClick={addBooth}>
                    <p>{traductionMap.boothManagement}</p>
                    <img  src={IconBoothConfig} alt="Gestion du stand"></img>
                </div>
                <div onClick={displayProductList}>
                    <p>{traductionMap.productList}</p>
                    <img src={IconProductList} alt="Liste des Produits"></img>
                </div>
                <div onClick={displayTypesList}>
                    <p>{traductionMap.categoryManagement}</p>
                    <img src={IconTypeList} alt="Gestion des types"></img>
                </div>
                <div onClick={displayStatistics}>
                    <p>{traductionMap.statistics}</p>
                    <img  src={IconStatistics} alt="Statistiques"></img>
                </div>
                
            </div>
        </div>
    );
}
export default BoothAdminConsole;