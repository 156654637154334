import logo from '../../ressources/logoWhiteSquare.png';
import facebookLogo from '../../ressources/facebookOrange.png';
import instagramLogo from '../../ressources/instagramOrange.png';
import LinkedinLogo from '../../ressources/linkedinOrange.png';
import { traductionMap } from '../../componants/Traduction';
import React, { useEffect} from 'react'
import mainImage from '../../ressources/presentationImage.jpg'
import teamImage from '../../ressources/team.jpg'
import InstagramEmbed from 'react-instagram-embed'

function About() {
  const descriptionRef = React.createRef()
  useEffect(() => {
    const node = descriptionRef.current
    node.innerHTML = node.innerHTML.replace('Business eXperience', "<a href='http://www.businessexperience.ch'>Business eXperience</a>")

  })
  return (
    <div className="about">
      <div className="aboutContent">
        <img src={logo} className="App-logo-small" alt="logo" />
        <h2>{traductionMap.lifeGoesBy} </h2>
        
        

        <div className='textImage'>
          <img src={mainImage} alt='mainImage' style={{backgroundColor: 'white'}}></img>
          <div className='caption'>
            <p>{traductionMap.haveYouEver}</p>
            <p>{traductionMap.usYes}</p>
          </div>
        </div>

        <div className='sqipDescription'>
          <h1>{traductionMap.whatIsSqip}</h1>
          <br/>
          <p id='sqipdescription' ref={descriptionRef} >{traductionMap.sqipDescription}</p>
          <div style={{position:'relative', width:'100%', height:'0px', paddingBottom:'56.25%'}}>
            <iframe 
            style={{position:'absolute', left:0, top:0, width:'100%', height:'100%'}}
            src="https://www.youtube.com/embed/0bGN5svQQJE" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen></iframe>
          </div>
        </div>
        <div className='numbersSqip'>
          <h1>{traductionMap.inFewFigures} </h1>
          <div>
            <div>
              <p>4</p>
              <p className='smallerSize'>Diplômés de Haute Ecole de Gestion</p>
            </div>
            <div>
              <p>1</p>
              <p className='smallerSize'>Application web</p>
            </div>
            <div>
              <p>0</p>
              <p className='smallerSize'>File d'attente</p>
            </div>
            <div>
              <p>-30%</p>
              <p className='smallerSize'>De renoncement</p>
            </div>
            <div>
              <p>+20%</p>
              <p className='smallerSize'>De chiffre d'affaire</p>
            </div>
            <div>
              <p>∞</p>
              <p className='smallerSize'>De bons moments</p>
            </div>
          </div>
        </div>

        <div className='textImage'>
          <img src={teamImage} alt='teamImage'></img>
          <div className='caption'>
            <p>{traductionMap.teamInNutshell}</p>
          </div>
        </div>
        <div className='sqipDescription'>
          <p>{traductionMap.weAre}</p>
        </div>

        <InstagramEmbed
          url='https://www.instagram.com/sqip.ch/?igshid=yjiq3of3z2ln'
          clientAccessToken='123|456'
          maxWidth={320}
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
        />
        
        <p>{traductionMap.moreInfos} </p>
        <p>info@sqip.ch</p>
        
        <a href="https://instagram.com/sqip.ch?igshid=yjiq3of3z2ln"><img src={instagramLogo} alt="https://instagram.com/sqip.ch?igshid=yjiq3of3z2ln" /></a>
        <a href="https://www.linkedin.com/company/sqip-ch/?originalSubdomain=ch"><img src={LinkedinLogo} alt="https://www.linkedin.com/company/sqip-ch/?originalSubdomain=ch" /></a>
        <a href="https://www.facebook.com/sQip.ch/"><img src={facebookLogo} alt="https://www.facebook.com/sQip.ch/" /></a>
        </div>
    </div>
  );
}

export default About;
