import React, { Component } from "react";
import QrReader from "react-qr-reader";
import { traductionMap } from "./Traduction";

class QrCodeReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 300,
      result: ""
    };
    this.handleScan = this.handleScan.bind(this);
  }
  handleScan(data) {
    if (data) {
      this.setState({
        result: data
      });
      window.location.replace(data)
    }
  }
  handleError(err) {
    console.error(err);
  }
  render() {
    return (
      <div className="qrCodeReader">
        <p>{traductionMap.scanQr}</p>
        <QrReader
          delay={this.state.delay}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: "60%", margin: "0% 20% 0% 20%", border: "orange", borderStyle: "double" }}
        />
        <p>{this.state.result}</p>
      </div>
    );
  }
}
export default QrCodeReader
