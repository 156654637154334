import React, { useState } from 'react';
import '../../styles/views/consumerViews/EmailVerification.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { sendSMS } from '../../tools/Tools';
import Button from '../../componants/Button';
import { traductionMap } from '../../componants/Traduction';
import { useForceUpdate } from '../../tools/utils';
import ConfirmScreen from '../../componants/ConfirmScreen';

function SMSVerification() {
    
    var intervalId = window.setInterval(function(){
        if(phoneNumberSent){
            setConfirmScreenPhone(true)
        }
        clearInterval(intervalId)
    }, 20000);

    const forceUpdate = useForceUpdate()

    const [phoneNumberSent, setPhoneNumberSent] = useState(false)
    const [confirmScreenPhone, setConfirmScreenPhone] = useState(false)
    var phoneNumber = '+41'
    const changePhoneNumber = (phone) => {
        localStorage.setItem("phoneNumber", '+' + phone)
    }
    const createCode = (length) => {
        var result = '';
        var characters = '0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        console.log(result)
        return result;
    }
    const checkCode = () => {
        if (document.getElementById("validationCode").value === localStorage.getItem("validationCode") && localStorage.getItem("validationCode").length > 0) {
            //set that the phone number is valid
            localStorage.setItem("validePhoneNumber", "true")
            window.location.reload()
        }
        else alert("Le code est invalide")
    }
    const sendValidationRequest = () => {
        var code = createCode(6);
        localStorage.setItem("validationCode", code)

        var validationCode = localStorage.getItem("validationCode")
        var receiver = localStorage.getItem("phoneNumber")
        if (receiver === null) {
            receiver = phoneNumber
        }
        setPhoneNumberSent(true)
        let message = "Voici votre code de validation " + validationCode
        sendSMS(receiver, message)
    }
    const changeSentStatus = () => {
        setPhoneNumberSent(!phoneNumberSent)
    }
    const tempNoSms = () =>{
        setConfirmScreenPhone(false)
        window.location.reload()
        localStorage.setItem('tempNoSms', 'true')
        forceUpdate()
    }
    const noSms = () =>{
        setConfirmScreenPhone(false)
        window.location.reload()
        localStorage.setItem('noSms', 'true')
        forceUpdate()
    }



    return (

        <div style={{ marginBottom: '100px', marginTop: '90px' }}>

            {!phoneNumberSent ?
                <>
                    <div className='EmailVerification'>
                        {traductionMap.enterPhoneNr}
                    </div>
                    <br></br>
                    <PhoneInput
                        countryCodeEditable={false}
                        style={{ marginLeft: '12%', width: '12px!important' }}
                        country={'ch'}
                        onChange={phone => changePhoneNumber(phone)}
                    />
                    {/* <input autofocus style={{marginLeft: '12%'}} type={'tel'} onChange={phone => changePhoneNumber(phone)}></input> */}
                    <div className='EmailVerification'>
                        <Button onClick={sendValidationRequest}>{traductionMap.senCode}</Button>
                    </div>
                </> :

                <div>
                <div className='EmailVerification'>
                    <p style={{ fontWeight: "bolder" }}>{localStorage.getItem("phoneNumber")}</p>
                    <p>{traductionMap.enterCode}</p>

                    <input type="text" id="validationCode" placeholder="Entrer le code à 6 chiffres"></input>

                    <Button onClick={checkCode} style={{marginTop: '-100px'}}>{traductionMap.confirmCode}</Button>
                    <Button onClick={changeSentStatus}>{traductionMap.correctNumber}</Button>
                    <Button onClick={sendValidationRequest}>{traductionMap.resendCode}</Button>
                    
                </div>
                {confirmScreenPhone?
                    <ConfirmScreen message={traductionMap.ifSmsNotReceived}
                    labelYes={traductionMap.skip} labelOthers={traductionMap.skipPerm}
                    labelNo={traductionMap.cancel} functionYes={() => tempNoSms()} functionNo={() => setConfirmScreenPhone(false)} functionOthers={() => noSms()} />
                    :null
                }
                </div>

            }

        </div>
    )
}

export default SMSVerification;
