
import { deleteObject, getStorage, ref } from 'firebase/storage';


// ========================================== any file Repository =================================================

//delete any file by id
async function deleteFile(path){
    const storage = getStorage();
    // Create a reference to the file to delete
    const desertRef = ref(storage, path);

    // Delete the file
    deleteObject(desertRef).then(() => {
    // File deleted successfully
    }).catch((error) => {
        console.log(error)
    });
}

export {deleteFile};