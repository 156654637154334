import React from 'react';

//This is the current path in which the user is
export const eventPathContext = React.createContext({
    eventPath: "/",
    setEventPath: () => {}
});
export const menuPathContext = React.createContext({
    menuPath: "/",
    setMenuPath: () => {}
});
export const currentPathContext = React.createContext({
    itemPath: "/",
    setItemPath: () => {}
});
